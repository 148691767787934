import {
  QTd, QTh, QTr, QTable,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';


export default {
  name: 'VParentPaymentCalculateView',
  components: {
    QTd,
    QTh,
    QTr,
    QTable,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
      }),
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      filters: {
        year: currentDayHelper.year,
        month: currentDayHelper.month,
      },
      refs: {
        years: [],
        months: [],
      },
      table: {
        rows: [],
      },
    };
  },

  computed: {
    cols() {
      const trans = field => this.trans(`parent_payment.retirement_table.cols.${field}`);
      return [
        [
          { label: trans('title'), field: 'title' },
        ],
        [
          { label: trans('name'), field: 'name' },
          { label: trans('iin'), field: 'iin' },
          { label: trans('group'), field: 'group' },
          { label: trans('retirementAt'), field: 'retirementAt' },
          { label: trans('calculation'), field: 'calculation' },
        ],
      ];
    },
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button', 'parent_payment', 'notice']);
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
    });
  },
};
