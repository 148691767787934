<template>
  <div class="s-about-page__process-wrapper">
    <h4 class="s-title s-upper s-title--double-line mb-30">
      <span>{{ trans('statement.voucher.process') }}</span>
    </h4>
    <div class="s-about-page__process">
      <div
        v-for="item in [...Array(4).keys()]"
        :key="item"
        class="s-about-page__process--step"
      >
        <div class="s-about-page__process--step-circle">
          {{ item + 1 }}
        </div>
        <p>
          {{ trans(`statement.voucher.process_${item + 1}`) }}
        </p>
      </div>
    </div>
    <div class="s-about-page__button">
      <q-btn
        color="green"
        :label="trans('statement.service_order')"
        type="a"
        :no-caps="isAdaptive"
        :width="isAdaptive ? undefined : 33"
        :to="link"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAdaptive: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
