import VInfoCard from '@common/components/VInfoCard';
import GroupsTable from './GroupsTable';

export default {
  name: 'VVoucherApplicationShow',
  components: { GroupsTable, VInfoCard },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {
        info: [],
        groups: [],
        files: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'voucher']);
    await requestGetDataHelper(this, {
      url: this.uriGetData,
      variableName: 'data',
    });
  },
};
