/* eslint import/extensions: 0 */
import KindergartenList from './VFundingKindergartenList.vue';
import KindergartenListFinal from './VFundingKindergartenListFinal.vue';
import KindergartenGroupList from './VFundingKindergartenGroupList.vue';
import KindergartenGroupListFinal
from './VFundingKindergartenGroupListFinal.vue';
import FundingCalculation from './VFundingCalculation.vue';
import FundingCalculationView from './VFundingCalculationView.vue';
import FundingKindergartenSpecialList
from './VFundingKindergartenSpecialList.vue';
import FundingKindergartenListSpecialFinal
from './VFundingKindergartenListSpecialFinal.vue';


export const VFundingKindergartenList = KindergartenList;
export const VFundingKindergartenListFinal = KindergartenListFinal;
export const VFundingKindergartenGroupList = KindergartenGroupList;
export const VFundingCalculation = FundingCalculation;
export const VFundingCalculationView = FundingCalculationView;
export const VFundingKindergartenGroupListFinal = KindergartenGroupListFinal;
export const VFundingKindergartenSpecialList = FundingKindergartenSpecialList;
export const VFundingKindergartenListSpecialFinal = FundingKindergartenListSpecialFinal;

export default {
  VFundingKindergartenList,
  VFundingKindergartenGroupList,
  VFundingCalculation,
  VFundingCalculationView,
  VFundingKindergartenListFinal,
  VFundingKindergartenGroupListFinal,
  VFundingKindergartenSpecialList,
  VFundingKindergartenListSpecialFinal,
};
