<template>
  <q-form @submit="request">
    <v-wrapper-q-select
      v-model="form.dates"
      multiple
      :options="dates"
      :use-input="false"
      :extra-binds="{
        required: true,
        label: trans('voucher.timesheet.dates'),
      }"
    />
    <v-wrapper-q-select
      v-model="form.reason"
      :options="reasons"
      option-label="label"
      option-value="value"
      :extra-binds="{
        required: true,
        label: trans('voucher.timesheet.reason'),
      }"
    />
    <v-wrapper-multi-files-field
      v-model="form.file"
      required
      :label="trans(`voucher.timesheet.file`)"
      equal
      :config="{
        max: 1,
        size: 10*1024*1024,
      }"
    />
    <q-input
      v-model="form.comment"
      :label="trans(`voucher.timesheet.comment`)"
      type="textarea"
      counter
      :maxlength="1000"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        type="submit"
      />
    </div>
  </q-form>
</template>

<script>
import { Helper } from '@common/src/helpers';
import moment from 'moment';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        dates: [],
        reason: null,
        file: [],
        comment: null,
      },
    };
  },
  computed: {
    dates() {
      const days = [];
      _.forEach(this.attendance.days, (value, key) => {
        const name = moment(key, 'Y-MM-DD').format('DD.MM.Y');
        days.push({ id: key, name });
      });
      return days;
    },
  },
  methods: {
    async request() {
      this.$globalLoading.show();
      const data = _.cloneDeep(this.form);
      data.child_id = this.attendance.child_id;
      data.dates = data.dates.map(itm => itm.id);

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
