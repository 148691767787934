import axios from 'axios';
import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';


export default {
  name: 'VGovServicesMonitoringView',
  components: {
    QInput, QDate, QPopupProxy, QSelect, QTable, QIcon, QTr, QTh, QTd, QBtn, QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
  },
  data: () => ({
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
      numericCols: [],
    },
    rows: [],
    loading: false,
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    filter: '',
  }),
  async mounted() {
    this.requestGetData();

    const cols = [];

    cols.push({
      name: 'name',
      required: true,
      label: 'statistics.gov_services.name',
      align: 'left',
      field: 'name',
      sortable: true,
    });
    const numericCols = [
      {
        name: 'statements',
        required: true,
        label: 'statistics.gov_services.queue',
        align: 'left',
        field: 'statements',
        sortable: true,
      }, {
        name: 'contingent',
        required: true,
        label: 'statistics.gov_services.assigned',
        align: 'left',
        field: 'contingent',
        sortable: true,
      }, {
        name: 'total',
        required: true,
        label: 'statistics.gov_services.total',
        align: 'left',
        field: 'total',
        sortable: true,
      }];
    this.table.numericCols = numericCols;
    this.table.columns = [...cols, ...numericCols];
  },
  beforeCreate() {
    this.$trans.add(['statistics', 'button', 'menu', 'placeholder']);
  },
  methods: {
    clearFilters() {
      this.filters.date.to = '';
      this.filters.date.from = '';
    },
    async requestGetData() {
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
        },
      });

      this.rows = dataRes.data;
    },
    sum(col) {
      return _.sumBy(this.rows, col.name);
    },
    async exportu(e) {
      this.loading = true;
      axios({
        url: `${this.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          export: true,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.gov_services')
          } ${this.filters.date.from ? this.$trans.get('statistics.file.from', { date: this.filters.date.from }) : ''
          } ${this.filters.date.to ? this.$trans.get('statistics.file.to', { date: this.filters.date.to }) : ''
          }.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
  },
};
