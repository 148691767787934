<template>
  <v-form-inform
    v-if="bodyText"
    class="mb-20"
    :message="bodyText"
  />
</template>

<script>
export default {
  props: {
    bodyText: {
      type: String,
      default: '',
    },
  },
};
</script>
