import Vue from 'vue';
import axios from 'axios';
import sentry from '@vendor/sentry';
import quasarApp from '@quasar/install';
import Translator from '@common/plugins/Translator';
import { eventBus } from '@common/mixins';
import plugin from '@common/plugins';
import SModal from '@common/plugins/SModal';
import {
  QBtn,
  QDialog,
  QForm,
  QInput,
  QSelect,
} from '@quasar/components';
import PortalVue from 'portal-vue';
import componentsForm from '@common/components/Form';
import Notify from '@vjs/components/Notify/Notify';
import Notifications from '@vjs/plugins/Notification';
import store from './store';
import Modal from './plugins/ModalWindow';
import Components from './componentList';
import Modals from './modals';

Vue.use(PortalVue);
sentry(Vue, 'https://af3b1b90ce92441d9cd72ea0ec78db9e@sentry.services.snation.kz/5');

plugin(Vue);
Vue.use(Translator, { store, eventBus });
Vue.component('notify', Notify);
Vue.use(Notifications);
Vue.use(Modal);
Vue.use(SModal);
Vue.use(quasarApp, {
  components: {
    QInput,
    QForm,
    QDialog,
    QBtn,
  },
});
Vue.component('q-select', QSelect);
Modals(Vue);

Vue.prototype.$axios = axios;
Vue.prototype.$eventHub = new Vue();

componentsForm(Vue);

if (window.core_project) {
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.core_project.csrfToken || '',
    'Accept-Language': window.core_project.locale,
  };
} else {
  console.log('No window.core_project! You\'re probably testing static components');
}

[
  'v-app',
  'v-app-menu',
  'v-app-header',
  'v-app-mobile-menu',
  'v-app-header-menu',
  'v-app-main-page',
  'v-app-insta',
  'v-app-footer',
]
  .forEach((element) => {
    if (document.getElementById(element)) {
      const config = {};
      config.store = store;
      config.components = { ...Components };
      const vm = new Vue(config);
      vm.$mount(`#${element}`);
    }
  });
