<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
    @submit="request"
  >
    <v-form-inform :message="message" />

    <v-form-inform
      v-if="!statements.length"
      color="blue"
      :message="trans('warning_text.voucher_passport_statement_no_children_modal_inform')"
    />

    <q-field
      v-else
      :label="trans('label.select_child')"
      borderless
      required
    >
      <q-option-group
        v-model="statement"
        :options="statements"
        @input="loadGroups"
      />
    </q-field>

    <v-wrapper-q-select
      v-if="statement && groups.length"
      v-model="group"
      :options="groups"
      :use-input="false"
      :extra-binds="{
        required: true,
        label: trans('label.select_group'),
      }"
    />
    <template v-if="freePlaceEmpty">
      <v-form-inform
          color="blue"
          :message="trans('warning_text.no_found_group_and_age_send_to_waiting_list')"
      />
      <div class="row-sort-end-btn">
        <q-btn
            no-caps
            width="15"
            color="green"
            type="submit"
            :label="trans('button.to_waiting_list')"
        />
      </div>
    </template>

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!statement||freePlaceEmpty===true"
        type="submit"
        :label="trans('button.approve')"
      />
    </div>
  </q-form>
</template>

<script>
import { QOptionGroup } from '@quasar/components';
import { Helper } from '@common/src/helpers';

export default {

  components: { QOptionGroup },

  props: {
    actionWaitingList: {
      type: String,
      required: true,
    },
    actionSendStatement: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    uriGetChildren: {
      type: String,
      required: true,
    },
    uriGetGroups: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      action: this.actionSendStatement,
      statement: null,
      statements: [],
      group: null,
      groups: [],
      freePlaceEmpty: false,
    };
  },
  async mounted() {
    await requestGetDataHelper(this, {
      url: this.uriGetChildren, variableName: 'statements', hardSet: true,
    });
  },
  methods: {
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: { statement: this.statement, group: this.group },
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      } else {
        this.$globalLoading.hide();
        this.$emit('onClose');
      }
    },
    async loadGroups() {
      if (!this.uriGetGroups) {
        return;
      }
      const { statement } = this;
      const resp = await requestGetDataHelper(this, {
        url: this.uriGetGroups, params: { statement }, returnData: true, fullRes:true
      });
      if (resp.code === 1) {
        this.freePlaceEmpty = true
        this.action = this.actionWaitingList;
      } else {
        this.groups = resp.data;
        this.freePlaceEmpty = false
        this.action = this.actionSendStatement;
      }
    },
  },
};
</script>
