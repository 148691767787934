<template>
  <confirm
    :active="show"
    :on-hide="cancel"
    size="medium"
  >
    <div class="attendance-child-info">
      {{ `${trans('attendance.time-sheet.full-name')}: ${fullName}` }}
      <br>
      {{ date }}
    </div>
    <q-field
      v-if="isAlt"
      borderless
    >
      <!--suppress JSUnresolvedVariable -->
      <q-option-group
        v-model="attendance"
        :options="attendanceOptionsList"
        class="d-flex justify-content-around s-w-100"
        type="radio"
        :disable="!isEdit || isDirectorSigned"
        inline
      />
    </q-field>
    <q-slide-transition>
      <div
        v-if="!isAlt || attendance === 0"
        class="s-light"
      >
        <q-select
          v-model="typeControl"
          :options="reasons"
          :label="trans('attendance.timesheet-absent-reason')"
          :disable="confirming || reloading || !isEdit || fileApproved === 1 || isDirectorSigned"
          option-value="value"
          option-label="label"
        />
        <!--suppress JSUnresolvedFunction -->
        <q-slide-transition>
          <div
            v-if="type === 1"
            class="mb-10"
          >
            <q-input
              v-model="otherControl"
              :error="hasValidateFail('other')"
              :error-message="fails.other"
              :disable="confirming || reloading || !isEdit || isDirectorSigned"
              required
              :label="trans('attendance.timesheet-absent-other')"
            />
          </div>
        </q-slide-transition>

        <q-slide-transition>
          <div
            v-if="![0,7].includes(type)"
            class="mb-10"
          >
            <div class="sn-field__label">
              {{ trans('attendance.timesheet-absent-file') }}:
              <i v-if="![2,3,4,5,6].includes(type)">*</i>
            </div>
            <q-btn
              v-if="!file && !hasFile"
              class="s-w-100 form-upload__button"
              icon="clip"
              color="blue"
              type="button"
              :label="trans('button.overview')"
              label-wider
              :disable="!isEdit || isDirectorSigned"
              :loading="confirming || reloading"
              @click="triggerFile('file')"
            />
            <q-btn
              v-if="file || hasFile"
              v-model="file"
              icon="close"
              class="s-w-100 s-btn--border sn-btn--ellipsis"
              :label="hasFile ? trans('attendance.file-was-loaded') : file.name"
              label-wider
              no-caps
              type="button"
              :disable="!isEdit || fileApproved === 1 || isDirectorSigned"
              :loading="confirming || reloading"
              @click="clearFile('file')"
            />
            <div
              v-if="hasValidateFail('file')"
              class="s-form__error-message mt-5"
            >
              {{ fails.file }}
            </div>
          </div>
          <!--suppress JSUnresolvedFunction -->
        </q-slide-transition>
        <input
          ref="fileInput"
          class="d-none"
          type="file"
          accept="image/*,.pdf"
          @change="fileChange"
        >
      </div>
    </q-slide-transition>
    <div class="s-group s-group--wider justify-content-end">
      <q-btn
        v-if="attendance === 0 && reason.uriDownload && fileApproved !== 3"
        color="orange"
        type="a"
        target="_blank"
        :to="reason.uriDownload"
      >
        {{ trans('button.download_file') }}
      </q-btn>
      <q-btn
        v-if="saveVisible"
        :disable="saveDisable"
        color="green"
        :loading="confirming || reloading"
        @click="confirm"
      >
        {{ trans('button.save') }}
      </q-btn>

      <q-btn
        v-if="attendance === 0 && hasFile && fileApproved === 1"
        color="red"
        :loading="confirming || reloading"
        @click="rejectParentReason"
      >
        {{ trans('button.to_refuse') }}
      </q-btn>
      <q-btn
        v-if="attendance === 0 && hasFile && fileApproved === 1"
        color="green"
        :loading="confirming || reloading"
        @click="approveParentReason"
      >
        {{ trans('button.approved') }}
      </q-btn>
    </div>
  </confirm>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script>
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QField,
  QInput,
  QOptionGroup,
  QSelect,
  QSlideTransition,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';
// noinspection NpmUsedModulesInstalled,
import { ATTENDANCE_TIME_SHEET_TRANSLATIONS } from '@vjs/balabaqsha/consts';
import Localization from '../../Mixins/QuasarLocale';
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'VTimeSheetReason',
  components: {
    QSelect,
    QBtn,
    QInput,
    QSlideTransition,
    QOptionGroup,
    QField,
    Confirm,
  },
  mixins: [Localization],
  props: {
    reasons: {
      type: Array,
      default: () => ([]),
    },
    reason: {
      type: Object,
      default: () => ({
        fullName: '',
        date: '',
        attendance: null,
        contingentId: null,
        type: null,
        rawType: null,
        file: null,
        hasFile: false,
        fileApproved: false,
        uriDownload: false,
      }),
    },
    show: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isAlt: {
      type: Boolean,
      default: false,
    },
    isDirectorSigned: {
      type: Boolean,
      default: false,
    },
    fails: {
      type: Object,
      default: () => ({}),
    },
    reloading: {
      type: Boolean,
      default: false,
    },
    confirming: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const {
      reasons,
      reason,
    } = this;

    return {
      type: reason.type || 0,
      file: reason.file || null,
      hasFile: reason.hasFile || null,
      fileApproved: reason.fileApproved || null,
      rawType: reason.rawType || null,
      other: reason.other || null,
      fullName: reason.fullName || null,
      date: reason.date || null,
      attendance: reason.attendance ?? null,
      contingentId: reason.contingentId || null,
      reasonList: reasons,
      backupReason: reason,
      activeFile: null,
    };
  },
  computed: {
    attendanceOptionsList() {
      const { hasFile, fileApproved } = this;
      if (hasFile && fileApproved === 1) {
        return this.isDemo ? [
          { value: 1, label: this.trans('attendance.journal-absent-no'), disable: true },
          { value: 2, label: this.trans('attendance.journal-absent-pz'), disable: true },
          { value: 0, label: this.trans('attendance.journal-absent-yes') },
        ] : [
          { value: 1, label: this.trans('attendance.journal-absent-no'), disable: true },
          { value: 0, label: this.trans('attendance.journal-absent-yes') },
        ];
      }

      return this.isDemo ? [
        { value: 1, label: this.trans('attendance.journal-absent-no') },
        { value: 2, label: this.trans('attendance.journal-absent-pz') },
        { value: 0, label: this.trans('attendance.journal-absent-yes') },
      ] : [
        { value: 1, label: this.trans('attendance.journal-absent-no') },
        { value: 0, label: this.trans('attendance.journal-absent-yes') },
      ];
    },
    reasonsTypeList() {
      const { reasonList } = this;
      return reasonList.map(reason => reason.value);
    },
    isValid() {
      let fileValidate = false;
      switch (this.type) {
        case 0: fileValidate = true; break;
        case 1: fileValidate = !!this.otherControl && (this.hasFile === true || this.file !== null); break;
        case 2: fileValidate = true; break; // Основание врача
        case 3: fileValidate = true; break; // Отпуск родителей
        case 4: fileValidate = true; break; // По заявлению родителя
        case 5: fileValidate = true; break;
        case 6: fileValidate = true; break;
        case 7: fileValidate = false; break;
        default: fileValidate = (this.hasFile === true || this.file !== null); break;
      }

      if (this.isAlt) {
        return this.attendance === 1 || (this.attendance === 0 && fileValidate);
      }

      return fileValidate;
    },
    saveVisible() {
      return this.attendance === 1 || this.attendance === 2
        || (this.attendance === 0 && this.file)
        || (this.attendance === 0 && !this.file && !(this.hasFile && (this.fileApproved === 1 || this.fileApproved === 2)));
    },
    saveDisable() {
      return this.attendance === 0 && (
        !this.isValid || !this.isEdit || this.file || this.hasFile || this.isDirectorSigned
      );
    },
    typeControl: {
      get() {
        return this.rawType;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          // eslint-disable-next-line prefer-destructuring
          this.rawType = value;
          this.type = value.value;
        }

        this.fails.type = null;
        this.hasValidateFail('type');
      },
    },
    otherControl: {
      get() {
        return this.other;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          this.other = value;
        }

        this.fails.other = null;
        this.hasValidateFail('other');
      },
    },
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  updated() {
    if (this.reason) {
      this.backupReason = this.reason;
    }
  },
  methods: {
    reasonEvent() {
      const {
        file, hasFile,
        type, rawType,
        other, fullName,
        attendance, contingentId,
        date,
      } = this;
      return {
        file,
        hasFile,
        type: parseInt(type, 10),
        rawType,
        other,
        fullName,
        attendance,
        contingentId,
        date,
      };
    },
    hasValidateFail(field) {
      const {
        type, other,
        $trans: { get: trans },
        fails,
      } = this;

      if (this.reloading) {
        return null;
      }

      if (
        typeof fails[field] !== 'undefined'
        && fails[field] !== null
      ) {
        return true;
      }
      if (field === 'type') {
        this.fails.type = null;
        if (type !== 1) {
          if (this.reasonsTypeList.indexOf(type) === -1) {
            this.fails.type = 'invalid type';
          }
        } else if (this.reasonsTypeList.indexOf(type) === -1) {
          this.fails.type = 'invalid type';
          return true;
        } else if (other && other.length === 0) {
          this.fails.type = 'invalid type';
          return true;
        }
        return null;
      }
      return null;
    },
    confirm() {
      this.$emit('confirm', this.reasonEvent());
    },
    approveParentReason() {
      this.$emit('approveParentReason', this.reasonEvent());
    },
    rejectParentReason() {
      this.$emit('rejectParentReason', this.reasonEvent());
    },
    cancel() {
      const { backupReason } = this;
      this.file = backupReason.file;
      this.type = backupReason.type;
      this.rawType = backupReason.rawType;
      this.other = backupReason.other;
      this.hasFile = backupReason.hasFile;
      this.fullName = backupReason.fullName;
      this.$emit('change', this.reasonEvent());
      this.$emit('close');
    },

    clearFile(filename) {
      this[filename] = null;
      this.hasFile = false;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
  },
};
</script>


<style scoped lang="scss">
.attendance-child-info {
  color: #3248A5;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.confirm-control {
  padding: 1.5em 0 0;
  display: flex;
  justify-content: flex-end;
  flex-basis: 1em;

  .sn-btn {
    margin: 0 0.5em;
  }
}
</style>
