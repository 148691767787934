import {
  QCheckbox,
} from '@quasar/components';
import axios from 'axios';
import Offer from '@vjs/modals/Offer.vue';
import VPageContent from '../../../components/VPageContent';

export default {
  name: 'VStepInformation',

  props: {
    uriAbout: '',
    uriOfferModal: '',
  },

  components: {
    VPageContent,
    QCheckbox,
  },

  data() {
    return {
      accept: [],
    };
  },

  created() {
    this.$eventHub.$on('SET_NEXT_STEP', this.nextStep);
  },

  beforeDestroy() {
    this.$eventHub.$off('SET_NEXT_STEP', this.nextStep);
  },

  watch: {
    accept(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 1,
        access: val.length === 4,
      });
    },
  },

  methods: {
    async offerInfo() {
      const res = await axios.get(this.uriOfferModal);
      this.$modal.open({
        title: this.trans('auth.offerta_link'),
        props: {
          text: res.data,
        },
        component: Offer,
      });
    },
    nextStep() {
      this.$store.commit('SET_NEXT_STEP');
    },
  },
};
