<template>
  <div>
    <v-form-inform
      v-if="bodyText"
      class="mb-20"
      :message="bodyText"
    />

    <div
      v-if="customInform"
      class="mb-20"
      v-html="customInform"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        :width="closeButton?.width ?? 15"
        :color="closeButton?.color ?? 'grey'"
        :label="closeButton?.label ?? trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        :width="confirmButton?.width ?? 15"
        :color="confirmButton?.color ?? 'green'"
        :label="confirmButton?.label ?? trans('button.approve')"
        :type="type === 'link' ? 'a' : 'button'"
        :to="type === 'link' ? action : undefined"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    type: {
      type: String,
      default: 'request',
    },
    bodyText: {
      type: String,
      default: '',
    },
    customInform: {
      type: String,
      default: '',
    },
    closeButton: {
      type: Object,
      default: () => ({
        color: 'grey',
        label: null,
        width: 15,
      }),
    },
    confirmButton: {
      type: Object,
      default: () => ({
        color: 'green',
        label: null,
        width: 15,
      }),
    },
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
    requestData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    async approve() {
      switch (this.type) {
        case 'request':
          await this.request();
          break;
        case 'link':
          break;
        case 'event':
        default:
          this.$emit('onClose');
          this.$emit('onApprove');
          break;
      }
    },
    async request() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        [this.method === 'get' ? 'params' : 'data']: this.requestData,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
