<template>
  <div>
    <v-form-inform
      v-if="informConfig?.text"
      class="mb-20"
      :color="informConfig?.color"
      :message="informConfig?.text"
    />

    <div
      v-if="textHtml"
      class="mb-20"
      v-html="textHtml"
    />

    <q-btn
      no-caps
      class="s-w-100"
      :color="buttonConfig?.color ?? 'orange'"
      :label="buttonConfig?.label ?? trans('button.informed')"
      @click="request"
    />
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    textHtml: {
      type: String,
      default: '',
    },
    informConfig: {
      type: Object,
      default: () => ({
        color: undefined,
        text: '',
      }),
    },
    buttonConfig: {
      type: Object,
      default: () => ({
        color: 'orange',
        label: null,
      }),
    },
    action: {
      type: String,
      default: '',
    },
  },
  methods: {
    async request() {
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$emit('onClose');
    },
  },
};
</script>
