import VFormInform from '@vjs/components/VFormInform';
import VNoResults from '@vjs/components/VNoResults';
import datesGenerator from '@common/plugins/CalendarGenerator/datesGenerator';
import { QTooltip } from '@quasar/components';
import { eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import CalendarHeader from './Components/CalendarHeader';
import VFoodMenu from './Components/VFoodMenu';

export default {
  name: 'VFoodMenuCalendar',
  components: {
    VFormInform,
    CalendarHeader,
    VNoResults,
    VFoodMenu,
    QTooltip,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        getItems: '',
        getRefs: '',
        store: '',
      }),
    },
    isContingent: {
      type: Boolean,
      default: false,
    },
    isCook: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentDayHelper,
    calendarDates: [],
    calendarData: [],
    year: 0,
    month: 0,
    dateMonth: null,
    activeMenu: null,
    refs: {
      portionWeights: [],
      mealTypelist: [],
      typeByMealTime: [],
      typeByEntity: [],
      entityByOptionType: [],
      productOptions: [],
      dishOptions: [],
    },
    refsLoaded: false,
  }),
  async mounted() {
    eventBus.$on('initCalendar', this.initCalendar);
    eventBus.$on('handleGetItems', this.requestGetData);
    eventBus.$on('handleCloseMenu', () => { this.activeMenu = null; });

    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'table',
      'food',
    ]);

    await this.initRefs();
  },
  computed: {
    swipeDirection() { return this.activeMenu ? 'swipe-right' : 'swipe-left'; },
    shortWeekDays() {
      const weekDays = [
        'monday', 'tuesday', 'wednesday', 'thursday',
        'friday', 'saturday', 'sunday',
      ];
      const result = [];
      _.forEach(weekDays, weekDay => result.push(this.trans(`label.week_days_short.${weekDay}`)));
      return result;
    },
    menuCols() {
      const trans = key => this.trans(`food.menu.header.${key}`);
      if (!this.activeMenu) {
        return [];
      }
      const { refs: { portionWeights } } = this;
      const cols = [];
      let tmpRow = [];
      _.forEach(portionWeights[_.keys(portionWeights)[0]], (weight, age) => {
        tmpRow.push({ label: age });
      });
      cols.push(tmpRow);

      _.forEach(portionWeights, (ages, mealLabel) => {
        tmpRow = [];
        tmpRow.push({ label: mealLabel });
        _.forEach(ages, (weight, age) => {
          tmpRow.push({ label: weight });
        });
        cols.push(tmpRow);
      });

      tmpRow = [
        { field: 'meal_time', rowspan: cols.length + 1, label: trans('meal_time') },
        { field: 'dish_id', rowspan: 2, label: trans('dish_id') },
        { field: 'dish_out', colspan: cols[cols.length - 1].length - 1, label: trans('dish_out') },
      ];
      cols.unshift(tmpRow);
      return cols;
    },
  },
  methods: {
    getDateActions({
      month, jsDate,
    }, dayKey) {
      const {
        trans, handleCreateMenu, calendarData, handleOpenMenu, handleConfirmModal,
      } = this;
      const actions = [];
      const genBtn = (label, color, click) => ({ label, color, click });

      const timestamp = (new Date(jsDate)).getTime();
      const dateData = calendarData.find(item => (new Date(item.date)).getTime() === timestamp);

      if (
        timestamp >= currentDayHelper.timestamp
        && month === this.month
        && this.apiConfig.store
        && !dateData
        && ![6, 7].includes(dayKey)
      ) {
        actions.push(genBtn(
          trans('button.add'), 'green', () => { handleCreateMenu(jsDate); },
        ));
      }

      if (dateData) {
        if (dateData.apiConfig.update) {
          actions.push(genBtn(
            trans('button.edit'), 'orange', () => { handleOpenMenu(jsDate, dateData.apiConfig); },
          ));
        }
        if (dateData.apiConfig.send) {
          actions.push(genBtn(
            trans('button.send'), 'green', () => { handleConfirmModal(dateData.apiConfig.send, 'patch'); },
          ));
        }
        if (
          this.isCook
          || (dateData.apiConfig.reject && dateData.apiConfig.approve)
          || (!dateData.apiConfig.update && dateData.apiConfig.getData)
        ) {
          actions.push(genBtn(
            trans('button.show'), 'blue', () => { handleOpenMenu(jsDate, dateData.apiConfig); },
          ));
        }
      }

      return actions;
    },
    getStatusColor(status) {
      switch (status) {
        case 2: return '#8DBE2D';
        case 3: return 'red';
        default: return '#F5942E';
      }
    },
    getDate({ jsDate }) {
      const { calendarData } = this;
      const timestamp = (new Date(jsDate)).getTime();
      return calendarData.find(item => (new Date(item.date)).getTime() === timestamp);
    },
    getDateClasses({ date, month, year }) {
      const result = [];
      if (month !== this.month) {
        result.push('s-food-menu-calendar--table-cell--day-disabled');
      }
      if (
        date === currentDayHelper.day
        && month + 1 === currentDayHelper.month
        && year === currentDayHelper.year
      ) {
        result.push('s-food-menu-calendar--table-cell--day-current');
      }
      return result;
    },
    handleCreateMenu(jsDate) {
      const momentDate = moment(jsDate);
      const date = momentDate.format('DD.MM.YYYY');
      const weekDay = _.upperFirst(momentDate.format('dddd'));
      const stringDate = momentDate.format('DD MMMM YYYY');
      this.activeMenu = {
        apiConfig: { save: this.apiConfig.store },
        date,
        weekDay,
        stringDate,
      };
    },
    handleOpenMenu(jsDate, apiConfig) {
      const momentDate = moment(jsDate);
      const date = momentDate.format('DD.MM.YYYY');
      const weekDay = _.upperFirst(momentDate.format('dddd'));
      const stringDate = momentDate.format('DD MMMM YYYY');
      this.activeMenu = {
        apiConfig: { ...apiConfig, save: apiConfig.update },
        date,
        weekDay,
        stringDate,
      };
    },
    async handleConfirmModal(uri, method) {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        size: 480,
        binds: { type: 'event' },
        events: {
          onApprove: async () => {
            await this.handlePostRequest(uri, method);
          },
        },
      });
    },
    async initCalendar({ year, month, dateMonth }) {
      this.calendarData = [];
      const body = {
        year,
        month,
        startingDay: 1,
      };

      const { dates } = datesGenerator(body);

      this.calendarDates = dates;
      this.year = year;
      this.month = month;
      this.dateMonth = dateMonth;

      await this.requestGetData();
    },
    async initRefs() {
      await requestGetDataHelper(this, {
        url: this.apiConfig.getRefs,
        variableName: 'refs',
        hardSet: true,
      });
    },
    async requestGetData() {
      await requestGetDataHelper(this, {
        url: this.apiConfig.getItems,
        params: {
          year: this.year,
          month: this.month + 1,
        },
        variableName: 'calendarData',
        hardSet: true,
      });
    },
    async handlePostRequest(url, method = 'post') {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        method,
      });

      this.$globalLoading.hide();

      if (!res.error) {
        eventBus.$emit('handleGetItems');
        eventBus.$emit('onCloseAll');
        eventBus.$emit('handleCloseMenu');
        return res;
      }
      Helper.handlerResponseErrorNew(this, res);
      return false;
    },
  },
};
