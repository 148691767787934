<template>
  <div class="s-about-page__inform s-about-page__inform--desktop">
    <a
      :href="faqLink"
      target="_blank"
      class="s-about-page__inform-link"
    >
      <span class="s-str-ico-fa-rev s-str-ico-fa--thm-arrow-to-bottom">
        {{ trans('statement.voucher.what_is_voucher') }}
      </span>
    </a>
    <div>
      <span>
        {{ trans('statement.voucher.is_pilot_order') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faqLink: {
      type: String,
      required: true,
    },
  },
};
</script>
