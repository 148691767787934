import VFaqSpoiler from '../VFaqSpoiler/VFaqSpoiler.vue';

export default {
  components: {
    VFaqSpoiler,
  },
  props: {

    query: {
      type: Array | String,
      default: () => [],
    },
    currentSectionId: {
      type: Number,
      default: 0,
    },
    currentId: {
      type: Number,
      default: 2,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      queryMutated: this.query,
      currentSectionIdMutated: this.currentId,
    };
  },
  computed: {
    getAnswearList() {
      let answearList = [];
      this.items.forEach((item) => {
        if (item.id === this.currentSectionIdMutated) {
          answearList = item.children;
        } else if (item.name === this.queryMutated.section) {
          answearList = item.children;
        }
      });
      return answearList;
    },
  },
  beforeCreate() {
    this.$trans.add(['label']);
  },
  methods: {
    setSection(id) {
      if (typeof (this.queryMutated) !== 'string') {
        this.queryMutated.section = '';
        this.currentSectionIdMutated = id;
      }
    },
    checkIconName(iconName) {
      if (iconName.slice(0, 3) === 'ti-') {
        return iconName.slice(3);
      }
      return iconName;
    },
  },
};
