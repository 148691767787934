<template>
  <div>
    <div class="mb-30">
      {{
        signXml ?
          trans('parent_payment.success_sign')
          : trans('parent_payment.sign_modal_body')
      }}
    </div>
    <div class="row justify-content-center">
      <div class="col col-sm-auto sn--text-center mt-5 mb-5">
        <q-btn
          v-if="!signXml"
          width="20"
          :label="trans('button.sign')"
          color="green"
          @click="handleSign"
        />
        <q-btn
          v-else
          width="20"
          :label="trans('button.submit')"
          color="green"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn } from '@quasar/components';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VParentPaymentSubmitSignModal',
  components: {
    QBtn,
  },
  directives: { ClosePopup },
  props: {
    signUrl: {
      type: String,
      default: '',
    },
    calculateId: {
      type: Number,
      default: null,
    },
    signXml: {
      type: String,
      default: '',
    },
  },
  async mounted() {
    if (!this.calculateId) {
      this.signXml = '';
    }
    await this.$trans.add([
      'parent_payment',
      'button',
    ]);
  },
  methods: {
    async handleSign() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.signUrl,
            method: 'post',
            data: {
              calculateId: this.calculateId,
              signXml: this.signXml,
            },
          });
          if (!res.error) {
            this.$emit('modalSigned', this.signXml);
          } else {
            this.signXml = '';
          }
          this.$globalLoading.hide();
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    handleSubmit() {
      if (this.signXml) {
        this.$emit('modalSubmit');
      } else {
        this.$notify({
          text: this.trans('parent_payment.sign_empty'),
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
