import {
  QTd, QTh, QTr, QTable,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';
import { eventBus } from '@common/mixins';

export default {
  name: 'VParentPaymentCalculateView',
  components: {
    QTd,
    QTh,
    QTr,
    QTable,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        childId: '',
        selectedYear: '',
        selectedMonth: '',
        getRefs: '',
        getTable: '',
        calculate: '',
        calculateSubmit: '',
        calculateCancel: '',
        export: '',
        submit: '',
        sign: '',
      }),
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      filters: {
        year: this.config.selectedYear ?? currentDayHelper.year,
        month: this.config.selectedMonth ?? currentDayHelper.month,
      },
      refs: {
        years: [],
        months: [],
      },
      table: {
        calculateId: null,
        isSubmitted: false,
        calculateConfirmed: false,
        cols: [],
        rows: [],
        signed: false,
        signData: {
          type: Object,
          default: () => ({
            signQR: '',
            signDate: '',
            signerIin: '',
            signerFullname: '',
          }),
        },
      },
      signXml: '',
      showActions: true,
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
    calculateActive() {
      return this.showActions && (this.filters.year < currentDayHelper.year
          || this.filters.month <= currentDayHelper.month);
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button', 'parent_payment', 'notice']);
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
    });
  },
  methods: {
    async getData() {
      this.signXml = '';
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
      });
      this.showActions = true;
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        await requestGetDataHelper(this, {
          url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
        });
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async submit() {
      await this.handlePostRequest(
        this.config.submit, {
          calculateId: this.table.calculateId,
          signXml: this.signXml,
        },
      );
    },
  },
};
