<template>
  <div
    v-if="isAdaptive"
    class="s-title-link s-title-link--small s-medium"
  >
    <a
      class="s-title-link__link"
      :href="backLink"
    >
      <q-icon
        name="arrow-left"
        icon-class="s-ico-fa"
      />
    </a>
    {{ trans('title.about_statement_voucher') }}
  </div>
  <h1
    v-else
    class="mt-40 mb-40"
  >
    {{ trans('title.about_statement_voucher') }}
  </h1>
</template>

<script>
import { QIcon } from '@quasar/components/icon';

export default {
  components: { QIcon },
  props: {
    isAdaptive: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
