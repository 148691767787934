export default {
  name: 'VFaqSpoiler',
  props: {
    elementId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: null,
    },
    currentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      show: this.currentId === this.elementId,
    };
  },
  methods: {
    switcher() {
      this.show = !this.show;
    },
  },
};
