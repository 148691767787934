<template>
  <div>
    <div
      v-if="loading"
      class="row load-panel"
    >
      <q-spinner
        indeterminate
        color="blue"
        size="5em"
      />
    </div>
    <v-time-sheet
      v-if="!loading"
      ref="timeSheet"
      :columns="columns"
      :check-is-current-day="checkIsCurrentDay"
      :rows="timeSheetData"
    >
      <template slot="headFixedRow">
        <tr class="s-time-sheet--head-row">
          <th class="s-time-sheet--head-cell">
            №
          </th>
          <th class="s-time-sheet--head-cell">
            {{ trans('attendance.time-sheet.full-name') }}
          </th>
        </tr>
      </template>
      <template slot="headScrollRow">
        <tr class="s-time-sheet--head-row">
          <th
            v-for="(item, key) in dateStrings"
            :key="key"
            class="s-time-sheet--head-cell s-time-sheet--head-cell-month"
            :colspan="item.daysCount"
            :style="getMonthStyle(item.daysCount)"
          >
            {{ item.label }}
          </th>
          <th
            class="s-time-sheet--head-cell"
            colspan="3"
          >
            {{ trans('attendance.time-sheet.absent-header') }}
          </th>
          <th
            v-if="isVoucher"
            class="s-time-sheet--head-cell"
            style="border-left:1px solid #DBDDEA; white-space: nowrap;"
            colspan="2"
          >
            {{ trans('attendance.time-sheet.voucher_header') }}
          </th>
        </tr>
      </template>
      <template v-slot:fixedRow="props">
        <v-ts-cell
          :class-name="{
            's-time-sheet-cell-total': props.props.row.is_total === true,
          }"
        >
          {{ props.props.row.fixed.index }}
        </v-ts-cell>
        <v-ts-cell
          :class-name="{
            's-time-sheet-cell-total': props.props.row.is_total === true,
          }"
          :style="isChildrenTimesheet ? 'white-space:nowrap' : ''"
        >
          {{ props.props.row.fixed.full_name }}
          <span
            v-if="props.props.row.fixed.bornDate"
            class="s-c-light-grey-blue"
          >
            ({{ props.props.row.fixed.bornDate }})
          </span>
          <div
            v-if="props.props.row.fixed.is_budget"
            class="s-time-sheet-cell-is-budget"
          >
            <q-tooltip>{{ trans('label.is_budget_yes') }}</q-tooltip>
          </div>
        </v-ts-cell>
      </template>
      <template v-slot:scrollRow="props">
        <v-ts-cell
          v-for="(dayRow, i) in props.props.row.scroll.days"
          :id="`day-${dayRow.day}-${props.props.index}`"
          :key="`day-${dayRow.day}-${props.props.index}`"
          :class-name="{
            's-time-sheet-cell-day': true,
            // 's-time-sheet-cell-out': dayRow.is_out === true,
            's-time-sheet-cell-moved': dayRow.is_moved === true,
            's-time-sheet-cell-dropped': dayRow.is_dropped === true,
            's-time-sheet-cell-debatable': dayRow.is_debatable === true,
            's-time-sheet-cell-holiday': dayRow.is_work === false,
            's-time-sheet-cell-total': props.props.row.is_total === true,
            's-time-sheet-cell-absent': checkIsEditable(dayRow),
            'absent-symbol-load-file-approving':(
              typeof dayRow.attendance.attendance !== 'undefined'
              && parseInt(dayRow.attendance.attendance, 10) === 0)
              && dayRow.attendance['has-file']
              && dayRow.attendance['file-approved'] === 1,
            'absent-symbol-load-file': (dayRow.is_work === true
              && typeof dayRow.attendance.attendance !== 'undefined'
              && parseInt(dayRow.attendance.attendance, 10) === 0)
              && dayRow.attendance['has-file']
              && dayRow.attendance.absent_reason_type !== 0
              && (dayRow.attendance['file-approved'] === 2 || dayRow.attendance['file-approved'] === 4),
            's-time-sheet-cell-attend': (dayRow.is_work === true
              && typeof dayRow.attendance.attendance !== 'undefined'
              && parseInt(dayRow.attendance.attendance, 10) === 1),
            'without-hover-icon': isWorker && !dayRow.hasFile,
          }"
          @click="() => changeReason(props.props.row, dayRow)"
          @mouseover.native="handleTooltip(`#day-${dayRow.day}-${props.props.index}`, dayRow.date)"
        >
          <span class="s-time-sheet-cell-absent--value">
            {{ getCellLabel(dayRow, props.props.row.is_total) }}
          </span>
        </v-ts-cell>
        <v-ts-cell
          :class-name="{
            's-time-sheet-cell-total': props.props.row.is_total === true,
          }"
        >
          {{ props.props.row.scroll.absentDays }}
        </v-ts-cell>
        <v-ts-cell
          :class-name="{
            's-time-sheet-cell-total': props.props.row.is_total === true,
          }"
        >
          {{ props.props.row.scroll.respectfulAbsentDays }}
        </v-ts-cell>
        <v-ts-cell>
          {{ props.props.row.scroll.reasons }}
        </v-ts-cell>
        <template v-if="isVoucher">
          <v-ts-cell class="s-to-center">
            {{ currencyFormatter(props.props.row.scroll.totalVoucher) }}
          </v-ts-cell>
          <v-ts-cell>
            <div class="d-flex justify-content-center align-items-center">
              <q-icon
                v-if="props.props.row.scroll.hasOwnProperty('voucherStatus')"
                bold
                icon-class="s-ico-fa"
                size="2rem"
                :name="getVoucherIconName(props.props.row.scroll.voucherStatus)"
                :color="getVoucherIconColor(props.props.row.scroll.voucherStatus)"
              />
              <q-btn
                v-if="props.props.row.scroll.urlChildAttendance"
                style="margin-left: 1.5rem;"
                outline
                color="blue"
                icon="eye"
                squire
                :to="props.props.row.scroll.urlChildAttendance"
                target="_blank"
                type="a"
              >
                <q-tooltip>{{ trans('attendance.voucher_see_child_timesheet') }}</q-tooltip>
              </q-btn>
            </div>
          </v-ts-cell>
        </template>
      </template>
    </v-time-sheet>
    <q-tooltip
      v-if="activeTooltipText && $q.screen.width > 768"
      ref="timesheetTooltip"
      max-width="24rem"
      :target="activeTooltipId"
    >
      {{ activeTooltipText }}
    </q-tooltip>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import {
  ATTENDANCE_TIME_SHEET_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import VTimeSheet, { VTsCell } from '@vjs/balabaqsha/components/VTimeSheet';
// noinspection NpmUsedModulesInstalled
import { QSpinner, QTooltip, QIcon } from '@quasar/components';

import moment from 'moment';
import Localization from '../../Mixins/QuasarLocale';

export default {
  name: 'VTimeSheetTable',
  components: {
    QTooltip,
    QIcon,
    VTimeSheet,
    VTsCell,
    QSpinner,
  },
  mixins: [Localization],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isAlt: {
      type: Boolean,
      default: false,
    },
    isVoucher: {
      type: Boolean,
      default: false,
    },
    dayColumns: {
      type: Object,
      default: () => ({}),
    },
    timeSheetData: {
      type: Array,
      default: () => ([]),
    },
    allowedEditDays: {
      type: Array,
      default: () => ([]),
    },
    daysCount: {
      type: Number,
      default: 0,
    },
    dateStrings: {
      type: Array,
      default: () => ([]),
    },
    currentDay: {
      type: String,
      default: '',
    },
    changeReason: {
      type: Function,
      default: () => () => null,
    },
    isChildrenTimesheet: {
      type: Boolean,
      default: false,
    },
    isWorker: {
      type: Boolean,
      default: false,
    },
    isDirectorSigned: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTooltipText: null,
      activeTooltipId: null,
    };
  },
  computed: {
    columns() {
      const { $trans: { get: trans }, dayColumns } = this;
      const fixedColumns = [
        {
          name: 'index',
          field: 'index',
          label: ' ',
          fixed: true,
        }, {
          name: 'full_name',
          field: 'full_name',
          label: '',
          fixed: true,
        },
        // {
        //   name: 'is_budget',
        //   field: 'is_budget',
        //   label: '',
        //   fixed: true,
        // },
      ];
      const lastColumns = [
        {
          name: 'absentDays',
          field: 'absentDays',
          label: trans('attendance.time-sheet.absent-days'),
        },
        {
          name: 'respectfulAbsentDays',
          field: 'respectfulAbsentDays',
          label: trans('attendance.time-sheet.respectful-absent-days'),
        },
        {
          name: 'reasons',
          field: 'reasons',
          label: trans('attendance.time-sheet.absent-reasons'),
        },
      ];
      if (this.isVoucher) {
        lastColumns.push({
          name: 'totalVoucher',
          field: 'totalVoucher',
          label: trans('attendance.time-sheet.total_voucher_header'),
        });
        lastColumns.push({
          name: 'voucherStatus',
          field: 'voucherStatus',
          label: trans('attendance.time-sheet.voucher_status_header'),
        });
      }

      const daysColumns = typeof dayColumns !== 'undefined'
        ? Object.keys(dayColumns)
          .map(key => ({
            name: key,
            field: key,
            label: dayColumns[key],
          }))
        : [];

      return [...fixedColumns, ...daysColumns, ...lastColumns];
    },
  },
  beforeMount() {
    this.initLocale();
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  methods: {
    currencyFormatter(number) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    checkIsCurrentDay(date) {
      const parsedDate = moment(date, 'Y-MM-DD').format('DD/MM/Y');
      return this.currentDay === parsedDate;
    },
    checkIsEditable(dayRow) {
      if (this.isAlt) {
        return dayRow.is_work === true
          && this.allowedEditDays.indexOf(dayRow.date) !== -1
          && dayRow.canEdit;
      }
      return (dayRow.is_work === true
        && (
          typeof dayRow.attendance.attendance !== 'undefined'
            && parseInt(dayRow.attendance.attendance, 10) === 0
        ));
    },
    scrollTableToToday() {
      const interval = setInterval(() => {
        const sheet = this.$refs.timeSheet;
        if (sheet) {
          clearInterval(interval);
          sheet.scrollTableToToday();
        }
      }, 1500);
    },
    handleSetScrollWidth() {
      const interval = setInterval(() => {
        const sheet = this.$refs.timeSheet;
        if (sheet) {
          clearInterval(interval);
          sheet.handleSetScrollWidth();
        }
      }, 1500);
    },
    reCalcSize() {
      const sheet = this.$refs.timeSheet;
      if (sheet) {
        sheet.reCalcSize();
      }
    },
    getMonthStyle(daysCount) {
      switch (daysCount) {
        case 1:
        case 2:
          return 'font-size: 12.3px';
        case 3: return 'font-size: ';
        default: return '';
      }
    },
    getCellLabel(dayRow, isTotal) {
      if (dayRow.is_work) {
        if (
          typeof dayRow.attendance.attendance !== 'undefined'
        && typeof isTotal === 'undefined'
        && parseInt(dayRow.attendance.attendance, 10) === 0
        ) {
          if (dayRow.attendance['has-file'] && dayRow.attendance['file-approved'] === 1) {
            return this.trans('attendance.table-sheet.absent-symbol-load-file-approving');
          }
          if (dayRow.attendance['has-file'] && dayRow.attendance['file-approved'] === 2) {
            return this.trans('attendance.table-sheet.absent-symbol-load-file');
          }
          if (dayRow.attendance['has-file'] && dayRow.attendance['file-approved'] === 4) {
            return this.trans('attendance.table-sheet.absent-symbol-load-file');
          }
          return this.trans('attendance.table-sheet.absent-symbol');
        }
        if (
          dayRow.attendance.attendance === null
          && typeof isTotal === 'undefined'
        ) {
          return this.trans('attendance.table-sheet.not-marked-symbol');
        }
        if (
          typeof dayRow.attendance.attendance !== 'undefined'
          && typeof isTotal === 'undefined'
          && parseInt(dayRow.attendance.attendance, 10) === 1
        ) {
          return this.trans('attendance.table-sheet.attend-symbol');
        }
        if (dayRow.is_moved) {
          return this.trans('attendance.table-sheet.move-symbol');
        }
        if (dayRow.is_dropped) {
          return this.trans('attendance.table-sheet.drop-symbol');
        }
        if (dayRow.is_out) {
          return this.trans('attendance.table-sheet.out-symbol');
        }
        if (
          typeof dayRow.attendance !== 'undefined'
          && typeof isTotal !== 'undefined'
        ) {
          return dayRow.attendance;
        }
      } else {
        return this.trans('attendance.table-sheet.vacancy-symbol');
      }

      if (this.isDemo && dayRow.attendance.attendance === 2) {
        return this.trans('attendance.table-sheet.pz-symbol');
      }

      return '';
    },
    handleTooltip(ref, date) {
      if (!date || this.$q.screen.width < 769) {
        return;
      }
      this.activeTooltipId = ref;
      this.activeTooltipText = moment(date).format('DD.MM.YYYY');
      this.$nextTick(() => {
        this.$refs.timesheetTooltip.updatePosition();
      });
    },
    getVoucherIconName(status) {
      switch (status) {
        case 2: return 'times';
        case 1: return 'check';
        default: return 'exclamation-triangle';
      }
    },
    getVoucherIconColor(status) {
      switch (status) {
        case 2: return 'red';
        case 1: return 'green';
        default: return 'bb-yellow';
      }
    },
  },
};
</script>
