import VNoResults from '@vjs/components/VNoResults';

export default {
  components: { VNoResults },
  props: {
    uriBack: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    defaultId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      idsWithFullText: [],
      maxDescLength: 75,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'feedback']);
  },
  mounted() {
    if (this.defaultId) {
      this.idsWithFullText = [this.defaultId];
      this.$nextTick(() => {
        const ref = this.$refs[`feedback-ref-${this.defaultId}`];
        const top = ref?.[0]?.getBoundingClientRect().top;
        if (top) {
          window.scrollTo({
            behavior: 'smooth',
            top,
          });
        }
      });
    }
  },
  methods: {
    getSplittedDesc(text = '') {
      return text.split(' ');
    },
    toggleFullDesc(id) {
      if (!this.idsWithFullText.includes(id)) {
        this.idsWithFullText.push(id);
      } else {
        this.idsWithFullText = this.idsWithFullText.filter(item => item !== id);
      }
    },
    getDescription(text, id) {
      const splittedDesc = this.getSplittedDesc(text);
      let description = [];
      if (splittedDesc.length <= this.maxDescLength || this.idsWithFullText.includes(id)) {
        description = splittedDesc;
      } else {
        description = splittedDesc.slice(0, this.maxDescLength - splittedDesc.length);
        description[description.length - 1] = `${description[description.length - 1]}...`;
      }
      return description.join(' ');
    },
  },
};
