import VHeadTabsPortalWrapper from '@common/components/VHeadTabsPortalWrapper';
import Map from './Map';
import Info from './Info';

export default {
  components: { Map, Info, VHeadTabsPortalWrapper },
  props: {},
  data: () => ({
    infoOpen: false,
  }),

  async mounted() {
    eventBus.$on('corruptionInfoOpen', this.handleInfoOpen);
  },
  computed: {
    swipeDirection() { return this.infoOpen ? 'swipe-left' : 'swipe-right'; },
  },
  methods: {
    handleInfoOpen(val, region) {
      this.infoOpen = val;
    },
  },
};
