// noinspection NpmUsedModulesInstalled
import { ATTENDANCE_TIME_SHEET_TRANSLATIONS } from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import { QBtn, QIcon, QSelect } from '@quasar/components';

import axios from 'axios';
import moment from 'moment';
import { Helper } from '@common/src/helpers';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import Localization from '../Mixins/QuasarLocale';
import TimeSheetApiProvider from './Mixins/TimeSheetApiProvider';
// eslint-disable-next-line import/extensions
import VTimeSheetTable from './Components/VTimeSheetTable.vue';
// eslint-disable-next-line import/extensions
import VTimeSheetSignatures from './Components/VTimeSheetSignatures.vue';
import VTimeSheetVoucherInfo from './Components/VTimeSheetVoucherInfo.vue';
// eslint-disable-next-line import/extensions
import VTimeSheetReason from './Components/VTimeSheetReason.vue';
// eslint-disable-next-line import/extensions
import VTimeSheetAddParentReason from './Components/VTimeSheetAddParentReason.vue';
// eslint-disable-next-line import/extensions
import VTimeSheetMassLoadDocDialog
// eslint-disable-next-line import/extensions
from './Components/VTimeSheetMassLoadDocDialog.vue';

export default {
  name: 'VTimeSheetView',
  components: {
    VTimeSheetMassLoadDocDialog,
    VTimeSheetReason,
    VTimeSheetAddParentReason,
    VTimeSheetTable,
    VTimeSheetSignatures,
    VTimeSheetVoucherInfo,
    VConfirmDialog,
    QIcon,
    QSelect,
    QBtn,
  },
  mixins: [Localization, TimeSheetApiProvider],
  props: {
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    isParent: {
      type: Boolean,
      default: false,
    },
    isWorker: {
      type: Boolean,
      default: false,
    },
    isVoucher: {
      type: Boolean,
      default: false,
    },
    isDebatable: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const {
      dataConfig: {
        groupId, reasons, allowEditMonth, isAlt, childId,
        massEditType, month,
      },
    } = this;
    const {
      apiConfig: {
        timeSheetGet,
      },
    } = this;

    return {
      locale,
      groupId,
      childId,
      allowEditMonth,
      isAlt,
      massEditType,
      month,
      timeSheetGet,
      reasonsList: reasons,
      timeSheet: {},
      timeSheetLoad: false,
      confirming: false,
      reloading: false,
      serverFail: null,
      dateStrings: [],
      reasons: {},
      reasonFails: {
        type: null,
        file: null,
        other: null,
      },
      currentReason: null,
      selectedAttendance: null,
      massLoadDialog: null,
      currentDay: moment().format('DD/MM/Y'),
      loading: false,
      approveParentReasonAction: false,
      rejectParentReasonAction: false,
      modalRemoveSign: false,
      loadingRemove: false,
      showSignButton: false,
      showRemoveButton: false,
      disableRemoveButton: false,
      removeSignUrl: '',
      timeSheetSignUrl: '',
      signXml: '',
    };
  },
  computed: {
    isChildrenTimeSheet() {
      return this.childId && !this.groupId;
    },
    canEditMonth() {
      if (
        typeof this.timeSheet !== 'undefined'
        && typeof this.timeSheet.editMonth !== 'undefined'
        && typeof this.timeSheet.editYear !== 'undefined'
        && typeof this.timeSheet.currentYear !== 'undefined'
        && typeof this.timeSheet.currentMonth !== 'undefined'
        && typeof this.timeSheet.currentMonth.value !== 'undefined'
      ) {
        return ((!this.allowEditMonth && this.allowEditMonth !== 0)
            || this.allowEditMonth === currentDayHelper.month)
          && this.timeSheet.currentMonth.value === parseInt(this.timeSheet.editMonth, 10)
          && this.timeSheet.currentYear === parseInt(this.timeSheet.editYear, 10);
      }
      return false;
    },
    years() {
      const { timeSheet: { availableDates } } = this;
      return Object.keys(availableDates);
    },
    months() {
      const { timeSheet: { availableDates, currentMonth, currentYear } } = this;
      return typeof availableDates[currentYear] !== 'undefined'
        ? availableDates[currentYear]
        : [currentMonth];
    },
    columns() {
      const { $trans: { get: trans }, timeSheet: { dayColumns } } = this;
      const fixedColumns = [
        {
          name: 'index',
          field: 'index',
          label: ' ',
          fixed: true,
        }, {
          name: 'full_name',
          field: 'full_name',
          label: '',
          fixed: true,
        },
      ];
      const lastColumns = [
        {
          name: 'absentDays',
          field: 'absentDays',
          label: trans('attendance.time-sheet.absent-days'),
        },
        {
          name: 'respectfulAbsentDays',
          field: 'respectfulAbsentDays',
          label: trans('attendance.time-sheet.respectful-absent-days'),
        },
        {
          name: 'reasons',
          field: 'reasons',
          label: trans('attendance.time-sheet.absent-reasons'),
        },
      ];

      if (this.isVoucher) {
        lastColumns.push({
          name: 'totalVoucher',
          field: 'totalVoucher',
          label: trans('attendance.time-sheet.total-voucher'),
        });
        lastColumns.push({
          name: 'voucherStatus',
          field: 'voucherStatus',
          label: trans('attendance.time-sheet.total-voucher'),
        });
      }

      const daysColumns = typeof dayColumns !== 'undefined'
        ? Object.keys(dayColumns)
          .map(key => ({
            name: key,
            field: key,
            label: dayColumns[key],
          }))
        : [];

      return [...fixedColumns, ...daysColumns, ...lastColumns];
    },
    timeSheetData() {
      const { timeSheet: { attendance }, $trans: { get: trans } } = this;
      if (typeof attendance === 'undefined') {
        return [];
      }
      const daysTotalAbsent = {
        fixed: {
          index: ' ',
          full_name: trans('attendance.time-sheet.absent-total'),
        },
        scroll: {
          absentDays: 0,
          reasons: '',
          respectfulAbsentDays: 0,
          totalVoucher: 0,
          days: [],
        },
        is_total: true,
      };
      const daysTotalAttend = {
        fixed: {
          index: ' ',
          full_name: trans('attendance.time-sheet.attend-total'),
        },
        scroll: {
          absentDays: '',
          reasons: '',
          respectfulAbsentDays: '',
          totalVoucher: '',
          days: [],
        },
        is_total: true,
      };

      attendance.forEach((item) => {
        Object.keys(item.days)
          .forEach((key) => {
            const day = item.days[key];
            if (typeof daysTotalAbsent.scroll.days[key] === 'undefined') {
              daysTotalAbsent.scroll.days[key] = {
                attendance: 0,
                is_work: day.is_work,
                day: day.day,
              };
              daysTotalAttend.scroll.days[key] = {
                attendance: 0,
                is_work: day.is_work,
                day: day.day,
              };
            }
            if (
              typeof day.attendance !== 'undefined'
              && typeof day.attendance.attendance !== 'undefined'
              && day.attendance.attendance !== null
              // && (
              //   (typeof day.is_moved === 'undefined' || !day.is_moved)
              //   && (typeof day.is_dropped === 'undefined' || !day.is_dropped)
              //   && (typeof day.is_out === 'undefined' || !day.is_out)
              // )
            ) {
              if (
                parseInt(day.attendance.attendance, 10) === 0
                || (this.isDemo && day.attendance.attendance === 2)
              ) {
                daysTotalAbsent.scroll.days[key].attendance += 1;
              } else {
                daysTotalAttend.scroll.days[key].attendance += 1;
              }
            }
          });
        daysTotalAbsent.scroll.absentDays += item.absentDays;
        daysTotalAbsent.scroll.respectfulAbsentDays += item.respectfulAbsentDays;
        daysTotalAbsent.scroll.totalVoucher += item.totalVoucher;
      });

      daysTotalAbsent.scroll.days = Object.keys(daysTotalAbsent.scroll.days)
        .map(key => daysTotalAbsent.scroll.days[key]);

      daysTotalAttend.scroll.days = Object.keys(daysTotalAttend.scroll.days)
        .map(key => daysTotalAttend.scroll.days[key]);

      const rows = attendance.map((item, index) => ({
        fixed: {
          index: index + 1,
          full_name: item.full_name,
          contingentId: item.contingentId,
          bornDate: item.born_date,
          is_budget: item.is_budget,
        },
        scroll: {
          days: Object.keys(item.days)
            .map(key => ({
              ...item.days[key],
              date: key,
            })),
          absentDays: item.absentDays,
          reasons: item.reasons,
          respectfulAbsentDays: item.respectfulAbsentDays,
          totalVoucher: item.totalVoucher,
          voucherStatus: item.parent_sign_status,
          urlChildAttendance: item.link_child_attendance_list,
        },
      }));

      return this.isChildrenTimeSheet ? rows
        : [...rows, daysTotalAbsent, daysTotalAttend];
    },
  },
  async beforeMount() {
    const { apiConfig } = this;
    await this.initProvider(apiConfig);
    await this.initLocale();
    await this.loadTimeSheet(this.month);
  },
  async mounted() {
    await this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
    if (this.isVoucher) {
      await this.$trans.add(['voucher']);
    }
    const self = this;
    setInterval(() => {
      const sheet = self.$refs.timeSheet;
      if (sheet) {
        sheet.reCalcSize();
      }
    }, 1500);
    setTimeout(() => {
      const sheet = self.$refs.timeSheet;
      if (sheet) {
        sheet.handleSetScrollWidth();
        sheet.scrollTableToToday();
      }
    });
    if (this.timeSheet?.isGroupTrashed) {
      this.$notify({
        text: this.trans('notice.this_group_deleted'),
        type: 'warn',
      });
    }
  },
  methods: {
    handleRemoveSign() {
      this.modalRemoveSign = true;
    },
    async removeSign() {
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.removeSignUrl,
      });
      this.loadingRemove = false;
      if (!res.error) {
        this.modalConfirmRemove = false;
        this.$emit('removed', this.identifier);
        window.location.reload();
      }
    },
    async sign() {
      this.$globalLoading.show();
      const { apiConfig } = this;
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.timeSheetSignUrl,
            data: {
              signXml: this.signXml,
            },
            method: 'post',
          });
          if (!res.error) {
            window.location.reload();
          } else {
            Helper.handlerResponseErrorNew(this, res);
            this.$globalLoading.hide();
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    exportu(e) {
      this.$globalLoading.show();
      this.loading = true;
      const date = `${this.timeSheet.currentYear}-${this.timeSheet.currentMonth.value}`;

      const totalAbsent = this.isChildrenTimeSheet
        ? null : this.timeSheetData[this.timeSheetData.length - 1];
      const totalAttend = this.isChildrenTimeSheet
        ? null : this.timeSheetData[this.timeSheetData.length - 2];

      const params = {
        export: true,
        date,
        totalAbsent: JSON.stringify(totalAbsent),
        totalAttend: JSON.stringify(totalAttend),
      };

      const url = new URL(this.groupId || this.childId, `${this.apiConfig.timeSheetGet.apiUrl}/stub`);

      if (this.isDebatable) {
        url.searchParams.set('debatable', '1');
      }

      axios({
        url: url.href,
        method: 'GET',
        responseType: 'blob',
        params,
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('attendance.export_timesheet')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.$globalLoading.hide();
        this.loading = false;
      });
    },
    downloadPdf(e) {
      this.$globalLoading.show();
      this.loading = true;
      const date = `${this.timeSheet.currentYear}-${this.timeSheet.currentMonth.value}`;

      const totalAbsent = this.isChildrenTimeSheet
        ? null : this.timeSheetData[this.timeSheetData.length - 1];
      const totalAttend = this.isChildrenTimeSheet
        ? null : this.timeSheetData[this.timeSheetData.length - 2];

      const params = {
        pdf: true,
        date,
        totalAbsent: JSON.stringify(totalAbsent),
        totalAttend: JSON.stringify(totalAttend),
      };

      const url = new URL(this.groupId || this.childId, `${this.apiConfig.timeSheetGet.apiUrl}/stub`);

      if (this.isDebatable) {
        url.searchParams.set('debatable', '1');
      }

      axios({
        url: url.href,
        method: 'GET',
        responseType: 'blob',
        params,
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('attendance.export_timesheet')
          } .pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.$globalLoading.hide();
        this.loading = false;
      });
    },
    attendanceByType(type) {
      const found = this.reasonsList.reduce(
        (acc, item) => {
          if (acc) {
            return acc;
          }
          // noinspection JSUnresolvedVariable
          return item.value === type ? item : null;
        },
        null,
      );
      if (!found) {
        return null;
      }
      return found;
    },
    async loadTimeSheet(date = null) {
      const {
        apiProvider, groupId, childId, isAlt,
      } = this;
      this.timeSheetLoad = true;
      this.timeSheet = {};

      const modelId = groupId || childId;

      const response = await apiProvider.provideFetch()
        .apply(this, [modelId, date, isAlt]);

      if (response) {
        this.timeSheet = response;
        if (response.dateStrings) {
          this.dateStrings = response.dateStrings;
        }

        this.showSignButton = response.showSignButton;
        this.showRemoveButton = response.showRemoveButton;
        this.disableRemoveButton = response.disableRemoveButton;
        this.removeSignUrl = response.removeSignUrl;
        this.timeSheetSignUrl = response.timeSheetSignUrl;
      }

      this.timeSheetLoad = false;
      this.reCalcSize();
      setTimeout(() => {
        const sheet = this.$refs.timeSheet;
        if (sheet) {
          sheet.handleSetScrollWidth();
          sheet.scrollTableToToday();
        }
      });
      let url = `?month=${date}`;

      if (this.isDebatable) {
        url += '&debatable=1';
      }

      window.history.replaceState(null, null, url);
    },
    async onChangeYear(item) {
      const { loadTimeSheet, timeSheet: { availableDates, currentMonth } } = this;
      if (availableDates[item].indexOf(currentMonth) === -1) {
        this.timeSheet.currentMonth = availableDates[item]
          .slice(0, 1)
          .shift();
      }
      const date = `${this.timeSheet.currentYear}-${this.timeSheet.currentMonth.value}`;
      await loadTimeSheet(date);
    },
    async onChangeMonth() {
      const { loadTimeSheet } = this;
      const date = `${this.timeSheet.currentYear}-${this.timeSheet.currentMonth.value}`;
      await loadTimeSheet(date);
    },
    async confirmAttendanceMark() {
      const {
        currentReason,
        apiProvider,
      } = this;
      this.serverFail = null;
      this.confirming = true;
      const parsedDate = moment(currentReason.date, 'DD/MM/Y').format('Y-MM-DD');

      const response = await apiProvider.provideAttendanceMark()
        .apply(this, [currentReason.contingentId, currentReason.attendance, parsedDate]);

      this.confirming = false;

      if (response.responseStatus === 'validation-fail') {
        this.serverFail = true;
        Object.keys(response.data)
          .forEach((key) => {
            this.reasonFails[key] = response.data[key];
          });
      }
      if (response.responseStatus === 'validation_fail' && response.message) {
        this.serverFail = true;
        this.$notify({
          type: 'error',
          text: response.message,
        });
      }

      if (response.responseStatus === 'ok') {
        if (currentReason.attendance === 0) {
          this.selectedAttendance = response.attendanceId;
          await this.confirmAbsentReason();
        } else {
          this.reloading = true;
          await this.loadTimeSheet(parsedDate);
          this.reloading = false;
          this.selectedAttendance = null;
          this.$notify({
            type: 'success',
            text: this.trans('attendance.timesheet-confirm-text'),
          });
        }
      }
    },
    async confirmAbsentReason() {
      const {
        currentReason,
        apiProvider,
        selectedAttendance,
        $trans: { get: trans },
      } = this;
      this.serverFail = null;
      this.confirming = true;

      currentReason.approvingParentReason = this.approveParentReasonAction;
      currentReason.rejectingParentReason = this.rejectParentReasonAction;
      const parsedDate = moment(currentReason.date, 'DD/MM/Y').format('Y-MM-DD');
      const response = await apiProvider.provideMark()
        .apply(this, [currentReason, selectedAttendance]);

      this.confirming = false;

      if (response.responseStatus === 'validation-fail') {
        this.serverFail = true;
        Object.keys(response.data)
          .forEach((key) => {
            this.reasonFails[key] = response.data[key];
          });
      }

      if (response.responseStatus === 'ok') {
        this.reloading = true;
        await this.loadTimeSheet(parsedDate);
        this.reloading = false;
        this.selectedAttendance = null;
        this.$notify({
          type: 'success',
          text: trans('attendance.timesheet-confirm-text'),
        });
      }
    },
    reCalcSize() {
      const sheet = this.$refs.timeSheet;
      if (sheet) {
        sheet.reCalcSize();
      }
    },
    chooseReason(row, day) {
      if (row.is_total) {
        return;
      }
      const { attendance } = day;
      const hasFile = attendance['has-file'] || false;
      const parsedDate = moment(day.date, 'Y-MM-DD').format('DD/MM/Y');
      const { contingentId } = day;

      const {
        timeSheet: { allowedEditDays },
        $notify,
        $trans: { get: trans },
      } = this;

      if (hasFile) {
        this.handleChooseReason(row, attendance, parsedDate, hasFile, contingentId);
      }
      if (!this.isEdit) {
        return;
      }
      if (this.timeSheet.directorSign) {
        $notify({
          text: trans('attendance.time-sheet.cant-change-signed-table'),
          type: 'error',
        });
        // eslint-disable-next-line consistent-return
        return;
      }
      if (
        (
          !this.canEditMonth && (
            typeof allowedEditDays === 'undefined'
              || !Array.isArray(allowedEditDays)
              || typeof day.is_work === 'undefined'
              || typeof day.date !== 'string'
              || allowedEditDays.indexOf(day.date) === -1
              || !day.is_work
          )
        ) || (this.isAlt && (allowedEditDays.indexOf(day.date) === -1 || !day.canEdit))
          || (
            !this.isAlt && (
              typeof day.attendance === 'undefined'
              || typeof day.attendance.attendance === 'undefined'
              || parseInt(day.attendance.attendance, 10) !== 0
            )
          )
      ) {
        // noinspection JSCheckFunctionSignatures
        $notify({
          text: trans(`attendance.time-sheet.cant-change${this.isAlt ? '-alternative' : ''}`),
          type: 'error',
        });
      } else {
        this.handleChooseReason(row, attendance, parsedDate, hasFile, contingentId);
      }
    },
    parentAddReason(row, day) {
      if (row.is_total) {
        return;
      }
      const { attendance } = day;
      const hasFile = attendance['has-file'] || false;
      const { allowedToEditDay } = attendance;
      const { allowedToEditReason } = attendance;
      const parsedDate = moment(day.date, 'Y-MM-DD').format('DD/MM/Y');
      const { contingentId } = day;
      const {
        $notify,
        $trans: { get: trans },
      } = this;

      if (!this.isParent) {
        $notify({
          text: trans('attendance.time-sheet.cant-change-parent-doc'),
          type: 'error',
        });
      } else if (!allowedToEditReason) {
        $notify({
          text: trans('attendance.time-sheet.cant-change-this-reason'),
          type: 'error',
        });
      } else if (!allowedToEditDay) {
        $notify({
          text: trans('attendance.time-sheet.cant-change'),
          type: 'error',
        });
      } else if (!(attendance.absent_reason_type > 0)) {
        $notify({
          text: trans('attendance.time-sheet.cant-change-reason-not-found'),
          type: 'error',
        });
      } else {
        this.handleParentAddReason(row, attendance, parsedDate, hasFile, contingentId);
      }
    },
    handleChooseReason(row, attendance, parsedDate, hasFile, contingentId) {
      const {
        makeDefaultReason,
        attendanceByType,
      } = this;
      const { id } = attendance;
      const { reasons } = this;
      let currentReason = makeDefaultReason(id, row.fixed.full_name, hasFile);
      if (id) {
        currentReason = reasons[id] || makeDefaultReason(id, row.fixed.full_name, hasFile);
      }
      currentReason.date = parsedDate;
      currentReason.attendance = attendance?.attendance;
      currentReason.contingentId = contingentId;

      this.reasonFails = {
        type: null,
        file: null,
        other: null,
      };
      currentReason.fileApproved = attendance['file-approved'];
      if (
        attendance
        && attendance.absent_reason_type
      ) {
        const reasonType = attendanceByType(attendance.absent_reason_type);
        currentReason.rawType = reasonType || reasons[4];
        currentReason.type = currentReason.rawType.value;
        currentReason.uriDownload = attendance.file;

        if (
          currentReason.rawType.value === 1
          && attendance.absent_reason_name
        ) {
          currentReason.other = attendance.absent_reason_name;
        }
      }

      reasons[id] = currentReason;
      this.reasons = reasons;
      this.currentReason = currentReason;
      this.selectedAttendance = id;
    },
    handleParentAddReason(row, attendance, parsedDate, hasFile, contingentId) {
      const {
        makeDefaultReason,
        attendanceByType,
      } = this;
      const { id } = attendance;
      const { reasons } = this;
      let currentReason = makeDefaultReason(id, row.fixed.full_name, hasFile);
      if (id) {
        currentReason = reasons[id] || makeDefaultReason(id, row.fixed.full_name, hasFile);
      }
      currentReason.date = parsedDate;
      currentReason.attendance = attendance?.attendance;
      currentReason.contingentId = contingentId;

      this.reasonFails = {
        type: null,
        file: null,
        other: null,
      };

      if (
        attendance
        && attendance.absent_reason_type
      ) {
        const reasonType = attendanceByType(attendance.absent_reason_type);
        currentReason.rawType = reasonType || reasons[4];
        currentReason.type = currentReason.rawType.value;
        currentReason.uriDownload = attendance.file;
        currentReason.fileApproved = attendance['file-approved'];
        if (
          currentReason.rawType.value === 1
          && attendance.absent_reason_name
        ) {
          currentReason.other = attendance.absent_reason_name;
        }
      }

      reasons[id] = currentReason;
      this.reasons = reasons;
      this.currentReason = currentReason;
      this.selectedAttendance = id;
    },
    reasonExist(id) {
      return typeof this.reasons[id] !== 'undefined';
    },
    makeDefaultReason(id, fullName = '', hasFile = false) {
      const { reasonsList } = this;
      return {
        fullName,
        attendance_id: id,
        type: 0,
        rawType: reasonsList.reduce((acc, item) => (item.value === 0 ? item : acc), null),
        file: null,
        other: '',
        hasFile,
      };
    },
    closeReason() {
      this.selectedAttendance = null;
      this.currentReason = {};
    },
    changeReason(reason) {
      const id = this.selectedAttendance;
      this.reasons[id] = reason;
      this.currentReason = null;
      this.currentReason = reason;
    },
    async confirmParentReason(reason) {
      const id = this.selectedAttendance;
      const parsedDate = moment(this.currentReason.date, 'Y-MM-DD').format('DD/MM/Y');
      this.reasons[id] = reason;
      this.currentReason = null;
      this.currentReason = reason;
      if (reason.attendance === 0) {
        await this.confirmAbsentReason();
      } else {
        this.reloading = true;
        await this.loadTimeSheet(parsedDate);
        this.reloading = false;
        this.selectedAttendance = null;
      }
    },
    async rejectParentReason(reason) {
      const id = this.selectedAttendance;
      const parsedDate = moment(this.currentReason.date, 'Y-MM-DD').format('DD/MM/Y');
      this.reasons[id] = reason;
      this.currentReason = null;
      this.currentReason = reason;
      this.approveParentReasonAction = false;
      this.rejectParentReasonAction = true;
      if (reason.attendance === 0) {
        await this.confirmAbsentReason();
      } else {
        this.reloading = true;
        await this.loadTimeSheet(parsedDate);
        this.reloading = false;
        this.selectedAttendance = null;
      }
    },
    async approveParentReason(reason) {
      const id = this.selectedAttendance;
      const parsedDate = moment(this.currentReason.date, 'Y-MM-DD').format('DD/MM/Y');
      this.reasons[id] = reason;
      this.currentReason = null;
      this.currentReason = reason;
      this.approveParentReasonAction = true;
      this.rejectParentReasonAction = false;
      if (reason.attendance === 0) {
        await this.confirmAbsentReason();
      } else {
        this.reloading = true;
        await this.loadTimeSheet(parsedDate);
        this.reloading = false;
        this.selectedAttendance = null;
      }
    },
    async confirmReason(reason) {
      const id = this.selectedAttendance;
      const tmpReason = _.cloneDeep(this.reasons[id]);
      const parsedDate = moment(this.currentReason.date, 'Y-MM-DD').format('DD/MM/Y');
      this.reasons[id] = reason;
      this.currentReason = null;
      this.currentReason = reason;
      if (this.isAlt && tmpReason.attendance !== reason.attendance) {
        await this.confirmAttendanceMark();
      } else if (reason.attendance === 0) {
        await this.confirmAbsentReason();
      } else {
        this.reloading = true;
        await this.loadTimeSheet(parsedDate);
        this.reloading = false;
        this.selectedAttendance = null;
      }
    },
    openMassAbsentReasonDialog() {
      this.massLoadDialog = true;
    },
    closeMassAbsentReasonDialog() {
      this.massLoadDialog = false;
    },
    async reloadSheet() {
      this.$refs.massLoadDialog.clearModel();
      this.massLoadDialog = null;
      await this.onChangeMonth();
    },
  },
};
