import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VDirection',
  components: {
    QBtn,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    flag: String,
    desc: {
      type: Object,
      default: () => ({}),
    },
    refuse: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: Number,
      default: () => null,
    },
    type: {
      type: String,
      default: 'common',
    },
  },
  data() {
    return {
      modalConfirm: false,
    };
  },
  mounted() {
    // this.stepItems.reverse();
  },
  methods: {
    callConfirmPopup(id, type) {
      this.$emit('confirm-popup', { id, type });
    },
  },
};
