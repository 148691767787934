<template>
  <portal to="tabs-portal">
    <div
      class="container-content-head"
      :style="headStyle"
    >
      <div class="container-content-head__right-wrapper">
        <div
          class="container-content-head__tabs-wrapper container-content-head__tabs-wrapper--q-btns-mod"
          :class="{
            'container-content-head__tabs--free': isFree,
            'container-content-head__tabs--right': isRight,
            [wrapperClass]: !!wrapperClass
          }"
        >
          <slot />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'VHeadTabsPortalWrapper',
  props: {
    isFree: Boolean,
    isRight: Boolean,
    wrapperClass: String,
    headStyle: [String, Object],
  },
};
</script>
