import KindergartenListApiProvider
from '../../../providers/Kindergarten/KindergartenListApiProvider';
import KindergartenLocalitiesRepository
from '../../../repositories/KindergartenLocalitiesRepository';
import KindergartensRepository
from '../../../repositories/KindergartensRepository';
import CalculateAllRepository
from '../../../repositories/CalculateAllRepository';
import ConfirmCalculateRepository
from '../../../repositories/ConfirmCalculateRepository';
import DenyCalculateRepository
from '../../../repositories/DenyCalculateRepository';

const KindergartenListProviders = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({
      localities,
      kindergartens,
      calculate,
      confirm,
      abort,
    }) {
      const trans = this.$trans.get;
      this.apiProvider = new KindergartenListApiProvider({
        ConfirmCalculateRepository: new ConfirmCalculateRepository(confirm),
        DenyCalculateRepository: new DenyCalculateRepository(abort),
        CalculateAllRepository: new CalculateAllRepository(calculate),
        KindergartenLocalitiesRepository: new KindergartenLocalitiesRepository(
          localities,
        ),
        KindergartensRepository: new KindergartensRepository(kindergartens),
      }, trans);
    },
  },
};

export default KindergartenListProviders;
