import {
  QField,
  QSelect,
  QForm,
  QInput,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import Validation from '@vjs/mixins/Validation';
import VSelectFromListOption from '../VSelectFromListOption';

export default {
  name: 'VStatementQuestionnaire',
  mixins: [Validation],
  components: {
    QInput,
    QSelect,
    QField,
    QForm,
    VSelectFromListOption,
  },

  props: {
    getDataUrl: {
      type: String,
      required: true,
    },
    getSchoolsUrl: {
      type: String,
      required: true,
    },
    submitUrl: {
      type: String,
      required: true,
    },
    parentInfo: {
      iin: null,
      phone: null,
      mail: null,
    },
    childrens: {
      type: Array,
      default: () => ([]),
    },
    childId: null,
    localityId: null,
    fromStatementCreate: {
      type: Boolean,
      default: false,
    },
    createStatementType: null,
    stepView: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({
        firstAdditionalPhone: null,
        secondAdditionalPhone: null,
        thirdAdditionalPhone: null,
        email: null,
        firstSchool: null,
        secondSchool: null,
        thirdSchool: null,
        fourthSchool: null,
      }),
    },
  },

  beforeCreate() {
    this.$trans.add([
      'label',
      'warning_text',
      'statement',
    ]);
  },

  async created() {
    this.$eventHub.$on('SET_NEXT_STEP', this.onSubmit);
    if (this.fromStatementCreate) {
      await this.getData();
    }
  },

  beforeDestroy() {
    this.$eventHub.$off('SET_NEXT_STEP', this.onSubmit);
  },

  data() {
    return {
      loaded: false,
      form: {
        parentIin: this.parentInfo.iin,
        children: this.childId,
        phone: this.parentInfo.phone,
        firstAdditionalPhone: this.formData.firstAdditionalPhone,
        secondAdditionalPhone: this.formData.secondAdditionalPhone,
        thirdAdditionalPhone: this.formData.thirdAdditionalPhone,
        statementType: this.formData.statementType,
        firstSchool: this.formData.firstSchool,
        secondSchool: this.formData.firstSchool,
        thirdSchool: this.formData.thirdSchool,
        fourthSchool: this.formData.fourthSchool,
        email: this.parentInfo.email ?? this.formData.email,
      },
      refs: {
        childrens: this.childrens,
        schools: [],
        statementTypes: [],
      },
    };
  },

  watch: {
    canSubmit(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 6,
        access: val,
      });
    },
  },

  computed: {
    canSubmit() {
      return this.form.phone !== null && this.form.parentIin !== null
        && this.form.children !== null && this.form.firstAdditionalPhone !== null
        && this.form.statementType !== null && this.loaded;
    },
  },

  methods: {
    async getData() {
      if (this.form.children == null && !this.fromStatementCreate) {
        this.loaded = false;
        return;
      }
      this.loaded = false;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.getDataUrl,
        params: {
          localityId: this.localityId,
          childId: this.childId || this.form.children,
          type: this.createStatementType || this.form.statementType?.value,
        },
      });

      if (!res.error) {
        this.form = { ...this.form, ...res.data.formData };
        this.refs = res.data.refs;
        if (!this.fromStatementCreate) {
          this.refs.childrens = this.childrens;
        }
        this.loaded = true;
      } else {
        this.loaded = false;
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async loadSchools() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.getSchoolsUrl,
        params: {
          localityId: this.form.statementType.localityId,
          type: this.form.statementType?.value,
        },
      });

      if (!res.error) {
        this.refs.schools = res.data;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async onSubmit() {
      this.$globalLoading.show();

      const data = {
        ...this.form,
        statementType: this.form.statementType.value,
        statementId: this.form.statementType.statementId,
        // fromStepView: this.stepView,
      };

      if (!this.fromStatementCreate) {
        await requestWrapper.call(this, {
          method: 'POST',
          url: this.submitUrl,
          data,
        });
      } else {
        this.$emit('submit', data);
      }

      this.$globalLoading.hide();
    },
  },
};
