import KindergartenGroupListApiProvider
from '../../../providers/Kindergarten/KindergartenGroupListApiProvider';
import KindergartensGroupsRepository
from '../../../repositories/KindergartensGroupsRepository';

const KindergartenListProviders = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ groups }) {
      const trans = this.$trans.get;
      this.apiProvider = new KindergartenGroupListApiProvider({
        KindergartensGroupsRepository: new KindergartensGroupsRepository(groups),
      }, trans);
    },
  },
};

export default KindergartenListProviders;
