<template>
  <div>
    <div
      class="mb-20"
      style="max-height: 50rem; overflow: scroll;border: 2px solid #DFE2ED; padding: 2rem;"
      v-html="body"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.sign')"
        @click="handleSignBtn"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: String,
      required: true,
    },
    signConfig: {
      type: Object,
      default: () => ({
        ncaLayerAction: null,
        getData: null,
        updateQr: null,
      }),
    },
  },
  methods: {
    async handleSignBtn() {
      this.$sModal.open('v-modal-egov-sign', {
        component: 'v-modal-egov-sign',
        binds: this.signConfig,
        size: 800,
      });
    },
  },
};
</script>
