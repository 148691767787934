export default class KindergartenListApiProvider {
  constructor({
    KindergartenLocalitiesRepository,
    KindergartensRepository,
    CalculateAllRepository,
    ConfirmCalculateRepository,
    DenyCalculateRepository,
  }, trans) {
    this.confirmCalculateRepository = ConfirmCalculateRepository;
    this.denyCalculateRepository = DenyCalculateRepository;
    this.kindergartenLocalitiesRepository = KindergartenLocalitiesRepository;
    this.kindergartensRepository = KindergartensRepository;
    this.calculateAllRepository = CalculateAllRepository;
    this.trans = trans;
  }

  provideListLocalitiesByDepartmentId() {
    const { kindergartenLocalitiesRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (departmentId, currentDate) {
      try {
        const response = await kindergartenLocalitiesRepository
          .listLocalitiesByDepartment(departmentId, currentDate);
        const {
          status,
          localities,
          date,
          dates: availableDates,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.department'),
          });
        } else {
          return { localities, date, availableDates };
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.department'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'server error',
            text: error,
          });
        }
      }

      return [];
    };
  }

  provideListKindergartensByLocalityId() {
    const { kindergartensRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (localityId, date) {
      try {
        const response = await kindergartensRepository
          .listKindergartensByLocalityId(localityId, date);
        const {
          status,
          kindergartens,
          total,
          leftToFundCalc,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.locality'),
          });
        } else {
          return {
            kindergartens,
            total,
            leftToFundCalc,
          };
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.locality'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'server error',
            text: error,
          });
        }
      }

      return {};
    };
  }

  provideCalculateAll() {
    const { calculateAllRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (localityId, date) {
      try {
        const response = await calculateAllRepository
          .calculate(localityId, date);
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'error',
            text: message,
          });
        }

        return response;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.locality'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'server error',
            text: error,
          });
        }
      }

      return [];
    };
  }

  provideConfirm() {
    const { confirmCalculateRepository } = this;
    // eslint-disable-next-line func-names
    return async function (calcIid, date) {
      try {
        const response = await confirmCalculateRepository
          .confirm(calcIid, date);
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'error',
            title: message,
          });
        } else {
          return response;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return [];
    };
  }

  provideDeny() {
    const { denyCalculateRepository } = this;
    // eslint-disable-next-line func-names
    return async function (calcIid, date) {
      try {
        const response = await denyCalculateRepository
          .deny(calcIid, date);
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'error',
            text: message,
          });
        } else {
          return response;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return [];
    };
  }
}
