<template>
  <div
    class="s-info-card"
    style="margin-bottom: 3rem;"
  >
    <div class="s-info-card--card">
      <div class="s-info-card--wrapper">
        <div
          class="s-info-card--title s-info-card--title-light-icon"
        >
          <span
            class="s-str-ico-fa s-str-ico-fa--thm-file-alt"
            v-html="info.title"
          />
          <q-btn
            width="15"
            color="orange"
            no-caps
            size="14px"
            :label="trans('button.export')"
            type="a"
            target="_blank"
            :to="uriExport"
          />
        </div>

        <div class="s-application-block__body">
          <div class="row mb-20">
            <div
              v-for="(itm, key) in info.common"
              :key="key"
              class="col-lg-4 col-md-6 col-sm-12"
            >
              <div class="s-desc-item">
                <div class="s-desc-item__label mb-5">
                  {{ itm.label }}
                </div>
                <div class="s-desc-item__value">
                  {{ itm.value }}
                </div>
              </div>
            </div>
          </div>

          <q-table
            :data="rows"
            :columns="cols"
            separator="cell"
            :pagination="{rowsPerPage: 0}"
            flat
            hide-bottom
            wrap-cells
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <template v-for="(col,i) in props.cols">
                  <q-td
                    v-if="!['__index'].includes(col.field)"
                    :key="i"
                    class="s-to-center"
                    v-html="col.value"
                  />
                </template>
              </q-tr>
            </template>
            <template v-slot:bottom-row="props">
              <q-tr :props="props">
                <q-td
                  :colspan="props.cols.length"
                  class="s-to-left"
                >
                  <div class="mt-10 mb-10">
                    <div
                      class="d-flex"
                      style="margin-bottom: 5px;"
                    >
                      <div
                        class="s-medium"
                        style="margin-right: 2rem;"
                      >
                        {{ trans('parent_payment.notification.payer') }}
                      </div>
                      <div>
                        {{ info.payer }}
                      </div>
                    </div>

                    <div class="d-flex">
                      <div
                        class="s-medium"
                        style="margin-right: 2rem;"
                      >
                        {{ trans('parent_payment.notification.date') }}
                      </div>
                      <div>
                        {{ info.date }}
                      </div>
                    </div>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:bottom="props" />
          </q-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.s-info-card--title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sn-btn {
    margin-left: 1.5rem;
  }
}

@media screen and ( max-width: 600px ) {
  .s-info-card--title {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;

    .s-str-ico-fa {
      order: 2;
    }

    .sn-btn {
      order: 1;
      margin-bottom: 1.5rem;
      margin-left: unset;
    }
  }
}
</style>

<script>
import {
  QTd, QTr, QTable,
} from '@quasar/components';

export default {
  name: 'VParentPaymentNotificationCard',
  components: {
    QTd,
    QTr,
    QTable,
  },
  props: {
    cols: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    uriExport: {
      type: String,
      required: true,
    },
  },
};

</script>
