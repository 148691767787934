import { QBtn } from '@quasar/components';
import { NumberFormatter } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VFundingDocumentsTreeBody',

  components: { QBtn },
  mixins: [NumberFormatter],

  props: {
    cols: {
      type: Array,
      default: () => ([]),
    },
    rows: {
      type: Array,
      default: () => ([]),
    },
    uploadDirectionUrl: {
      type: String,
      default: '',
    },
    deleteDirectionUrl: {
      type: String,
      default: '',
    },
    fundingDocumentId: {
      type: Number,
      default: null,
    },
    labels: {
      type: Object,
      default: () => ({
        modalTitle: '',
        buttonAdd: '',
        view: '',
        areYouSure: '',
      }),
    },
    role: {
      type: String,
      default: 'kindergartenWorker',
    },
  },

  data() {
    return {
      localRows: [],
      renderComponents: [],
      tmpRenderComponents: [],
    };
  },

  watch: {
    rows: {
      handler() {
        this.initRender();
      },
      deep: true,
    },
  },

  mounted() {
    this.initRender();
  },

  methods: {
    initRender() {
      this.localRows = this.rows;
      this.renderComponents = [];
      _.forEach(this.localRows, row => this.renderRow(row));
      this.renderComponents = this.tmpRenderComponents;
      this.tmpRenderComponents = [];
    },
    renderTd(value, name, withChilds, rowData) {
      return (
        <td
          class={name !== 'data' ? 's-to-center' : '' }
          style='1rem'
        >
          <div class={name === 'data' ? 'd-flex align-items-center' : '' }>
          {
            withChilds && name === 'data'
              ? <q-btn
                size="xs"
                dense
                round
                icon={rowData.expand ? 'minus' : 'plus'}
                flat
              />
              : null
          }
          {
            this.renderTdValue(name, value, rowData?.contingentId)
          }
          </div>
        </td>
      );
    },

    // eslint-disable-next-line consistent-return
    renderRow(rowData) {
      const vm = this;
      const { tmpRenderComponents } = this;

      const withChildren = (rowData.childrens && rowData.childrens.length);

      tmpRenderComponents.push(
        (
          <tr
            onClick={async () => {
              rowData.expand = !rowData.expand;
              _.forEach(vm.localRows, row => this.renderRow(row));
              this.renderComponents = this.tmpRenderComponents;
              this.tmpRenderComponents = [];
            }}
          >
            {
              this.cols.map(col => this.renderTd(rowData[col.name],
                col.name, withChildren, rowData))
            }
          </tr>
        ),
      );
      if (withChildren && rowData.expand) {
        _.forEach(rowData.childrens, child => this.renderRow(child));
      }
    },
    renderTdValue(name, value, contingentId = null) {
      if (name === 'absentReasonDocuments' && value !== '-' && value !== null) {
        return (
          <q-btn
            squire
            type="a"
            target="_blank"
            icon="eye"
            color="blue"
            to={value}
          />
        );
      }
      if (name === 'direction' && value !== '-') {
        if (value === null) {
          return (
            <q-btn
              v-s-modal={{
                title: this.labels.modalTitle,
                id: 'v-upload-file-modal',
                component: 'v-upload-file-modal',
                notPersistent: true,
                events: {
                  modalSubmit: async file => this.uploadDirection(
                    file,
                    contingentId,
                  ),
                },
              }}
              squire
              label={this.labels.buttonAdd}
              icon-right="plus"
              color="blue"
            />
          );
        }
        if (!value.uploaded) {
          return <a target="_blank" href={value.directionUrl}>{this.labels.view}</a>;
        }
        return (
          <div class="s-group s-group--wider s-group--to-center">
            <q-btn
              squire
              type="a"
              target="_blank"
              icon="eye"
              outline
              color="blue"
              to={value.directionUrl}
            />
            {
              this.role === 'kindergartenWorker'
                ? <q-btn
                v-s-modal={{
                  id: 'v-modal-confirm',
                  title: this.labels.areYouSure,
                  component: 'v-modal-confirm',
                  binds: {
                    type: 'event',
                  },
                  events: { onApprove: async () => this.deleteDirection(value.id) },
                }}
                squire
                icon="trash"
                color="red"
                outline
              />
                : null
            }
          </div>
        );
      }
      return (
        name === 'timesheet'
        && value !== '-' && value !== null
          ? <a target="_blank" href={value}>{this.labels.view}</a>
          : value
      );
    },
    async uploadDirection(file, contingentId) {
      if (file) {
        const url = this.uploadDirectionUrl;
        const formData = new FormData();
        formData.append('directionFile', file[0]);
        formData.append('fundingDocumentId', this.fundingDocumentId);
        formData.append('contingentId', contingentId);
        formData.append('type', 19);
        this.$globalLoading.show();
        const res = await requestWrapper.call(this, {
          url,
          data: formData,
          method: 'post',
        });
        if (!res.error) {
          this.$emit('onDirectionUploaded');
        } else {
          Helper.handlerResponseErrorNew(this, res);
        }
        this.$globalLoading.hide();
      }
    },
    async deleteDirection(id) {
      this.$globalLoading.show();
      const url = this.deleteDirectionUrl;
      const res = await requestWrapper.call(this, {
        url,
        data: {
          directionFileId: id,
        },
        method: 'post',
      });
      if (!res.error) {
        this.$emit('onDirectionDeleted');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },

  render() {
    return (
      <tbody>
      { this.renderComponents }
    </tbody>
    );
  },
};
