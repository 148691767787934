import {
  QTd, QTh, QTr, QTable,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import { scrollTop } from '@vjs/helpers';
import Pagination from '@common/components/Pagination';
import VFoodTechnologicCard from './VFoodTechnologicCard';

export default {
  name: 'VFoodTechnologicalMap',
  components: {
    QTd,
    QTh,
    QTr,
    QTable,
    VNoResults,
    VFoodTechnologicCard,
    Pagination,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getItems: '',
        getRefs: '',
        store: '',
      }),
    },
    isContingent: {
      type: Boolean,
      default: false,
    },
    isCook: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      isEdit: false,
      refs: {
        products: [],
      },
      items: [],
      cols: [],
      links: [],
      meta: {},
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button', 'food', 'placeholder', 'select']);
    if (this.config.getRefs) {
      await requestGetDataHelper(this, {
        url: this.config.getRefs,
        variableName: 'refs',
      });
    }
    await this.handleGetItems(this.config.getItems);
  },

  computed: {
    isNewItemActive() {
      return !!this.items?.[0]?.isNew;
    },
  },

  methods: {
    async handleGetItems(url) {
      const res = await requestGetDataHelper(this, { url, returnData: true, fullRes: true });
      if (res) {
        this.$set(this, 'links', res.generated_links);
        this.$set(this, 'meta', res.meta);
        this.$set(this, 'cols', res.cols);
        this.$set(this, 'items', []);
        this.$nextTick(() => { this.$set(this, 'items', res.data); });
      }
    },
    addItem() {
      const item = {
        name: '',
        description: '',
        isNew: true,
        ingredients: [{}],
      };
      const items = _.cloneDeep(this.items);
      items.unshift(item);
      this.$set(this, 'items', []);
      this.$nextTick(() => { this.$set(this, 'items', items); });
    },
    handleUpdateCard(key, card) {
      this.$set(this.items, key, card);
    },
    handleDeleteCard(key) {
      const items = _.cloneDeep(this.items);
      this.$delete(items, key);
      this.$set(this, 'items', []);
      this.$nextTick(() => { this.$set(this, 'items', items); });
    },
    async updatePage(link) {
      await this.handleGetItems(link);
      await requestGetDataHelper(this, { url: link, variableName: 'items', hardSet: true });
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.wrapperRef.getBoundingClientRect().top),
        500,
      );
    },
  },
};
