<template>
  <q-dialog
    persistent
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('notice.confirm')"
      size="700"
      in-body
    >
      <v-form-inform
        color="red"
        :message="informMessage"
      />
      <q-form
        class="v-statement-contract-revision-modal"
        @submit="requestAction"
      >
        <div class="v-statement-contract-revision-modal__title">
          {{ trans('label.statement_contract.select_files') }}
        </div>
        <div class="mb-20">
          <q-checkbox
            v-for="(docType, key) in docsTypes"
            :key="key"
            v-model="form.selectedDocs"
            :val="key"
            class="s-w-100"
            :label="docType"
          />
        </div>
        <q-input
          v-model="form.comment"
          :label="trans(`label.statement_contract.comment`)"
          filled
          class="mb-30"
          type="textarea"
          required
          counter
          :maxlength="1000"
          :error="hasValidationFail('comment')"
          :error-message="getValidationMessage('comment')"
          @input="flashValidationFail('comment')"
        />
        <div class="s-group s-group--wider justify-content-between">
          <q-btn
            width="20"
            no-caps
            color="grey"
            :label="trans('button.close')"
            @click="handleModal"
          />
          <q-btn
            type="submit"
            width="20"
            no-caps
            color="orange"
            :label="trans('button.send_to_revision')"
          />
        </div>
      </q-form>
    </v-modal>
  </q-dialog>
</template>

<style lang="scss">
.v-statement-contract-revision-modal {
  padding: 2.5rem;

  .sn-textarea .sn-field__native {
    height: 8rem;
  }

  .sn-checkbox {
    align-items: center;

    &__inner {
      margin-right: 1rem;
    }

    &__bg {
      width: 2.7rem;
      height: 2.7rem;
    }

    &__label {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #212545;
    margin-bottom: 1.5rem;
  }
}
@media screen and ( max-width: 530px ) {
  .v-statement-contract-revision-modal {
    padding: unset;
  }
}
</style>

<script>
import {
  QDialog,
  QBtn,
  QForm,
  QCheckbox,
  QInput,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform';
import { requestWrapper } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VStatementContractRevisionModal',
  components: {
    QDialog,
    QBtn,
    QForm,
    QCheckbox,
    QInput,
    VModal,
    VFormInform,
  },
  mixins: [Validation],
  props: {
    modal: Boolean,
    forCorrection: Boolean,
    informMessage: {
      type: String,
      default: '',
    },
    uriAction: {
      type: String,
      required: true,
    },
    docsTypes: {
      type: [Array, Object],
      default: () => ([]),
    },
  },
  data() {
    return {
      form: {
        selectedDocs: [],
        comment: '',
      },
    };
  },
  methods: {
    async handleModal() {
      if (!this.loading) {
        this.$emit('modal-close', false);
      }
    },
    async requestAction() {
      this.$emit('loading', true);
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        this.$emit('loading', false);
      }
    },
  },
};
</script>
