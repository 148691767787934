// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

import _ from 'lodash';

// noinspection NpmUsedModulesInstalled
import {
  QSeparator,
  QSelect,
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QSpinner,
  QTooltip,
  QIcon,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import VNoResults from '@vjs/components/VNoResults';
import axios from 'axios';
import Localization from '../../Mixins/QuasarLocale';
import DateYearMonthPicker from '../../Mixins/DateYearMonthPicker';
import PriceFormat from '../../Mixins/PriceFormat';
import KindergartenListProvidersMixin from '../Mixins/KindergartenListProviders';

export default {
  name: 'VVoucherFundingKindergartenSpecialList',
  components: {
    QSeparator,
    QSelect,
    QTable,
    QTr,
    QTd,
    QTh,
    QBtn,
    QSpinner,
    QTooltip,
    VNoResults,
    QIcon,
  },
  mixins: [
    Localization, PriceFormat,
    DateYearMonthPicker, KindergartenListProvidersMixin,
  ],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
    detailUrl: {
      type: String,
      default: () => 'string',
    },
    type: {
      type: String,
      default: () => 'funding',
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const { dataConfig: { department_id, date } } = this;

    return {
      locale,
      departmentId: department_id,
      currentDate: date,
      availableDepartments: [],
      selectedDepartment: null,
      kindergartens: [],
      departmentLoad: false,
      kindergartensLoad: false,
      totalPlan: 0,
      totalFact: 0,
      denyStatus: false,
      denied: true,
      loading: false,
    };
  },
  computed: {
    anyIsLoading() {
      const { departmentLoad, kindergartensLoad } = this;
      return departmentLoad || kindergartensLoad;
    },
    columns() {
      const { currentDate, currentYear } = this;
      const trans = this.$trans;
      return [
        {
          name: 'name',
          field: 'name',
          label: trans.get('funding.kindergarten.table.locality'),
        },
        // {
        //   name: 'throughput',
        //   field: 'throughput',
        //   label: trans.get('funding.kindergarten.table.throughput'),
        // },
        // {
        //   name: 'count',
        //   field: 'count',
        //   label: trans.get('funding.kindergarten.table.contingent'),
        // },
        // {
        //   name: 'fundingPlanCount',
        //   field: 'fundingPlanCount',
        //   label: trans.get('funding.kindergarten.table.funding-count'),
        // },
        {
          name: 'total',
          field: 'total',
          label: trans.get('funding.kindergarten.table.funding-fact-date', {
            date: currentDate.split('-').slice(0, 2).join('-'),
          }),
        },
        // {
        //   name: 'fundingPlanYear',
        //   field: 'fundingPlan',
        //   label: trans.get('funding.kindergarten.table.funding-fact-year', {
        //     year: currentYear,
        //   }),
        // },
        {
          name: 'id',
          field: 'id',
          label: '',
        },
      ];
    },
  },
  watch: {
    selectedDepartment(value, old) {
      if (
        old === null
        || value.id !== old.id
      ) {
        const {
          loadKindergartens,
          setLastLocality,
        } = this;
        loadKindergartens(value.id);
        setLastLocality(value.id);
      }
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_TRANSLATIONS);
  },
  async beforeMount() {
    const {
      apiConfig,
    } = this;
    await this.initLocale();
    await this.initProvider(apiConfig);
    await this.loadLocalities();
    await this.setDefaultDate();
  },
  methods: {
    getLocalityById(id) {
      const { availableDepartments } = this;
      const department = availableDepartments
        .reduce((acc, item) => {
          if (acc) {
            return acc;
          }
          return parseInt(id, 10) === parseInt(item.id, 10) ? item : null;
        }, null);

      return department || availableDepartments.slice(0, 1)
        .shift();
    },
    lastLocality() {
      const { departmentId } = this;
      if (departmentId) {
        const key = `selected-funding-department-${departmentId}`;
        return localStorage.getItem(key);
      }
      return null;
    },
    setLastLocality(value) {
      const { departmentId } = this;
      if (departmentId && value) {
        const key = `selected-funding-department-${departmentId}`;
        localStorage.setItem(key, value);
      }
    },
    async loadLocalities() {
      const {
        apiProvider, departmentId, lastLocality, getLocalityById, selectedDate,
      } = this;
      this.departmentLoad = true;

      const { localities, date, availableDates } = await apiProvider
        .provideListLocalitiesByDepartmentId()
        .apply(this, [departmentId, selectedDate]);
      this.departmentLoad = false;
      if (
        localities
        && Array.isArray(localities)
        && localities.length
      ) {
        this.availableDepartments = localities;

        this.selectedDepartment = getLocalityById(lastLocality());

        // noinspection JSUnresolvedVariable
        this.denied = this.selectedDepartment.denied;
      }
      if (date) {
        this.currentDate = date;
      }
      if (availableDates) {
        this.availableDates = availableDates;
      }
    },
    async loadKindergartens(departmentId) {
      const { apiProvider, selectedDate } = this;
      this.kindergartens = [];
      this.kindergartensLoad = true;
      this.totalPlan = 0;
      this.totalFact = 0;
      const response = await apiProvider
        .provideListKindergartensByLocalityId()
        .apply(this, [departmentId, selectedDate]);
      const { kindergartens, total, leftToFundCalc } = response;
      this.kindergartensLoad = false;
      if (
        kindergartens
        && Array.isArray(kindergartens)
        && kindergartens.length
      ) {
        this.kindergartens = kindergartens;
      }
      // noinspection JSUnresolvedVariable
      this.totalPlan = total.plan || 0;
      // noinspection JSUnresolvedVariable
      this.totalFact = total.fact || 0;
    },
    onChangeYear(val) {
      this.currentYear = val;
      this.currentMonth = _.first(this.availableDates[val]);
      // noinspection JSIgnoredPromiseFromCall
      this.loadLocalities();
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedDepartment.id);
    },
    onChangeMonth(val) {
      this.currentMonth = val;
      // noinspection JSIgnoredPromiseFromCall
      this.loadLocalities();
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedDepartment.id);
    },
    async exportu(e) {
      this.loading = true;
      axios({
        url: `${this.apiConfig.export.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          department_id: this.selectedDepartment.id,
          export: true,
          date: this.currentDate,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } - ${this.$trans.get('funding.kindergarten.table.funding-fact-date', {
            date: this.currentDate.split('-').slice(0, 2).join('-'),
          })}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
