<template>
  <q-form
    class="v-new-product-inventory-dialog"
    @submit="requestAction"
  >
    <p> {{ trans(`button.food.product_accounting_inventoried`) }} :</p>
    <div v-for="(item) in inventoryList">
      <div>
     {{ trans('label.created_date') }} : {{ item.createdAt }},
        {{ trans('label.food.product_inventory.amount') }} : {{ item.amount }}
      </div>
    </div>
    <p></p>
    <q-input
      v-model="measure_name"
      :label="trans(`label.food.product_accounting.measure_type`)"
      class="mb-30"
      :disable="true"
    />
    <q-input
      v-model="inv_amount"
      type="number"
      :label="trans(`label.food.product_accounting.inv_amount`)"
      class="mb-30"
      :error="hasValidationFail('inv_amount')"
      :error-message="getValidationMessage('inv_amount')"
      @input="flashValidationFail('inv_amount')"
    />
    <div class="s-group s-group--wider justify-content-between">
      <q-btn
        width="20"
        no-caps
        color="grey"
        :label="trans('button.close')"
        @click="$emit('onClose')"
      />
      <q-btn
        type="submit"
        width="20"
        no-caps
        color="orange"
        :label="trans('button.save')"
      />
    </div>
  </q-form>
</template>


<script>
import {
  QBtn,
  QForm,
  QInput,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VNewProductInventoryDialog',
  components: {
    QBtn,
    QForm,
    QInput,
  },
  mixins: [Validation],
  props: {
    row: {
      type: Object,
      required: true,
    },
    uriAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inv_amount: this.row.inv_amount,
      measure_name: this.row.measure_name,
      inventoryList: this.row.inventoryList,
    };
  },
  methods: {
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: 'patch',
        data: {
          inv_amount: this.inv_amount,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onSuccess');
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
