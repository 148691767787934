import VNoResults from '@vjs/components/VNoResults';
import VParentPaymentNotificationCard from './VParentPaymentNotificationCard';

export default {
  name: 'VParentPaymentNotificationsView',
  components: {
    VNoResults,
    VParentPaymentNotificationCard,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getData: '',
      }),
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      filters: {
        year: currentDayHelper.year,
        month: currentDayHelper.month,
      },
      refs: {
        years: [],
        months: [],
      },
      data: [],
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
    cols() {
      const trans = field => this.trans(`parent_payment.notification.cols.${field}`);
      return [
        { align: 'center', label: trans('name'), field: 'name' },
        { align: 'center', label: trans('iin'), field: 'iin' },
        { align: 'center', label: trans('group'), field: 'group' },
        { align: 'center', label: trans('month'), field: 'month' },
        { align: 'center', label: trans('sum'), field: 'sum' },
      ];
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button', 'parent_payment']);
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await requestGetDataHelper(this, {
      url: this.config.getData, variableName: 'data', params: this.filters, hardSet: true,
    });
  },
};
