<template>
  <v-info-card
    :title="trans('voucher.application_show.group_details')"
    icon="list"
    class="s-table-with-filters"
    with-spoiler
  >
    <q-table
      :data="data"
      :columns="cols"
      separator="cell"
      flat
      wrap-cells
      :no-data-label="trans('label.no_data')"
      :no-results-label="trans('label.no_results')"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td>
            {{ props.row.agesImploded }}
          </q-td>
          <q-td align="center">
            {{ props.row.childCount }}
          </q-td>
          <q-td align="center">
            {{ props.row.freePlaces }}
          </q-td>
          <q-td align="center">
            {{ props.row.lang.name }}
          </q-td>
        </q-tr>
      </template>
      <template v-slot:no-data>
        <v-no-results
          image="search"
          class="s-w-100"
          :title="trans('voucher.application_show.groups_no_results_title')"
          :text="trans('voucher.application_show.groups_no_results_text')"
        />
      </template>
    </q-table>
  </v-info-card>
</template>

<script>
import VNoResults from '@vjs/components/VNoResults';
import { QTable, QTr, QTd } from '@quasar/components';
import VInfoCard from '@common/components/VInfoCard';

export default {
  name: 'VVoucherApplicationFormGroupsTable',
  components: {
    QTable, QTr, QTd, VInfoCard, VNoResults,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },

  computed: {
    cols() {
      return [
        {
          label: this.trans('voucher.application_form.group_ages'),
          name: 'ages',
          field: 'ages',
          align: 'left',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_child_count'),
          name: 'childCount',
          field: 'childCount',
          align: 'center',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_free_places'),
          name: 'freePlaces',
          field: 'freePlaces',
          align: 'center',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_lang'),
          name: 'lang',
          field: 'lang',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
};

</script>
