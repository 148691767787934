export default {
  props: {
    docInfo: {
      type: Object,
      default: () => ({
        source: null,
        mimeType: null,
      }),
    },
    uriSign: {
      type: String,
      required: true,
    },
    signConfig: {
      type: Object,
      default: () => ({
        action: null,
        getData: null,
        updateQr: null,
      }),
    },
  },
  async mounted() {
    await this.$trans.add(['voucher', 'label']);
  },
  methods: {
    async handleSignBtn() {
      this.$sModal.open('v-modal-egov-sign', {
        component: 'v-modal-egov-sign',
        binds: this.signConfig,
        size: 800,
      });
    },
  },
};
