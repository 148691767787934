import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';

export default {
  name: 'VFreePlacePredictions',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
  },
  props: {
    uriLocality: {
      type: String,
      default: '',
    },
    uriGetData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filters: {
        localities: [],
        schools: [],
        schoolTypes: [],
      },
      defaultFilter: {
        label: null,
        value: null,
      },
      table: {
        cols: [],
        rows: [],
        pagination: {
          rowsPerPage: 10,
          page: 1,
          rowsNumber: 0,
        },
      },
      locality: {
        value: null,
      },
      school: {
        value: null,
      },
      schoolType: {
        value: null,
      },
    };
  },

  computed: {
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'warning_text']);
  },

  async beforeMount() {
    await this.loadLocalities();
    await this.getTable();
    this.resetFilters();
    this.defaultFilter.label = this.trans('label.not_selected');
  },

  methods: {
    async loadLocalities() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriLocality,
      });

      this.$globalLoading.hide();

      if (!res.error) {
        this.filters.localities = res.data;
        // eslint-disable-next-line prefer-destructuring
        this.locality = this.filters.localities[0]?.value;
      }

      Helper.handlerResponseErrorNew(this, res);
    },
    async getTable(data) {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: {
          localityId: this.locality,
          schoolId: this.school,
          schoolType: this.schoolType,
          rowsPerPage: data?.pagination?.rowsPerPage ?? this.table.pagination.rowsPerPage,
          page: data?.pagination?.page ?? this.table.pagination.page,
          rowsPerPage: data?.pagination?.rowsPerPage ?? this.table.pagination.rowsPerPage,
          page: data?.pagination?.page ?? this.table.pagination.page,
        },
      });

      this.$globalLoading.hide();

      if (!res.error) {
        this.filters.schools = [...[this.defaultFilter], ...res.data.schools];
        this.filters.schoolTypes = [...[this.defaultFilter], ...res.data.schoolTypes];
        this.table.cols = res.data.cols;
        this.table.rows = res.data.rows;
        this.table.pagination = res.data.pagination;
      }

      Helper.handlerResponseErrorNew(this, res);
    },
    resetFilters() {
      this.school = this.defaultFilter.value;
      this.schoolType = this.defaultFilter.value;
    },
  },
};
