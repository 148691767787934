import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import GroupsTable from './GroupsTable';

export default {
  name: 'VVoucherApplicationForm',
  components: { GroupsTable },
  props: {
    action: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: true,
    },
    urlRefs: {
      type: String,
      required: true,
    },
    preloadFiles: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        organization_id: null,
        name: null,
        bin: null,
        throughput: null,
        group_count: null,
        group_details: [],

        date: currentDayHelper.date,
      },
      files: {
        ses_certificate_file: this.preloadFiles?.ses_certificate_file ?? [],
        pb_certificate_file: this.preloadFiles?.pb_certificate_file ?? [],
        bvu_certificate_file: this.preloadFiles?.bvu_certificate_file ?? [],
        child_list_file: this.preloadFiles?.child_list_file ?? [],
        start_of_activity: this.preloadFiles?.start_of_activity ?? [],
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'voucher']);
    await requestGetDataHelper(this, {
      url: this.urlRefs,
      variableName: 'form',
    });
  },

  methods: {
    currencyFormatter(number, digits = 2) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(formattedNumber);
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data: { ...this.form, files: this.files },
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
