import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QField,
  QRadio,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import VReferenceTime from '@vjs/components/VReferenceTime';
import Validation from '../mixins/Validation';

export default {
  name: 'VStepAddChild',

  components: {
    QForm,
    QInput,
    QBtn,
    QField,
    QRadio,
    VFormInform,
    VReferenceTime,
  },

  mixins: [Validation],

  props: {
    uriSendChildIin: {
      type: String,
      required: true,
    },
    uriSendChild: {
      type: String,
      required: true,
    },
    referenceTime: Number,
  },

  data() {
    return {
      loading: false,
      access: false,
      iinAfterRequest: '',
      form: {
        iin: null,
        lastname: '',
        firstname: '',
        middlename: '',
        birthday: '',
        genderCurrent: null,
        genderOptions: [
          {
            value: 'm',
            label: 'label.male',
          },
          {
            value: 'w',
            label: 'label.female',
          },
        ],
      },
      genderFromIin: false,
    };
  },

  beforeCreate() {
    this.$trans.add(['label', 'placeholder', 'validation']);
  },

  created() {
    this.$eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    this.$eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    checkIinDisable() {
      return !this.validateIin(this.form.iin);
    },
    validate() {
      return this.form.genderCurrent !== null
        && this.validateIin(this.form.iin)
        && this.form.firstname !== ''
        && this.form.middlename !== ''
        && this.form.lastname !== '';
    },
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 4,
        access: val,
      });
    },
    'form.iin': function (val) {
      if (this.iinAfterRequest !== val) {
        this.resetFields();
      }
    },
  },

  methods: {
    async requestForCheckIin() {
      this.loading = true;
      const iinRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendChildIin,
        data: {
          iin: this.form.iin,
        },
      });

      if (iinRes.error) {
        this.resetFields();
        this.loading = false;
        return;
      }

      this.access = true;
      this.iinAfterRequest = this.form.iin;
      this.form.firstname = iinRes.data.firstname;
      this.form.lastname = iinRes.data.surname;
      this.form.middlename = iinRes.data.lastname;
      this.form.birthday = iinRes.data.born_date;
      this.form.genderCurrent = iinRes.data.sex;
      this.genderFromIin = !!this.form.genderCurrent;
      this.loading = false;
    },

    async requestForm() {
      this.$emit('loading', true);
      const sendFormRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendChild,
        data: {
          iin: this.form.iin,
          firstname: this.form.firstname,
          surname: this.form.lastname,
          lastname: this.form.middlename,
          born_date: this.form.birthday,
          sex: this.form.genderCurrent,
        },
      });

      if (sendFormRes.error) {
        this.$emit('loading', false);
        return false;
      }

      this.$emit('send-data', sendFormRes.data);
      this.$emit('loading', false);
      this.$store.commit('SET_NEXT_STEP');
      return true;
    },

    resetFields() {
      this.form.firstname = '';
      this.form.lastname = '';
      this.form.middlename = '';
      this.form.birthday = '';
      this.form.genderCurrent = null;
      this.genderFromIin = false;
    },
  },
};
