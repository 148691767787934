<template>
  <div>
    <div class="mb-30">
      {{ trans('funding_documents.reason_modal_body') }}
    </div>
    <div>
      <q-input
        v-model="reason"
        type="textarea"
        class="mb-20"
      />
      <div class="s-group s-group--wider s-group--to-center">
        <q-btn
          width="20"
          :label="trans('button.cancel')"
          color="grey"
          @click="handleModal"
        />
        <q-btn
          width="20"
          :label="trans('button.submit')"
          color="green"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn } from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import QInput from '@quasar/components/input/QInput';

export default {
  name: 'VFundingDocumentsReasonModal',
  components: {
    QInput,
    QBtn,
  },
  directives: { ClosePopup },
  props: {
  },
  data() {
    return {
      reason: '',
    };
  },
  async mounted() {
    await this.$trans.add([
      'funding_documents',
      'button',
    ]);
  },
  methods: {
    async handleSubmit() {
      this.$emit('onSubmit', this.reason);
    },
    async handleModal() {
      this.$emit('onClose');
    },
  },
};
</script>
