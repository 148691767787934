import Vue from 'vue';
import _ from 'lodash';
import VueResource from 'vue-resource';
import { SELECT_ROLE_TRANSLATIONS, FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';
import Localization from '../Mixins/QuasarLocale';
import VMultiSelectField from '../../components/VMultiSelectField';

Vue.use(VueResource);

export default {
  mixins: [Localization],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    organizations: {
      type: Array,
      required: true,
    },
    organizationId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    locale: {
      type: String,
      default: () => FALLBACK_LOCALE,
    },
    selectOrgLabelKey: {
      type: String,
    },
  },
  data() {
    const vm = this;
    const fields = {
      organization_id: {
        value: vm.organizationId,
        name: 'organization_id',
        component: VMultiSelectField,
        values: vm.organizations,
        labelTransKey: 'label.'.concat(vm.selectOrgLabelKey),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
    };

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(SELECT_ROLE_TRANSLATIONS);
  },
  beforeMount() {
    this.initLocale();
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      // vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          // vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          // vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            // show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
