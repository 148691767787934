<template>
  <q-form @submit="request">
    <component
      :is="field.component"
      v-for="field in form.fields"
      :key="field.name"
      v-model="field.value"
      :field="field"
      :field-name="field.labelTransKey?trans(field.labelTransKey):field.label"
      :errors="getErrors(form,field.name)"
      v-on="field.events?field.events:{}"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!validate"
        type="submit"
        :label="trans('button.approve')"
      />
    </div>
  </q-form>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  name: 'VRefuseStatementModal',
  props: {
    action: {
      type: String,
      required: true,
    },
    reasonsUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      form: {
        fields: {
          reason_id: {
            value: null,
            name: 'reason_id',
            component: 'q-select-field',
            labelTransKey: 'label.enrollment_request_refuse_reason_id',
            values: [],
            loadValues: {
              url: vm.reasonsUrl,
            },
            required: true,
            v_show: true,
          },
          message: {
            value: '',
            name: 'message',
            labelTransKey: 'label.additional_message',
            component: 'q-text-field',
            extraAttributes: {
              type: 'textarea',
              maxlength: 250,
              counter: true,
            },
            required: true,
          },
        },
        errors: [],
        afterCallback(vm, response) {
          if (response.url_redirect) {
            window.location = response.url_redirect;
          }
          if (response.message){
            show_notice(response.message, 'success');
          }
        },
      }
    };
  },
  computed: {
    validate() {
      const {
        reason_id, message,
      } = this.form.fields;
      const nullVals = ['', null, undefined];
      return !nullVals.includes(reason_id.value)
        && !nullVals.includes(message.value);
    },
  },
  beforeCreate() {
    this.$trans.add(['menu']);
  },
  methods: {
    async request() {
      const vm = this;
      vm.$globalLoading.show();
      vm.form.errors = [];

      const formData = {};
      _.forEach(vm.form.fields, (el, key) => {
        _.set(formData, key, (typeof el.value !== "undefined") ? el.value : el);
      });

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: formData,
      });


      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
        if (vm.form.afterCallback !== undefined) {
          vm.form.afterCallback(vm, res);
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
        this.form.errors = res.data.errors;
      }
      this.$globalLoading.hide();
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
</script>
