<template>
  <q-form @submit="request">
    <v-wrapper-q-select
      v-model="form.attendances"
      multiple
      :options="attendances"
      :use-input="false"
      :extra-binds="{
        required: true,
        label: trans('voucher.revision.attendances'),
      }"
    />
    <v-wrapper-q-select
      v-model="form.reason"
      :options="reasons"
      option-label="label"
      option-value="value"
      :extra-binds="{
        required: true,
        label: trans('voucher.revision.reason'),
      }"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        type="submit"
      />
    </div>
  </q-form>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    attendances: {
      type: Array,
      required: true,
    },
    selectedReasonId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        attendances: [],
        reason: this.selectedReasonId,
      },
    };
  },
  methods: {
    async request() {
      this.$globalLoading.show();
      const data = _.cloneDeep(this.form);
      data.child_id = this.attendance.child_id;
      data.attendances = data.attendances.map(itm => itm.id);

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
