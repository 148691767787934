<template>
  <div>
    <div class="row justify-content-center">
      <div class="col col-sm sn--text-center mt-5 mb-5">
        <q-form
          class="d-flex justify-content-center flex-column s-w-100"
          @submit="handleSubmit"
        >
          <div
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-8 align-self-center">
              <v-wrapper-q-select
                v-model="form.firstSchool"
                :options="refs.optionsOrganization"
                :label="trans('label.choose_organization')"
                option-value="value"
                option-label="label"
                required
                old-search
                @input="selectSchool(1)"
              />
            </div>
            <div
              v-if="form.firstSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(1)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 1"
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-8 align-self-center">
              <v-wrapper-q-select
                v-model="form.secondSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="selectSchool(2)"
              />
            </div>
            <div
              v-if="form.secondSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(2)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 2"
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-8 align-self-center">
              <v-wrapper-q-select
                v-if="!form.isSpecialStatementType"
                v-model="form.thirdSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="selectSchool(3)"
              />
            </div>
            <div
              v-if="form.thirdSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(3)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 3"
            class="row"
          >
            <div class="col-md-8 align-self-center">
              <v-wrapper-q-select
                v-if="!form.isSpecialStatementType"
                v-model="form.fourthSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="selectSchool(4)"
              />
            </div>
            <div
              v-if="form.fourthSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(4)"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col align-self-center"
            >
              <q-btn
                width="20"
                type="submit"
                :label="trans('button.save')"
                color="green"
              />
            </div>
          </div>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ClosePopup } from '@quasar/directives';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VEditStatementSchoolsVoucherModal',
  directives: { ClosePopup },
  props: {
    statementId: {
      type: Number,
      required: true,
    },
    getDataUrl: {
      type: String,
      required: true,
    },
    submitUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstSchool: null,
        secondSchool: null,
        thirdSchool: null,
        fourthSchool: null,
      },
      refs: {
        optionsOrganization: [],
      },
      newCreatedSchools: {},
      schoolMax: 4,
    };
  },
  async mounted() {
    await this.$trans.add([
      'button',
      'label',
      'statement',
    ]);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.$globalLoading.show();

      const data = {
        firstSchool: this.form.firstSchool,
        secondSchool: this.form.secondSchool,
        thirdSchool: this.form.thirdSchool,
        fourthSchool: this.form.fourthSchool,
        newCreatedSchools: JSON.stringify(this.newCreatedSchools),
      };

      _.forEach(this.lgotTypeDocuments, (file, name) => {
        data[name] = file;
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.submitUrl,
        formData: true,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    async selectSchool(number) {
      this.$globalLoading.show();
      const schoolIds = {
        1: 'firstSchool',
        2: 'secondSchool',
        3: 'thirdSchool',
        4: 'fourthSchool',
      };
      const scId = schoolIds[number] || null;
      this.handleSelectSchools(scId);
      this.$globalLoading.hide();
    },
    async clearSchool(n) {
      // eslint-disable-next-line default-case
      const schoolIds = {
        1: 'firstSchool',
        2: 'secondSchool',
        3: 'thirdSchool',
        4: 'fourthSchool',
      };
      const scId = schoolIds[n] || null;
      this.newCreatedSchools[scId] = null;
      this.form[scId] = null;
    },
    async getData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.getDataUrl,
      });
      if (!res.error) {
        this.refs.optionsOrganization = res.data.schools;
        this.form.firstSchool = res.data.firstSchool?.value;
        this.form.secondSchool = res.data.secondSchool?.value;
        this.form.thirdSchool = res.data.thirdSchool?.value;
        this.form.fourthSchool = res.data.fourthSchool?.value;
        this.schoolMax = res.data.schoolMax ?? 4;

        this.newCreatedSchools = _.cloneDeep(this.form);
      }
      this.$globalLoading.hide();
    },
    handleSelectSchools(schoolIndx) {
      this.newCreatedSchools[schoolIndx] = this.form[schoolIndx];
    },
  },
};
</script>

<style lang="scss" scoped>
.sn-form {
  overflow: hidden;
}

.lines-b {
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
</style>
