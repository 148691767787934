<template>
  <div>
    <div
      v-if="signData"
      class="ml-20"
      style="padding: 1.5rem; max-width: 60rem; border: #EDEFF7 1px solid; border-radius: 5px"
    >
      <img
        v-if="signData.signQR"
        :src="signData.signQR"
        alt=""
      >
      <div class="mt-10 mb-10">
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.status') }}:
          </div>
          <div>
            {{ signData.status }}
          </div>
        </div>
        <div
          v-if="signData.signerFullname"
          class="d-flex mb-5"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.signerFullname') }}:
          </div>
          <div>
            {{ signData.signerFullname }}
          </div>
        </div>
        <div
          v-if="signData.signerIin"
          class="d-flex mb-5"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.signerIin') }}:
          </div>
          <div>
            {{ signData.signerIin }}
          </div>
        </div>
        <div
          v-if="signData.signDate"
          class="d-flex mb-5"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.signDate') }}:
          </div>
          <div>
            {{ signData.signDate }}
          </div>
        </div>
        <div
          v-if="signData.refusalDates"
          class="d-flex mb-5"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.refusalDates') }}:
          </div>
          <div>
            {{ signData.refusalDates }}
          </div>
        </div>
        <div
          v-if="signData.reason"
          class="d-flex mb-5"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.reason') }}:
          </div>
          <div>
            {{ signData.reason }}
          </div>
        </div>
        <div
          v-if="signData.comment"
          class="d-flex"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.comment') }}:
          </div>
          <div>
            {{ signData.comment }}
          </div>
        </div>
        <div
          v-if="signData.files"
          class="d-flex"
        >
          <div class="s-medium mr-5">
            {{ trans('voucher.timesheet.files') }}:
          </div>
          <div v-html="signData.files" />
        </div>
      </div>
    </div>
    <div
      v-else-if="!signData && isParent"
      class="s-group justify-content-end s-group--wider"
      style="padding-right: 2rem; padding-left: 2rem;"
    >
      <q-btn
        v-if="canSign"
        color="green"
        :label="trans('voucher.timesheet_sign')"
        no-caps
        label-wider
        width="20"
        @click="handleSignBtn"
      />
      <q-btn
        v-if="canDecline"
        color="red"
        :label="trans('voucher.timesheet_decline')"
        no-caps
        label-wider
        width="20"
        @click="handleDeclineBtn"
      />
    </div>
    <div
      v-if="btnConfig?.length"
      class="s-group justify-content-end s-group--wider"
      style="padding-right: 2rem; padding-left: 2rem;"
    >
      <q-btn
        v-for="(btn, key) in btnConfig"
        :key="key"
        :color="btn.color"
        :label="btn.label"
        no-caps
        label-wider
        width="20"
        @click="handleBtn(btn)"
      />
      <q-btn
        v-if="canSign"
        color="green"
        :label="trans('voucher.timesheet_sign')"
        no-caps
        label-wider
        width="20"
        @click="handleSignBtn"
      />
      <q-btn
        v-if="canDecline"
        color="red"
        :label="trans('voucher.timesheet_decline')"
        no-caps
        label-wider
        width="20"
        @click="handleDeclineBtn"
      />
    </div>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import { ATTENDANCE_TIME_SHEET_TRANSLATIONS } from '@vjs/balabaqsha/consts';
// noinspection NpmUsedModulesInstalled
import { Helper } from '@common/src/helpers';

export default {
  name: 'VTimeSheetVoucherInfo',
  components: {
  },
  props: {
    signData: {
      type: Object,
      required: false,
    },
    btnConfig: {
      type: Array,
      required: false,
    },
    attendance: {
      type: Object,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    isParent: Boolean,
    canSign: Boolean,
    canDecline: Boolean,
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  methods: {
    handleOnReload() {
      this.$emit('reload');
    },
    async handleBtn(btn) {
      const { attendance, reasons, handleSignBtn } = this;
      if (btn.type === 'button') {
        if (btn.modal === 's-modal' && btn['s-modal-config']) {
          const config = btn['s-modal-config'];
          const binds = config?.binds ?? {};
          binds.reasons = reasons;
          binds.attendance = attendance;
          const events = {};
          events.onApprove = this.handleOnReload;
          this.$sModal.open(
            btn['s-modal-config'].id ?? btn['s-modal-config'].component,
            { ...btn['s-modal-config'], binds, events },
          );
        }
      }
      if (btn.type === 'sign') {
        await handleSignBtn();
      }
    },
    handleSignBtn() {
      this.$globalLoading.show();
      const { apiConfig } = this;
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: apiConfig.signVoucherAttendance.apiUrl,
            data: {
              signXml: this.signXml,
            },
            method: 'post',
          });
          if (!res.error) {
            this.handleOnReload();
            this.$globalLoading.hide();
          } else {
            Helper.handlerResponseErrorNew(this, res);
            this.$globalLoading.hide();
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
  },
};
</script>
