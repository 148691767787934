import { Helper } from '@common/src/helpers';

export default {
  /** QUESTIONNAIRE */
  GET_GROUP_QUESTIONS: async ({ state }) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.questionApiConfig.questionsGet.apiUrl,
    });
    if (!res.error) {
      state.questions = res.questions;
    }
    vm.$globalLoading.hide();
  },
  CREATE_QUESTIONNAIRE_QUESTION: async ({ state }, params = {}) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const url = state.questionId
      ? state.makeQuestionUrl(state.questionApiConfig.questionUpdate.apiUrl, state.questionId)
      : state.questionApiConfig.questionAdd.apiUrl;
    const res = await requestWrapper.call(vm, {
      url,
      data: { ...params },
      method: state.method,
    });
    if (!res.error) {
      eventBus.$emit('update-questions');
      if (state.questionId) {
        state.questionId = null;
      } else {
        eventBus.$emit('close-question');
      }
    } else {
      Helper.handlerResponseErrorNew(vm, res);
    }
    vm.$globalLoading.hide();
  },
  DELETE_QUESTIONNAIRE_QUESTION: async ({ state }, payload) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const binds = {
      type: 'request',
      method: 'DELETE',
      action: state.makeQuestionUrl(state.questionApiConfig.questionDelete.apiUrl, payload),
    };
    const config = {
      id: 'v-model-confirm',
      component: 'v-model-confirm',
      title: vm.trans('notice.are_you_sure'),
      binds,
    };
    const events = {
      onApprove: async () => {
        state.questionId = null;
        eventBus.$emit('update-questions');
      },
    };
    vm.$sModal.open('v-model-confirm', { ...config, binds, events });
    vm.$globalLoading.hide();
  },
  HIDE_QUESTIONNAIRE_QUESTION: async ({ state }, payload) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeQuestionUrl(state.questionApiConfig.questionHide.apiUrl, payload),
    });
    if (!res.error) {
      eventBus.$emit('update-questions');
    }
    vm.$globalLoading.hide();
  },
  CHANGE_QUESTION_OPTION: async ({ state }, payload) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeQuestionUrl(state.questionApiConfig.questionChangeOption.apiUrl, payload),
    });
    if (!res.error) {
      eventBus.$emit('update-questions');
    }
    vm.$globalLoading.hide();
  },
  HIDE_QUESTIONNAIRE_QUESTIONS: async ({ state }, params) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.questionApiConfig.questionsHide.apiUrl,
      data: { ...params },
      method: 'POST',
    });
    if (!res.error) {
      eventBus.$emit('update-questions');
    }
    vm.$globalLoading.hide();
  },
  /** QUESTIONNAIRE PROCESS */
  GET_PROCESS_DATA: async ({ commit, state }) => {
    const { vm } = state;
    const res = await requestWrapper.call(vm, {
      url: state.processApiConfig.loadData.apiUrl,
    });
    if (!res.error) {
      vm.$store.commit('SET_QUESTION_GROUP', res.questionGroup);
    }
    commit('SET_LOADING', { name: 'common', status: false });
  },
  GET_QUESTIONNAIRE_DETAIL: async ({ state }) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.processApiConfig.getDetails.apiUrl,
    });
    if (!res.error) {
      state.questionnaireDetails = res;
    }
    vm.$globalLoading.hide();
  },
  SEND_PROCESS_ANSWER: async ({ commit, state }, payload) => {
    const { vm } = state;
    commit('SET_LOADING', { name: 'answer', status: true });
    const res = await requestWrapper.call(vm, {
      url: state.processApiConfig.storeAnswer.apiUrl,
      data: payload,
      method: 'POST',
    });
    if (res.error) {
      Helper.handlerResponseErrorNew(vm, res);
    } else {
      await vm.$store.dispatch('GET_PROCESS_DATA');
      eventBus.$emit('next-question');
    }
    commit('SET_LOADING', { name: 'answer', status: false });
  },
  SEND_PROCESS_RESULT: async ({ state }, payload) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.processApiConfig.storeResult.apiUrl,
      data: payload,
      method: 'POST',
    });
    if (res.error) {
      Helper.handlerResponseErrorNew(vm, res);
    }
    vm.$globalLoading.hide();
  },
};
