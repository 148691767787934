import {
  QDialog,
  QBtn,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';

export default {
  name: 'WarningModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  props: {
    type: String,
    linkInterest: String,
    promoText: String,
  },
  created() {
    this.$trans.add(['label', 'warning_text', 'main']);
  },
  data() {
    return {
      modalWarning: false,
    };
  },
  mounted() {
    //  TODO: через время удалить
    if (localStorage.getItem('modalWarningGuest')) {
      // this.modalWarning = true;
      // localStorage.setItem('modalWarningGuest', true);
      localStorage.removeItem('modalWarningGuest');
    }

    if (this.type === 'user') {
      this.modalWarning = true;
    }
  },
  methods: {
    handleClick() {
      localStorage.setItem('modalWarningUser', true);
      document.location.href = this.linkInterest;
    },
  },
};
