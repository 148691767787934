import _ from 'lodash';
import {
  QPagination,
} from '@quasar/components';
import {
  requestWrapper,
  genArray,
} from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import VNoResults from '@vjs/components/VNoResults';
import VApplicationItem from '../../components/VApplicationItem';
import { scrollTop } from '../../helpers';
import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VApplicationListView',
  components: {
    QPagination,
    VApplicationItem,
    VNoResults,
  },
  mixins: [Localization],
  data() {
    return {
      items: [],
      itemsPaginate: [],
      loading: true,
      currentPage: 1,
      countByPage: 5,
      correction: false,
      sanatorium: false,
    };
  },
  props: {
    uriList: {
      type: String,
      required: true,
    },
    uriRemove: {
      type: String,
      default: '',
    },
    uriInterest: {
      type: String,
      required: true,
    },
    uriStatementContinue: {
      type: String,
      required: true,
    },
    uriUpdateLgots: {
      type: String,
      default: '',
    },
    uriGetLgotTypes: {
      type: String,
    },
    uriGetLgotTypeDocuments: {
      type: String,
    },
    uriCheckSpecialType: {
      type: String,
    },
  },
  beforeMount() {
    this.initLocale();
  },
  beforeCreate() {
    this.$trans.add(['statement']);
  },
  async mounted() {
    await this.requestGetApplications();

    eventHub.$on('updatedLgot', async () => {
      await this.requestGetApplications();
    });
    eventHub.$on('updatedLang', async () => {
      await this.requestGetApplications();
    });
  },

  computed: {
    countOfPages() {
      const count = Math.ceil(this.items.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsPaginated() {
      if (this.loading) {
        return genArray({
          id: 0,
          fullname: '',
          statementsCount: '',
          flag: '',
          locked: false,
          isNotInterested: false,
          remove: false,
          desc: [
            {
              label: '',
              value: '',
            },
            {
              label: '',
              value: '',
            },
            {
              label: '',
              value: '',
            },
            {
              label: '',
              value: '',
            },
          ],
          steps: [
            {
              title: '',
              status: '',
              statusMessage: '',
              text: '',
            },
            {
              title: '',
              status: '',
              statusMessage: '',
              text: '',
            },
            {
              title: '',
              status: '',
              statusMessage: '',
              text: '',
            },
            {
              title: '',
              status: '',
              statusMessage: '',
              text: '',
            },
          ],
        }, 4);
      }
      return this.calcPagination(this.currentPage);
    },
  },

  methods: {
    async requestGetApplications() {
      const applicationsRes = await requestWrapper.call(this, {
        url: this.uriList,
      });

      if (!applicationsRes.error) {
        this.items = applicationsRes.data;
        this.correction = (applicationsRes.correction) ?? false;
        this.sanatorium = (applicationsRes.sanatorium) ?? false;
        this.loading = false;
      }
    },
    calcPagination(val) {
      const cur = (val - 1) * this.countByPage;
      return this.items.slice(cur, cur + this.countByPage);
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
    removeApplication(id) {
      this.items = this.items.filter(itm => itm.id !== id);
    },
    unlockApplication(id, newSteps) {
      const application = _.find(this.items, itm => itm.id === id);
      application.locked = false;
      application.steps = newSteps;
    },
    setInterest(id) {
      const application = _.find(this.items, itm => itm.id === id);
      application.isNotInterested = false;
    },
  },
};
