// noinspection NpmUsedModulesInstalled
import {
  QToggle,
  QTable,
  QTr,
  QTd,
  QBtn,
  QInput,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
// noinspection NpmUsedModulesInstalled
import {
  CONTINGENT_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';
import _ from 'lodash';
import VFormInform from '@vjs/components/VFormInform';
import { Helper } from '@common/src/helpers';
import Localization from '../Mixins/QuasarLocale';
import ProvidersMixin from './Mixins/Providers';
import { requestWrapper } from '../../../helpers';

export default {
  name: 'VOrganizationChildTable',
  components: {
    QToggle,
    Confirm,
    QTable,
    QTd,
    QTr,
    QBtn,
    QInput,
    QSelect,
    QSlideTransition,
    VFormInform,
  },
  mixins: [Localization, ProvidersMixin],
  props: {
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    groupId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    correctionType: {
      type: Number,
      default: 0,
    },
    canMoveSwap: false,
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';

    return {
      locale,
      table: this.data,
      deactivatePopup: false,
      deactivateAllWarningPopup: false,
      movePopup: false,
      selectedContingentId: null,
      selectedGroups: [],
      deactivateContractNumber: null,
      deactivateReason: null,
      deactivateOtherReason: null,
      allowedMoveGroups: [],
      pagination: {
        rowsPerPage: 20,
      },
      moving: false,
      deactivating: false,
      deactivatingByGroup: false,
      showOtherReason: false,
      documentDisease: null,
      replacedContingent: null,
    };
  },
  computed: {
    contingentRefs() {
      if (this.selectedGroup) {
        return this.selectedGroup.contingentRef;
      }
      return [];
    },
    deactivateReasonList() {
      // noinspection JSUnresolvedVariable
      return this.dataConfig.deactivateReasons;
    },
    groupColumns() {
      return [
        {
          align: 'center',
          name: 'group_name',
          field: 'group_name',
          label: this.trans('fields.group_name'),
        },
        {
          align: 'center',
          name: 'start_year',
          field: 'start_year',
          label: this.trans('fields.start_year'),
        },
        {
          align: 'center',
          name: 'group_lang',
          field: 'group_lang',
          label: this.trans('label.freeplace_lang'),
        },
        {
          align: 'center',
          name: 'limit',
          field: 'limit',
          label: this.trans('table.throughput'),
        },
        {
          align: 'center',
          name: 'statement_count',
          field: 'statement_count',
          label: this.trans('label.occupied_places_groups'),
        },
        {
          align: 'center',
          name: 'free_place_count',
          field: 'free_place_count',
          label: this.trans('label.free_places_groups'),
        },
        {
          align: 'center',
          name: 'group_available_free_place',
          field: 'group_available_free_place',
          label: this.trans('label.group_available_free_places'),
        },
      ];
    },
    deactivateFilled() {
      const { deactivateContractNumber, deactivateReason, deactivateOtherReason } = this;

      const contractNumberFilled = (
        deactivateContractNumber !== null
        && deactivateContractNumber.length > 0
      );

      const otherReasonFilled = (
        deactivateOtherReason !== null
        && deactivateOtherReason.length > 0
      );

      if (deactivateReason !== null && deactivateReason.value === 11) {
        return otherReasonFilled && contractNumberFilled;
      }
      return contractNumberFilled && deactivateReason !== null;
    },
    selectedContingent() {
      const { data, selectedContingentId } = this;

      return data.reduce((acc, item) => {
        if (acc) {
          return acc;
        }
        // noinspection JSUnresolvedVariable
        if (item.contingent_id === selectedContingentId) {
          return item;
        }
        return null;
      }, null);
    },
    selectedGroup() {
      const { selectedGroups } = this;
      if (selectedGroups.length === 0) {
        return null;
      }
      return selectedGroups.slice(0, 1)
        .shift();
    },
    documentDiseaseRequired() {
      return this.selectedGroup?.document_disease_required;
    },
    moveFilled() {
      if (this.canMoveSwap) {
        return true;
      }

      const { selectedContingentId } = this;
      const group = this.selectedGroup;

      if (!selectedContingentId || !group) {
        return false;
      }

      // noinspection JSUnresolvedVariable
      return parseInt(group.free_place_count, 10) >= 1;
    },
    buttonApproveDisable() {
      return !this.moveFilled || this.moving
        || (!this.documentDisease && this.documentDiseaseRequired)
        || (!this.replacedContingent && this.canMoveSwap);
    },
    documentUploadLabel() {
      return this.selectedGroup?.document_upload_label ?? this.trans('label.confirmation_document');
    },
  },
  beforeCreate() {
    this.$trans.add(CONTINGENT_TRANSLATIONS);
  },
  beforeMount() {
    const {
      apiConfig: { contingentApi },
    } = this;
    this.initLocale();
    this.initProvider(contingentApi);
  },
  methods: {
    getBudgetBtnColor(isBudget) {
      switch (isBudget) {
        case 1: return 'green';
        case 0: return 'red';
        default: return 'bb-yellow';
      }
    },
    getBudgetBtnLabel(isBudget) {
      switch (isBudget) {
        case 1: return this.trans('button.yes');
        case 0: return this.trans('button.no');
        default: return '';
      }
    },
    // eslint-disable-next-line camelcase
    async handleContingentBudget(contingent_id) {
      this.$globalLoading.show();
      const rowIndex = _.findIndex(this.table, { contingent_id });
      let is_budget = null;
      if ([1, null].includes(this.table[rowIndex].contingent_budget)) {
        is_budget = 0;
      } else if (this.table[rowIndex].contingent_budget === 0) {
        is_budget = 1;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: `${this.apiConfig.contingentApi.changeBudgetUrl}/${contingent_id}`,
        data: { is_budget },
      });

      if (!res.error) {
        this.table[rowIndex].contingent_budget = is_budget;
      }
      this.$globalLoading.hide();
    },
    changeReason(reason) {
      this.showOtherReason = reason.value === 11;
    },
    confirmDeactivate(id) {
      this.selectedContingentId = id;
      this.deactivatingByGroup = false;
      this.deactivatePopup = true;
    },
    deactivateAllWarning() {
      this.deactivateAllWarningPopup = true;
    },
    confirmDeactivateAll() {
      this.selectedContingentId = null;
      this.deactivatingByGroup = true;
      this.deactivatePopup = true;
    },
    cancelDeactivate() {
      this.deactivateReason = null;
      this.deactivateOtherReason = null;
      this.selectedContingentId = null;
      this.deactivatingByGroup = false;
      this.deactivatePopup = false;
      this.deactivateAllWarningPopup = false;
      this.deactivateContractNumber = null;
    },
    async deactivate() {
      const {
        apiProvider,
        selectedContingentId,
        groupId,
        deactivateContractNumber,
        deactivateReason,
        deactivateOtherReason,
        deactivatingByGroup,
      } = this;

      this.deactivating = true;
      this.$globalLoading.show();
      const deactivated = await apiProvider
        .provideDeactivateContingent()
        .apply(this, [
          deactivatingByGroup ? groupId : selectedContingentId,
          deactivateContractNumber,
          deactivateReason.value,
          deactivateOtherReason,
          deactivatingByGroup,
        ]);
      this.deactivating = false;
      this.deactivatePopup = false;
      if (deactivated) {
        this.cancelDeactivate();
        window.location.reload();
      }
      this.$globalLoading.hide();
    },
    async prepareMove(id) {
      const {
        apiProvider,
        $notify,
        $trans,
      } = this;

      this.selectedContingentId = id;
      this.$globalLoading.show();
      const allowedGroupList = await apiProvider
        .provideGetAllowedGroupsByContingentId()
        .apply(this, [id]);
      this.$globalLoading.hide();
      if (allowedGroupList.length) {
        this.allowedMoveGroups = allowedGroupList;
        this.confirmMove();
      } else {
        $notify({
          type: 'warn',
          title: this.trans('notice.no_matching_groups'),
        });
      }
    },
    confirmMove() {
      this.movePopup = true;
    },
    deactivateMove() {
      this.selectedContingentId = null;
      this.replacedContingent = null;
      this.movePopup = false;
      this.allowedMoveGroups = [];
      this.selectedGroups = [];
    },
    async applyMove() {
      const {
        apiProvider,
        selectedContingentId,
        documentDisease,
        replacedContingent,
      } = this;

      const groupId = this.selectedGroup.id;

      this.moving = true;
      this.$globalLoading.show();
      const moved = await apiProvider
        .provideMoveChildToGroup()
        .apply(this, [
          selectedContingentId,
          groupId,
          documentDisease,
          replacedContingent,
        ]);
      this.moving = false;
      if (moved) {
        this.deactivateMove();
        window.location.reload();
      } else {
        this.$globalLoading.hide();
      }
    },
    async switchContingentBudget(contingentId, rowIndex) {
      this.$globalLoading.show();
      const budget = this.table[rowIndex].freeplace_budget;
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.contingentApi.changeBudgetUrl,
        data: {
          contingentId,
          budget,
        },
        method: 'post',
      });
      if (res.error) {
        this.table[rowIndex].freeplace_budget = !budget;
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    clearFile() {
      this.documentDisease = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.documentDisease = file;
      }
      this.$refs.fileInput.value = null;
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
  },
  watch: {
    selectedGroups: {
      deep: true,
      handler() {
        this.replacedContingent = null;
        this.documentDisease = null;
      },
    },
  },
};
