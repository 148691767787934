<template>
  <div class="s-about-page__cards-wrapper">
    <div
      v-for="(card, key) in cards"
      :key="key"
      class="s-about-page__card"
    >
      <div
        v-if="!isAdaptive"
        class="s-about-page__card--title"
      >
        {{ card.title }}
      </div>
      <div class="s-about-page__card--body">
        <div
          class="s-about-page__card--img"
          :style=" {
            'background-image': `url(${cdnStatic}/balabaqsha/images/voucher/${card.img}.svg)`
          }"
        />
        <div>
          <div
            v-if="isAdaptive"
            class="s-about-page__card--title"
          >
            {{ card.title }}
          </div>
          <div
            class="s-about-page__card--text"
            v-html="card.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAdaptive: {
      type: Boolean,
      required: true,
    },
    cdnStatic: {
      type: String,
      required: true,
    },
  },
  computed: {
    cards() {
      const trans = key => this.trans(`statement.voucher.${key}`);
      const result = [];
      const makeCard = (title, img, text) => {
        result.push({ title, img, text });
      };
      makeCard(trans('term'), 'term', trans('term_text'));
      makeCard(trans('for_who'), 'for_who', trans('for_who_text'));
      makeCard(trans('docs'), 'docs', trans('docs_text'));
      return result;
    },
  },
};
</script>
