<template>
  <div>
    <div class="mb-30">
      {{ title }}
    </div>
    <div class="row justify-content-center">
      <div class="col col-sm sn--text-center mt-5 mb-5">
        <v-wrapper-file-field
          v-model="file"
          required
          equal
          :config="{
            max: 1,
          }"
        />
        <q-btn
          width="20"
          :label="trans('button.save')"
          :disable="!file"
          color="green"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn } from '@quasar/components';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VUploadFileModal',
  components: {
    QBtn,
  },
  directives: { ClosePopup },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
    };
  },
  async mounted() {
    await this.$trans.add([
      'notice',
      'button',
      'label',
    ]);
  },
  methods: {
    handleSubmit() {
      if (this.file) {
        this.$emit('modalSubmit', this.file);
        this.$emit('onClose');
      } else {
        this.$notify({
          text: this.trans('notice.file_empty'),
          type: 'error',
        });
      }
    },
  },
};
</script>
