import _ from 'lodash';
import {
  QPagination,
  QBtn,
} from '@quasar/components';
import {
  genArray,
  requestWrapper,
} from '@vjs/helpers';
import { ERR_403 } from '@vjs/helpers/requestWrapper';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import MXListView from '@vjs/mixins/MXListView';
import { scrollTop } from '../../helpers';
import VBulletinFilters from '../../components/VBulletinFilters';
import VFreeplaceItem from '../../components/VFreeplaceItem';
import VNoResults from '../../../components/VNoResults';
export default {
  name: 'VFreeplacesListView',
  mixins: [MXListView],
  components: {
    VBulletinFilters,
    VStatisticPanel,
    VFreeplaceItem,
    QPagination,
    VNoResults,
    QBtn,
  },
  props: {
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriByAll: {
      type: String,
      required: true,
    },
    uriByOrg: {
      type: String,
      required: true,
    },
    defaultFilters: Object,
    disableLoadFromStorage: Boolean,
  },
  data() {
    return {
      currentTab: 'all',
      currentOrgID: null,
      itemsPaginate: [],
      correction: false,
      isSanatorium: false,
      isCorrection: false,
      firstLoad: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['bulletin', 'label', 'directions', 'button', 'warning_text', 'fields']);
  },
  computed: {
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsPaginated() {
      if (this.loading) {
        return genArray({
          activeLabel: ' ',
          ages: ' ',
          agesTitle: ' ',
          availableCount: ' ',
          count: ' ',
          dateEnd: ' ',
          dateStart: ' ',
          groupName: ' ',
          educationYear: ' ',
          id: 0,
          lang: '',
          organization: {
            address: ' ',
            name: ' ',
          },
          params: genArray({ key: ' ', value: ' ' }, 6),
          publishDate: ' ',
          is_correction: ' ',
          is_sanatorium: ' ',
        }, 4);
      }

      return this.itemsPaginate;
    },
  },
  methods: {
    handleChangeParams(key, count) {
      const item = this.itemsPaginate[key];
      item.params[item.params.length - 1].value = count;
    },
    async handleFilteredList(arr) {
      this.itemsFiltered = this.bulletinItems = arr.items || [];
      this.statisticItems = arr.statistics || [];
      this.calcPagination(1);
      this.firstLoad = true;
    },
    calcPagination(val) {
      const current = (val - 1) * this.countByPage;
      this.itemsPaginate = this.itemsFiltered
        .slice(current, current + this.countByPage);
    },
    // Запускается на событие @input в q-pagination
    async changePagination(val) {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
      this.calcPagination(val);
    },
    selectOrganization(orgID) {
      this.currentOrgID = orgID;
    },
  },
};
