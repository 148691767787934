
export default {
  props: {},
  data: () => ({ }),

  async mounted() {},

  methods: {
    handleBackBtn() {
      eventBus.$emit('corruptionInfoOpen', false);
    },
  },
};
