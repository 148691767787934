export default {
  name: 'VFoodMenuCalendarHeader',
  props: {
    tmpDateMonth: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateMonth: this.tmpDateMonth,
      currentWeekDay: _.upperFirst(moment().format('dddd')),
      currentDate: moment().format('DD MMMM YYYY'),
    };
  },
  watch: {
    dateMonth() {
      this.emitInitCalendar();
    },
  },
  methods: {
    emitInitCalendar() {
      const parsedDate = moment(this.dateMonth, 'MM YYYY');
      const data = {
        year: parsedDate.year(),
        month: parsedDate.month(),
        dateMonth: this.dateMonth,
      };
      eventBus.$emit('initCalendar', data);
    },
  },
};
