export const FALLBACK_LOCALE = 'kz';
export const TEMPORARY_FREE_PLACE_TYPE = 3;
export const EARLY_FREE_PLACE_TYPE = 2;
export const COMMON_FREE_PLACE_TYPE = 1;

export const FREE_PLACE_FORM_TRANSLATIONS = [
  'bulletin',
  'label',
  'notice',
  'fields',
  'placeholder',
  'button',
  'warning_text',
];

export const CONTINGENT_TRANSLATIONS = [
  'button',
  'notice',
  'label',
  'fields',
  'table',
  'warning_text',
];

export const FUNDING_KINDERGARTEN_TRANSLATIONS = [
  'button',
  'fields',
  'notice',
  'label',
  'title',
  'table',
  'organization',
  'funding',
  'warning_text',
];

export const FUNDING_KINDERGARTEN_GROUPS_TRANSLATIONS = [
  'button',
  'notice',
  'label',
  'table',
  'menu',
  'fields',
  'funding',
  'season',
];

export const FUNDING_CALCULATOR = [
  'button',
  'notice',
  'label',
  'table',
  'menu',
  'funding',
  'warning_text',
  'header',
];

export const ATTENDANCE_TRANSLATIONS = [
  'button',
  'fields',
  'organization',
  'attendance',
  'label',
];

export const ATTENDANCE_JOURNAL_TRANSLATIONS = [
  'table',
  'notice',
  'directions',
  'attendance',
];

export const ATTENDANCE_TIME_SHEET_TRANSLATIONS = [
  'notice',
  'attendance',
  'timesheet',
];

export const ATTENDANCE_ORGANIZATION_CONTROL = [
  'notice',
  'config',
];

export const STATEMENT_REFUSE_TRANSLATIONS = [
  'statement-refuse',
  'notice',
  'config',
];

export const SELECT_ROLE_TRANSLATIONS = [
  'warning_text',
  'notice',
  'config',
  'placeholder',
  'roles',
  'select',
  'seo-title',
  'label',
];
