import VPieChart from '@vjs/components/VPieChart';
import {
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import VHeadTabsPortalWrapper from '@common/components/VHeadTabsPortalWrapper';
import { scrollTop } from '../../helpers';
import VQueueTable from './VQueueTable';

export default {
  name: 'VPassportPageView',

  components: {
    VPieChart,
    QBtn,
    VPhotoAlbum,
    VHeadTabsPortalWrapper,
    VQueueTable,
  },

  props: {
    passportId: {
      type: Number,
      required: true,
    },
    uriSendStatement: {
      type: String,
      required: false,
    },
    uriSendQueue: {
      type: String,
      required: false,
    },
    uriGetChildren: {
      type: String,
      required: false,
    },
    uriGetGroups: {
      type: String,
      required: false,
    },
    uriGetGroupsFreeplaces: {
      type: String,
      required: false,
    },
    queueTableConfig: {
      type: Object,
      default: () => ({
        uriGetFilters: null,
        uriGetData: null,
      }),
    },
    isVoucherStatement: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAuth: window.core_project.isAuth,
      picItems: [],
      flags: '',
      name: '',
      description: '',
      address: '',
      phone: '',
      email: '',
      groups: [],
      queueStatements: [],
      aboutOrg: [],
      statistics: {
        throughput: '',
        eduLangs: '',
        yearRestriction: '',
        availablePlacesCount: 0,
        queueCount: 0,
        pieChart: [],
      },
      tabs: ['profile', 'queue'],
      activeTab: 'profile',
    };
  },

  async mounted() {
    this.$trans.add(['organization', 'warning_text']);
    const res = await requestWrapper.call(this, {
      url: '/api/v1/balabaqshas/show',
      params: {
        id: this.passportId,
      },
    });

    if (!res.error) {
      this.flags = res.data.marks;
      this.name = res.data.name;
      this.description = res.data.body;
      this.address = res.data.address;
      this.phone = res.data.phone_number;
      this.email = res.data.email;
      this.groups = res.data.groups;
      this.aboutOrg = res.data.informBlock;
      this.statistics.throughput = res.data.pieBlock.throughput;

      if (Array.isArray(res.data.pieBlock.eduLangs)) {
        this.statistics.eduLangs = res.data.pieBlock.eduLangs.reduce((acc, item, idx) => {
          const symbComma = (idx !== res.data.pieBlock.eduLangs.length - 1 && ', ') || '';
          return acc + item + symbComma;
        }, '');
      }

      this.statistics.yearRestriction = res.data.pieBlock.orgYearRestriction;
      this.statistics.availablePlacesCount = res.data.pieBlock.availablePlacesCount;
      this.statistics.queueCount = res.data.pieBlock.queueCount;
      this.statistics.pieChart = res.data.pieBlock.pie;
      this.picItems = res.data.images;
    }
  },

  methods: {
    scrollTo(name = '') {
      if (this.$refs[name]) {
        scrollTop(
          window,
          window.scrollY,
          window.scrollY + Math.abs(this.$refs[name].getBoundingClientRect().top),
          500,
        );
      }
    },
    handleStatement() {
      this.$sModal.open('v-modal-voucher-send-queue-statement', {
        size: 480,
        component: 'v-modal-voucher-send-queue-statement',
        binds: {
          actionWaitingList:  this.uriSendStatement,
          actionSendStatement: this.uriSendQueue,
          action: this.uriSendQueue,
          uriGetChildren: this.uriGetChildren,
          uriGetGroups: this.uriGetGroupsFreeplaces,
          message: this.trans('warning_text.voucher_passport_statement_modal_inform', { ddo: this.name }),
        },
      });
    },
  },
};
