import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VVoucherApplicationInfoForm',
  props: {
    action: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: true,
    },
    uriGetRefs: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentDayHelper,
      form: {
        date_start: null,
        date_end: null,
        locality: null,
        plan_count: '',
        total: '',
        contract_file: [],
      },
      refs: {
        localities: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'voucher']);
    await requestGetDataHelper(this, { url: this.uriGetRefs, variableName: 'refs' });
  },

  computed: {
    dateStartDF() {
      return moment(this.form.date_start, 'DD.MM.YYYY').format('YYYY/MM/DD');
    },
  },

  methods: {
    handleDateStart(val) {
      if (val > this.form.date_end) {
        this.form.date_end = null;
      }
    },
    formatNumberInput(newValue, max, name) {
      let tmpVal = newValue.replace(/\D/g, '');
      if (tmpVal === '') {
        this.form[name] = '';
        return;
      }
      if (tmpVal < 0) {
        tmpVal = 0;
      }
      if (tmpVal > max) {
        tmpVal = max;
      }
      this.$set(this.form, name, this.currencyFormatter(tmpVal));
    },
    currencyFormatter(number) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(formattedNumber);
    },
    formatNumberBack(val) {
      return Number(val.replace(/\s/g, ''));
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data: {
          ...this.form,
          total: this.formatNumberBack(this.form.total),
          plan_count: this.formatNumberBack(this.form.plan_count),
        },
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
