import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import axios from 'axios';


export default {
  name: 'VQueueMonitoringByYearView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrlAll: {
      type: String,
    },

  },
  data: () => ({
    form: {
      page: 1,
    },
    exportLoading: false,
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
    },
    rows: [],
    rowNames: [],
    totalNames: [],
    filterString: '',
    selectedDepartment: null,
    table_name: null,
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'label', 'main']);
    await this.requestGetDataAll();
  },
  beforeCreate() {
  },
  methods: {
    getCellStyle(cellName) {
      switch (cellName) {
        case 'count_of_childs_enrolled_in_state':
          return {
            whiteSpace: 'unset',
            minWidth: '25rem',
          };
        case 'count_of_childs_enrolled_in_private':
          return {
            whiteSpace: 'unset',
            minWidth: '25rem',
          };
        default:
          return {};
      }
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col] === 'number',
      );
      return _.sumBy(rows, col);
    },
    async requestGetDataAll() {
      this.$globalLoading.show();
      const dataRes = await requestWrapper({
        url: this.apiUrlAll,
      });

      if (!dataRes.error) {
        this.table_name = dataRes.data.table_name;
        this.rows = dataRes.data.rows;
        this.rowNames = dataRes.data.rowNames;
        this.table.columns = dataRes.data.cols;
        this.totalNames = dataRes.data.totalNames;
      }
      this.$globalLoading.hide();
    },
    async exportu(e) {
      this.$globalLoading.show();
      this.loading = true;
      axios({
        url: `${this.apiUrlAll}?export=1`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.enrolled_childrens_by_age')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.$globalLoading.hide();
        this.loading = false;
      });
    },
  },
  watch: {
  },
};
