export default {
  name: 'VFreeplaceItem',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    organization: {
      type: Object,
      default: () => ({
        address: '',
        name: '',
        type: '',
      }),
    },
    params: {
      type: Array,
      default: () => ([
        {
          key: '',
          value: '',
        },
      ]),
    },
    groupName: String,
    educationYear: String,
    orgType: String,
    ages: String,
    diseases: {
      type: String,
      default: '',
    },
    sanatorium: {
      type: String,
      default: '',
    },
    typeOfTab: {
      type: String,
      default: 'all',
    },
    correction: {
      type: Boolean,
      default: () => false,
    },
    sanatoria: {
      type: Boolean,
      default: () => false,
    },
    agesList: String,
    loading: Boolean,
  },
};
