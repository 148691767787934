<template>
  <div class="s-group s-group--wider justify-content-start">
    <img
      :src="signData.signQR"
      alt=""
    >
    <div class="mt-10 mb-10">
      <div class="d-flex mb-5">
        <div class="s-medium mr-5">
          {{ labels.fioLabel ?? trans('parent_payment.signer_fio') }}:
        </div>
        <div>
          {{ signData.signerFullname }}
        </div>
      </div>
      <div class="d-flex mb-5">
        <div class="s-medium mr-5">
          {{ labels.iinLabel ?? trans('parent_payment.signer_iin') }}:
        </div>
        <div>
          {{ signData.signerIin }}
        </div>
      </div>
      <div class="d-flex mb-5">
        <div class="s-medium mr-5">
          {{ labels.signLabel ?? trans('parent_payment.sign_date') }}:
        </div>
        <div>
          {{ signData.signDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSignatureDataViewer',
  props: {
    signData: {
      type: Object,
      default: () => ({
        signQR: null,
        signDate: null,
        signerFullname: '',
        signerIin: '',
      }),
    },
    labels: {
      type: Object,
      default: () => ({
        fioLabel: null,
        iinLabel: null,
        signLabel: null,
      }),
    },
  },
  beforeCreate() {
    this.$trans.add(['parent_payment', 'label']);
  },
};
</script>
