import SendQueueStatement from './SendQueueStatement';
import AccountSign from './AccountSign';
import VRefuseStatementModal from './VRefuseStatementModal';
import TimesheetRevise from './TimesheetRevise';
import TimesheetDecline from './TimesheetDecline';
import VEditStatementSchoolsModal from './VEditStatementSchoolsModal';

export default function (Vue) {
  Vue.component('v-edit-statement-schools-voucher-modal', VEditStatementSchoolsModal);
  Vue.component('v-modal-timesheet-voucher-decline', TimesheetDecline);
  Vue.component('v-modal-timesheet-voucher-revise', TimesheetRevise);
  Vue.component('v-modal-voucher-account-sign', AccountSign);
  Vue.component('v-modal-voucher-send-queue-statement', SendQueueStatement);
  Vue.component('v-refuse-statement-voucher-modal', VRefuseStatementModal);
}
