import Cards from './components/Cards';
import Process from './components/Process';
import Faq from './components/Faq';
import AdaptiveInform from './components/AdaptiveInform';
import Inform from './components/Inform';
import Title from './components/Title';

export default {
  components: {
    Cards,
    Process,
    Faq,
    AdaptiveInform,
    Inform,
    Title,
  },
  props: {
    faqItems: {
      type: Array,
      default: () => ([]),
    },
    backLink: {
      type: String,
      required: true,
    },
    mobileFaqLink: {
      type: String,
      required: true,
    },
    statementLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cdnStatic: window.core_project.cdnStatic,
    };
  },
  async beforeMount() {
    await this.$trans.add(['statement', 'title']);
  },
  computed: {
    isAdaptive() {
      return this.$q.screen.width < 480;
    },
  },
};
