import { Helper } from '@common/src/helpers';
import QOptionGroup from '@quasar/components/option-group/QOptionGroup';

export default {
  components: { QOptionGroup },
  props: {
    uriAccount: {
      type: String,
      required: false,
    },
    defaultType: {
      type: String,
      default: null,
    },
    uriDecline: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    signConfig: {
      type: Object,
      default: () => ({
        ncaLayerAction: null,
        getData: null,
        updateQr: null,
        getBody: null,
      }),
    },
  },
  data() {
    return {
      cdnStatic: window.core_project.cdnStatic,
      type: !this.defaultType ? 'voucher' : this.defaultType,
    };
  },
  async mounted() {
    await this.$trans.add(['voucher', 'label']);
  },
  computed: {
    types() {
      const trans = key => this.trans(`voucher.faq-alt.type_${key}`);
      return [
        { value: 'voucher', label: trans('finance') },
        { value: 'voucher_virtual', label: trans('virtual') },
      ];
    },
    submitBtn() {
      const {
        uriDecline, type, trans, uriAccount, signConfig, defaultType,
      } = this;

      if (uriDecline && type === 'voucher_virtual') {
        return {
          type: 'a',
          href: uriDecline,
          label: !defaultType ? trans('voucher.continue') : trans('voucher.change_to_virtual_btn'),
        };
      }
      if (type === 'voucher') {
        const label = !defaultType ? trans('voucher.continue') : trans('voucher.change_to_finance_btn');
        if (uriAccount) {
          return {
            type: 'create',
            label,
          };
        }
        if (signConfig.ncaLayerAction) {
          return {
            type: 'sign',
            label,
          };
        }
      }
    },
  },
  methods: {
    async handleSubmitBtn() {
      const { type } = this.submitBtn;
      if (type === 'sign') {
        await this.handleSignModal();
      }
      if (type === 'create') {
        await this.handleCreateRequest();
      }
    },
    async handleCreateRequest() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAccount,
      });

      if (res.error) {
        this.$globalLoading.hide();
        Helper.handlerResponseErrorNew(this, res);
      }
    },
    async handleSignModal() {
      const res = await requestGetDataHelper(this, {
        url: this.signConfig.getBody,
        returnData: true,
        fullRes: true,
      });
      this.$sModal.open('v-modal-voucher-account-sign', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-voucher-account-sign',
        size: '800',
        binds: { signConfig: this.signConfig, ...res },
      });
    },
  },
};
