import {
  QInput,
  QBtn,
  QForm,
  QIcon,
  QField,
  QOptionGroup, QPopupProxy, QDate,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';
import SelectUserFile from '@vjs/balabaqsha/components/SelectUserFile';

export default {
  name: 'VEnrollmentRequestSendForm',
  mixins: [Validation],
  components: {
    QDate,
    QPopupProxy,
    QInput,
    QBtn,
    QForm,
    QIcon,
    QField,
    QOptionGroup,
    VFormInform,
    SelectUserFile,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        submitUrl: '',
        getFiles: '',
        getData: '',
        staticUrl: '',
      }),
    },
    additionalConfig: {
      type: Object,
      default: () => ({
        statementExpiredDate: null,
      }),
    },
    contractName: String,
    editFiles: Boolean,
    forCorrection: Boolean,
    forSanatorium: Boolean,
    forMilitary: Boolean,
    forCourier: Boolean,
    forLaw: Boolean,
    comment: String,
  },
  data() {
    const files = {
      child_medical_card: [],
      vaccination_card: [],
    };
    if (this.forSanatorium) {
      files.phthisiatrician_cert = [];
    }
    if (this.forCorrection) {
      files.pmpk_document = [];
    }
    if (this.forMilitary) {
      files.military_workplace_cert = [];
    }
    if (this.forCourier) {
      files.courier_service_workplace_cert = [];
    }
    if (this.forLaw) {
      files.law_officer_workplace_cert = [];
    }
    return {
      files,

      form: {
        issuer_workplace: null,
        issuer_position: null,
        issuer_birth_cert_number: null,
        issuer_birth_cert_date: null,
        child_birth_cert_number: null,
        child_birth_cert_date: null,
      },

      inputList: [
        'issuer_workplace',
        'issuer_position',
        'issuer_birth_cert_number',
        'issuer_birth_cert_date',
        'child_birth_cert_number',
        'child_birth_cert_date',
      ],

      disable: {
        issuer_workplace: false,
        issuer_position: false,
        issuer_birth_cert_number: false,
        issuer_birth_cert_date: false,
        child_birth_cert_number: false,
        child_birth_cert_date: false,
      },
      disableFiles: {
        child_medical_card: this.editFiles,
        pmpk_document: this.editFiles,
        phthisiatrician_cert: this.editFiles,
        vaccination_card: this.editFiles,
        military_workplace_cert: this.editFiles,
        courier_service_workplace_cert: this.editFiles,
        law_officer_workplace_cert: this.editFiles,
      },

      modalFiles: false,
      activeFile: null,
    };
  },

  computed: {
    _() {
      return _;
    },
    filesDefaultValue() {
      if (this.activeFile) {
        const isMultiple = this.getMaxFiles(this.activeFile) > 1;
        if (isMultiple) {
          return this.files[this.activeFile];
        }
        return this.files[this.activeFile]?.id;
      }
      return undefined;
    },
    validateFiles() {
      const files = _.cloneDeep(this.files);

      return !this.hasValidationFail('vaccination_card') && files.vaccination_card?.id !== null
        && !this.hasValidationFail('child_medical_card') && files.child_medical_card.length !== 0
        && (this.forSanatorium ? !this.hasValidationFail('phthisiatrician_cert') && files.phthisiatrician_cert.id !== null : true)
        && (this.forMilitary ? !this.hasValidationFail('military_workplace_cert') && files.military_workplace_cert.id !== null : true)
        && (this.forCourier ? !this.hasValidationFail('courier_service_workplace_cert') && files.courier_service_workplace_cert.id !== null : true)
        && (this.forLaw ? !this.hasValidationFail('law_officer_workplace_cert') && files.law_officer_workplace_cert.id !== null : true);
    },
    validate() {
      if (this.editFiles) {
        return this.validateFiles;
      }
      return this.validateFiles
      && this.form.issuer_workplace !== null
      && this.form.issuer_position !== null
      && this.form.issuer_birth_cert_number !== null
      && this.form.issuer_birth_cert_date !== null
      && this.form.child_birth_cert_number !== null
      && this.form.child_birth_cert_date !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
    ]);
    await this.requestGetData();
  },

  methods: {
    getFileName(fileType) {
      const isMultiple = this.getMaxFiles(fileType) > 1;
      if (_.isEmpty(this.files[fileType])) {
        return this.trans('label.file_not_chosen');
      }
      if (isMultiple) {
        return this.trans('label.files_downloaded', { count: this.files[fileType].length });
      }
      return this.files[fileType].name;
    },
    clearFile(fileType) {
      const isMultiple = this.getMaxFiles(fileType) > 1;
      if (isMultiple) {
        this.files[fileType] = [];
      } else {
        this.files[fileType] = null;
      }
    },
    selectFile(file) {
      if (this.hasValidationFail(this.activeFile)) {
        if (!_.isEqual(file, this.files[this.activeFile])) {
          this.flashValidationFail(this.activeFile);
        } else {
          this.$notify({
            text: this.trans('notice.file_already_selected'),
            type: 'error',
          });
        }
      }

      this.files[this.activeFile] = file;
      this.modalFiles = false;
      this.activeFile = null;
    },
    fileModalClose() {
      this.activeFile = null;
      this.modalFiles = false;
    },
    triggerFile(fileType) {
      this.activeFile = fileType;
      this.modalFiles = true;
    },
    isInputDisabled(name) {
      if (this.disable.hasOwnProperty(name)) {
        return this.disable[name];
      }
      return true;
    },
    getMaxFiles(fileType) {
      switch (fileType) {
        case 'phthisiatrician_cert':
        case 'military_workplace_cert':
        case 'courier_service_workplace_cert':
        case 'law_officer_workplace_cert':
          return 1;
        default:
          return 2;
      }
    },
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }

        this.files = { ...this.files, ...data.files };
        if (this.editFiles && !_.isEmpty(data.errors)) {
          _.forEach(data.errors, (name) => {
            this.addValidationError(name, [true]);
            this.disableFiles[name] = false;
          });
        }
      }
      this.$globalLoading.hide();
    },

    async requestAction() {
      this.$globalLoading.show();
      const files = {};
      _.forEach(this.files, (file, fileType) => {
        const isMultiple = this.getMaxFiles(fileType) > 1;
        if (isMultiple) {
          files[fileType] = file;
        } else {
          files[fileType] = file?.id;
        }
      });
      const data = { ...this.form, ...files };

      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data,
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
  },
};
