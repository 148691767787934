/* eslint-disable camelcase */
import {
  QTd, QTr, QTh, QTable, QTooltip,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VFoodMenu',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
  },
  props: {
    handleConfirmModal: {
      type: Function,
      default: () => {},
    },
    date: {
      type: String,
      required: true,
    },
    weekDay: {
      type: String,
      required: true,
    },
    stringDate: {
      type: String,
      required: true,
    },
    apiConfig: {
      type: Object,
      default: () => ({
        save: '',
        getData: '',
      }),
    },
    isContingent: {
      type: Boolean,
      default: false,
    },
    refs: {
      type: Object,
      required: true,
      default: () => ({
        portionWeights: [],
        mealTypelist: [],
        typeByMealTime: [],
        typeByEntity: [],
        entityByOptionType: [],
        productOptions: [],
        dishOptions: [],
        cols: [],
      }),
    },
  },
  data() {
    let childCols = ['dish_id', 'dish_out_0'];
    if (this.isContingent) {
      childCols = ['dish_id', 'dish_out_0', 'dish_out_3', 'dish_out_5'];
    }
    return {
      rows: [],
      isRejected: false,
      refuseReason: '',
      childCols,
    };
  },

  async mounted() {
    await this.initRows();
    await this.handleGetData();
  },

  computed: {
    isEdit() { return !!this.apiConfig.save; },
    buttons() {
      const {
        trans, isEdit, handleCloseMenu, handleSave, apiConfig, handleConfirmModal, handleRejectModal,
      } = this;
      const genBtn = (color, label, click) => ({ color, label, click });
      const result = [];

      result.push(genBtn('bb-blue', trans('button.go_back'), handleCloseMenu));

      if (apiConfig.send) {
        result.push(genBtn(
          'blue', trans('button.send'), () => { handleConfirmModal(apiConfig.send, 'patch'); },
        ));
      }

      if (isEdit) {
        result.push(genBtn('green', trans('button.save'), handleSave));
      }

      if (apiConfig.reject) {
        result.push(genBtn(
          'red', trans('button.reject'), () => { handleRejectModal(); },
        ));
      }

      if (apiConfig.approve) {
        result.push(genBtn(
          'green', trans('button.approve'), () => { handleConfirmModal(apiConfig.approve, 'patch'); },
        ));
      }

      if (apiConfig.sendToDelete) {
        result.push(genBtn(
          'red', trans('button.delete'), () => { handleConfirmModal(apiConfig.sendToDelete, 'post'); },
        ));
      }

      if (apiConfig.rejectDeleteRequest) {
        result.push(genBtn(
          'red', trans('button.reject_delete'), () => { handleConfirmModal(apiConfig.rejectDeleteRequest, 'post'); },
        ));
      }

      if (apiConfig.approveDeleteRequest) {
        result.push(genBtn(
          'green', trans('button.approve_delete'), () => { handleConfirmModal(apiConfig.approveDeleteRequest, 'post'); },
        ));
      }

      return result;
    },
  },

  methods: {
    async handleRejectModal() {
      this.$sModal.open('v-modal-food-refuse', {
        title: '',
        component: 'v-modal-food-refuse',
        size: 560,
        binds: { uriAction: this.apiConfig.reject },
        events: {
          onSuccess: async () => {
            eventBus.$emit('handleCloseMenu');
            eventBus.$emit('handleGetItems');
          },
        },
      });
    },
    handleCloseMenu() {
      eventBus.$emit('handleCloseMenu');
    },
    getTotals(rowKey) {
      let dish_out_0 = 0;
      let dish_out_3 = 0;
      let dish_out_5 = 0;

      _.forEach(this.rows[rowKey].children, (child) => {
        dish_out_0 += Number(child.dish_out_0 ?? 0);

        if (this.isContingent === true) {
          dish_out_3 += Number(child.dish_out_3 ?? 0);
          dish_out_5 += Number(child.dish_out_5 ?? 0);
        }
      });
      if (rowKey === 1) {
        _.forEach(this.rows[0].children, (child) => {
          dish_out_0 += Number(child.dish_out_0 ?? 0);
          if (this.isContingent === true) {
            dish_out_3 += Number(child.dish_out_3 ?? 0);
            dish_out_5 += Number(child.dish_out_5 ?? 0);
          }
        });
      }
      if (this.isContingent === true) {
        return { dish_out_0, dish_out_3, dish_out_5 };
      }
      return { dish_out_0 };
    },
    handleMealSelect(rowKey, childKey, option) {
      const child = this.rows[rowKey].children[childKey];

      this.$set(child, 'dishLabel', option.label);
      if (child.entity === 1) {
        this.$set(child, 'dish_out_0', option.dish_out ?? 0);
        if (this.isContingent === true) {
          this.$set(child, 'dish_out_3', option.dish_out ?? 0);
          this.$set(child, 'dish_out_5', option.dish_out ?? 0);
        }
      }
    },
    initRows() {
      return new Promise((res) => {
        const {
          refs: {
            mealTypelist, typeByMealTime, typeByEntity, entityByOptionType,
          },
        } = this;
        const rows = [];

        _.forEach(mealTypelist, (mealTime) => {
          const tmpRow = {};
          tmpRow.mealTime = mealTime;
          tmpRow.children = [];
          _.forEach(typeByMealTime[mealTime.value], (mealType) => {
            const child = {
              dish_id: null,
              dishLabel: null,
              dish_out_0: 0,
              dish_out_3: 0,
              dish_out_5: 0,
              type: mealType.value,
              placeholder: mealType.label,
            };
            _.forEach(typeByEntity, (entityType, key) => {
              if (entityType.includes(mealType.value)) {
                child.refs = key;
                child.entity = entityByOptionType[key];
              }
            });
            tmpRow.children.push(child);
          });
          rows.push(tmpRow);
        });
        this.$set(this, 'rows', rows);
        res();
      });
    },
    async handleGetData() {
      if (this.apiConfig.getData) {
        const res = await requestGetDataHelper(this, {
          url: this.apiConfig.getData,
          returnData: true,
        });
        if (res) {
          _.forEach(this.rows, (time) => {
            time.children = res.items[time.mealTime.value];
            this.isRejected = res.isRejected;
            this.refuseReason = res.refuseReason;
          });
        }
      }
    },
    async handleSave() {
      this.$globalLoading.show();

      const items = [];

      _.forEach(this.rows, (row) => {
        _.forEach(row.children, (child) => {
          items.push({
            meal_time: row.mealTime.value,
            ...child,
          });
        });
      });

      const data = {
        items,
        date: this.date,
      };

      const res = await requestWrapper.call(this, {
        url: this.apiConfig.save,
        data,
        method: 'post',
      });

      this.$globalLoading.hide();

      if (!res.error) {
        eventBus.$emit('handleCloseMenu');
        eventBus.$emit('handleGetItems');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
    },
  },
};
