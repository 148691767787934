<template>
  <div>
    <h4 class="s-title s-upper s-title--double-line mt-40">
      <span>{{ trans('statement.faq_header') }}</span>
    </h4>
    <div class="row-sort-center">
      <div class="s-w-800">
        <div class="faq-cont-wrapper">
          <div class="faq-cont__answear-list">
            <v-faq-spoiler
              v-for="(item, index) in items"
              :key="index"
              :title="item.name"
              :text="item.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VFaqSpoiler from '@vjs/views/VFaqSpoiler/VFaqSpoiler.vue';

export default {
  components: { VFaqSpoiler },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
