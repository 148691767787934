import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VOrganizationReports',
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        getData: null,
        submit: null,
        back: null,
      })
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  async mounted() {
    await requestGetDataHelper(this, {
      url: this.apiConfig.getData,
      hardSet: true,
      variableName: 'sections',
    })
  },

  methods: {
    async handleSubmit() {
      this.$globalLoading.show();

      const files = {};
      this.sections.forEach(section => {
        _.forEach(section.items, (file, key) => {
          files[key] = file.items;
        })
      })
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submit,
        data: {files},
        method: 'post',
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  }
};
