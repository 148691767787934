import {
  QInput,
  QBtn,
  QPagination,
  QTooltip,
} from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import MXListView from '@vjs/mixins/MXListView';
import VOrgsMap from '@common/components/VOrgsMap';
import { Helper } from '@common/src/helpers';
import VCatalogItem from '../../components/VCatalogItem';
import VCatalogFilters from '../../components/VCatalogFilters';
import { scrollTop } from '../../helpers';

export default {
  name: 'VCatalogListView',
  mixins: [MXListView],
  components: {
    QInput,
    QBtn,
    QTooltip,
    QPagination,
    VStatisticPanel,
    VCatalogItem,
    VCatalogFilters,
    VNoResults,
    VOrgsMap,
  },
  props: {
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriStatementCreate: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    departmentData: {
      type: Object,
      default: () => ({}),
    },
    // со страницы создания заявки
    fromStatement: {
      type: Boolean,
      default: false,
    },
    uriLoadQueueModalInfo: String,
  },
  data() {
    return {
      searchValue: '',
      debounceTimeout: null,
      tab: 'list',
      selectedOrgs: [],
    };
  },
  beforeCreate() {
    this.$trans.add(['queue', 'button', 'warning_text']);
  },
  mounted() {
    if (this.fromStatement) {
      this.tab = 'map';
    }

    eventBus.$on('selectOrgOnMap', this.addOrg);
    this.handleInitSelectedOrgs();
  },
  watch: {
    // Сбрасывает отфильтрованный список при удалении строки в поиске
    searchValue(val) {
      if (val === '') {
        this.itemsFiltered = this.items;
      }
    },
  },
  computed: {
    uriBtnStatement() {
      if (this.fromStatement) {
        const tmpData = this.data;
        if (this.selectedOrgs[0]) {
          tmpData.firstSchool = this.selectedOrgs[0];
        }
        if (this.selectedOrgs[1]) {
          tmpData.secondSchool = this.selectedOrgs[1];
        }
        if (this.selectedOrgs[2]) {
          tmpData.thirdSchool = this.selectedOrgs[2];
        }
        if (this.selectedOrgs[3]) {
          tmpData.fourthSchool = this.selectedOrgs[3];
        }

        const params = new URLSearchParams({
          data: JSON.stringify(tmpData),
        }).toString();
        return `${this.uriStatementCreate}?${params}`;
      }
    },
    swipeDirection() { return this.tab === 'list' ? 'swipe-right' : 'swipe-left'; },
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          linkImage: '',
          linkItem: '',
          title: '',
          marks: [
            {
              color: 'blue',
              text: '',
            },
          ],
          languages: '',
          yearsRestriction: '',
          address: '',
          throughputOrg: '',
          availablePlaces: '',
          queueCount: '',
          availablePlacesNumber: '',
          isVoucherStatements: false,
        }, 4);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    handleInitSelectedOrgs() {
      if (this.fromStatement) {
        const handler = (type) => {
          const item = this.data[type];
          if (item?.id) {
            const url = Helper.urlGenerate(this.uriLoadQueueModalInfo, { id: item.id });
            const result = { ...item, linkQueueModal: url };
            this.selectedOrgs.push(result);
          }
        };
        handler('firstSchool');
        handler('secondSchool');
        handler('thirdSchool');
        handler('fourthSchool');
      }
    },
    openQueueModal(item, key) {
      const onApprove = (lang) => { this.selectedOrgs[key].lang = lang; };
      this.$sModal.open('v-modal-select-map-org', {
        title: this.trans('notice.queue_statement_map_modal'),
        component: 'v-modal-select-map-org',
        size: 700,
        binds: { uriGetData: item.linkQueueModal, selectedLang: item.lang },
        events: { onApprove },
      });
    },
    addOrg(itm) {
      const exists = this.selectedOrgs.find(item => itm.id === item.id);
      if (exists) {
        this.$notify({
          type: 'warn',
          text: this.trans('notice.org_already_selected'),
        });
      } else if (this.selectedOrgs.length === 4) {
        this.$notify({
          type: 'warn',
          text: this.trans('notice.cannot_select_more_than_4_org'),
        });
      } else {
        this.selectedOrgs.push(itm);
      }
    },
    // Для обработки реактивного поиска
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    // Обычный поиск по запросу
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.itemsFiltered = this.items.filter((a) => {
          const orgName = a.title || '';
          const orgAddress = a.address || '';
          return orgName.toLowerCase().indexOf(searchStr) !== -1
            || orgAddress.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.itemsFiltered = this.items;
      }
    },
    // Принимает и обрабатывает список от фильтра
    handleFilteredList(arr) {
      this.items = arr.data.items || [];

      if (arr.accessPlaceChecked && Array.isArray(this.items)) {
        this.itemsFiltered = this.items.filter(itm => parseInt(itm.availablePlaces, 10));
      } else {
        this.itemsFiltered = this.items;
      }

      this.statisticItems = arr.data.statistics || [];
      this.searchValue = '';
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
