import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import kazakhstan2023High from '@amcharts/amcharts4-geodata/kazakhstan2023High';

export default {
  props: {},
  data: () => ({ chart: null, geoDataCities: [] }),

  async mounted() {
    await this.$trans.add('label');

    this.initChart();
    this.configureChart();
    this.initPolygon();
    this.initKaragandaPolygon();
  },
  methods: {
    // TODO переделать под множество кликабельных областей с инклюдом
    initKaragandaPolygon() {
      const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      polygonSeries.include = ['KZ-35'];

      this.initPolygonTemplate(polygonSeries, '#8FBF24');
      this.configureTooltip(polygonSeries);

      polygonSeries.id = 'cursor-pointer';

      polygonSeries.mapPolygons.template.events.on('hit', (ev) => {
        eventBus.$emit('corruptionInfoOpen', true, ev.target.dataItem.dataContext.NAME_ENG);
      });
    },
    initPolygon() {
      const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      polygonSeries.exclude = ['KZ-35'];

      this.initPolygonTemplate(polygonSeries, '#E0E2EC');
      this.configureTooltip(polygonSeries);

      this.initCityCircles(polygonSeries);
    },
    initPolygonTemplate(polygonSeries, fill) {
      // Configure series
      const polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}';
      polygonTemplate.fill = am4core.color(fill);
      polygonTemplate.stroke = am4core.color('#3646A2');
      polygonTemplate.strokeWidth = '0.75px';

      // Create hover state and set alternative fill color
      const hs = polygonTemplate.states.create('hover');
      hs.properties.fill = am4core.color('#a3d92a');
    },
    configureTooltip(polygonSeries) {
      polygonSeries.tooltip.getFillFromObject = false;
      polygonSeries.tooltip.background.fill = am4core.color('#FFFFFF');
      polygonSeries.tooltip.label.fill = am4core.color('#3646A2');
      polygonSeries.tooltip.label.fontSize = 16;
    },
    initCityCircles(polygonSeries) {
      polygonSeries.events.on('inited', () => {
        const imageSeries = this.chart.series.push(new am4maps.MapImageSeries());
        const imageSeriesTemplate = imageSeries.mapImages.template;
        const circle = imageSeriesTemplate.createChild(am4core.Circle);
        circle.radius = 10;
        circle.fill = am4core.color('#3045A7');
        circle.strokeWidth = 0;
        circle.nonScaling = true;
        circle.tooltipText = '{title}';
        imageSeriesTemplate.propertyFields.latitude = 'latitude';
        imageSeriesTemplate.propertyFields.longitude = 'longitude';


        const data = [];
        _.forEach(this.geoDataCities, (city) => {
          const mapPolygon = polygonSeries.getPolygonById(city.id);
          data.push({
            latitude: mapPolygon.visualLatitude,
            longitude: mapPolygon.visualLongitude,
            title: city.name,
          });
        });
        imageSeries.data = data;
      });
    },
    initChart() {
      am4core.options.classNamePrefix = 's-corruption-map-';
      am4core.options.autoSetClassName = true;
      this.chart = am4core.create(this.$refs.map, am4maps.MapChart);
      this.chart.geodata = this.getGeoData();
      this.chart.projection = new am4maps.projections.Miller();
    },
    configureChart() {
      if (this.chart.logo) {
        this.chart.logo.disabled = true;
      }
      this.chart.maxZoomLevel = 1;
      this.chart.seriesContainer.draggable = false;
      this.chart.seriesContainer.events.disableType('doublehit');
      this.chart.chartContainer.background.events.disableType('doublehit');
    },
    getGeoData() {
      const customGeoData = { type: 'FeatureCollection' };
      const geoDataCities = [];
      customGeoData.features = kazakhstan2023High.features.map((itm) => {
        const name = itm.properties.NAME_ENG.replace(/\s/g, '');
        itm.properties.name = this.trans(`label.map.${itm.properties.TYPE.toLowerCase()}.${name}`);
        if (itm.properties.TYPE === 'City') {
          geoDataCities.push(itm.properties);
        }
        return itm;
      });
      this.geoDataCities = geoDataCities;
      return customGeoData;
    },
  },
};
