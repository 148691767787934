<template>
  <td
    :class="cellClass"
    @click="$emit('click')"
  >
    <span v-if="value !== null">{{ getValue }}</span>
    <slot v-if="value === null" />
  </td>
</template>

<script>
import { cn } from '../../helpers/support';

export default {
  name: 'VTsCell',
  props: {
    value: {
      type: null,
      default: () => null,
    },
    header: {
      type: Boolean,
      default: () => false,
    },
    className: {
      type: [String, Object],
      default: () => '',
    },
  },
  computed: {
    getValue() {
      const { value } = this;
      let val = null;
      if (
        typeof value === 'object'
          && typeof value.value !== 'undefined'
      ) {
        val = value.value;
      } else {
        val = value;
      }
      if (
        typeof val === 'string'
          || typeof val === 'symbol'
          || typeof val === 'number'
          // eslint-disable-next-line valid-typeof
          || typeof val === 'bigint'
      ) {
        return val;
      }

      return null;
    },
    cellClass() {
      const { className } = this;
      return cn(className, 's-time-sheet-cell');
    },
  },
};
</script>
