import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },

    classlist: {
      type: String,
      default: '',
    },

    photo: {
      type: String,
      default: '',
    },
    hideButton: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    accept: {
      type: String,
      default: '',
    },

    childAvatar: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    file: null,
    submitValue: '',
  }),

  beforeCreate() {
    this.$trans.add(['cabinet', 'notice']);
  },

  mounted() {
    this.submitValue = this.photo;
  },

  watch: {
    photo() {
      this.submitValue = this.photo;
    },
  },

  methods: {
    setPhoto() {
      this.file = this.$refs.input.files[0];
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (e) => {
        this.submitValue = e.target.result;
        if (_.isString(this.$props.action) && this.$props.action !== '') {
          this.send();
        }
      });
      if (this.file) {
        fileReader.readAsDataURL(this.file);
      }
    },

    async send() {
      this.loading = true;
      const temp = this.photo;
      const formData = new FormData();
      formData.append(this.name, this.file);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }, false);
      if (!res.error && this.childAvatar) {
        this.$emit('change', this.file);
      } else if (res.error) {
        this.$notify({
          type: 'error',
          title: window.L.error,
          text: Object.values(res.data.errors)[0][0],
        });
        this.submitValue = temp;
      }
      this.loading = false;
    },
    openDialog() {
      if (!this.loading) {
        this.$refs.input.click();
      }
    },
  },
};
