<template>
  <div class="row justify-content-center">
    <div class="col col-sm sn--text-center mt-5 mb-5">
      <v-wrapper-file-field
        v-model="file"
        required
        :label="trans('parent_payment.upload_file_modal_body')"
        equal
        :config="{
          max: 1,
        }"
      />
      <q-btn
        width="20"
        :label="trans('button.upload_file')"
        :disable="!file"
        color="green"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import { QBtn } from '@quasar/components';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VParentPaymentUploadFileModal',
  components: {
    QBtn,
  },
  directives: { ClosePopup },
  props: {
  },
  data() {
    return {
      file: null,
    };
  },
  async mounted() {
    await this.$trans.add([
      'parent_payment',
      'button',
    ]);
  },
  methods: {
    handleSubmit() {
      if (this.file) {
        this.$emit('modalSubmit', this.file);
      } else {
        this.$notify({
          text: this.trans('parent_payment.file_empty'),
          type: 'error',
        });
      }
    },
  },
};
</script>
