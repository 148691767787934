<template>
  <div>
    <div
      v-if="directorSign && directorSign.signQR"
      class="s-group s-group--wider justify-content-start ml-30"
    >
      <img
        :src="directorSign.signQR"
        alt=""
      >
      <div class="mt-10 mb-10">
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_director_fio') }}
          </div>
          <div>
            {{ directorSign.signerFullname }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_director_iin') }}
          </div>
          <div>
            {{ directorSign.signerIin }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.sign_date') }}
          </div>
          <div>
            {{ directorSign.signDate }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="workerControlSign && workerControlSign.signQR"
      class="s-group s-group--wider justify-content-start ml-30"
    >
      <img
        :src="workerControlSign.signQR"
        alt=""
      >
      <div class="mt-10 mb-10">
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_worker_control_fio') }}
          </div>
          <div>
            {{ workerControlSign.signerFullname }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_worker_control_iin') }}
          </div>
          <div>
            {{ workerControlSign.signerIin }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.sign_date') }}
          </div>
          <div>
            {{ workerControlSign.signDate }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="bookerControlSign && bookerControlSign.signQR"
      class="s-group s-group--wider justify-content-start ml-30"
    >
      <img
        :src="bookerControlSign.signQR"
        alt=""
      >
      <div class="mt-10 mb-10">
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_booker_control_fio') }}
          </div>
          <div>
            {{ bookerControlSign.signerFullname }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.signer_booker_control_iin') }}
          </div>
          <div>
            {{ bookerControlSign.signerIin }}
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="s-medium mr-5">
            {{ trans('timesheet.sign_date') }}
          </div>
          <div>
            {{ bookerControlSign.signDate }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="directorSign"
      class="no-gutters row align-items-stretch mb-10 justify-content-start col-md-6 col-sm-12 "
    >
      <div class="sn-field__wrapper col-lg-4 col-md-5 col-sm-12 ml-20">
        <q-btn
          class="s-w-50"
          no-caps
          color="green"
          @click="downloadPdf"
        >
          {{ trans('attendance.download_pdf') }}
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import {
  ATTENDANCE_TIME_SHEET_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';
// noinspection NpmUsedModulesInstalled
import { Helper } from '@common/src/helpers';
import Localization from '../../Mixins/QuasarLocale';

export default {
  name: 'VTimeSheetSignatures',
  components: {
  },
  mixins: [Localization],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    directorSign: {
      type: Object,
      default: () => ({}),
    },
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    workerControlSign: {
      type: Object,
      default: () => ({}),
    },
    bookerControlSign: {
      type: Object,
      default: () => ({}),
    },
  },
  beforeMount() {
    this.initLocale();
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  methods: {
    async downloadPdf() {
      this.$emit('downloadPdf');
    },
  },
};
</script>
