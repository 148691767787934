export default class AttendanceJournalApiProvider {
  constructor(
    { JournalRepository, JournalMarkRepository, JournalConfirmRepository },
    trans,
  ) {
    this.journalRepository = JournalRepository;
    this.journalMarkRepository = JournalMarkRepository;
    this.journalConfirmRepository = JournalConfirmRepository;
    this.trans = trans;
  }

  provideFetch() {
    const { journalRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (groupId) {
      try {
        const response = await journalRepository.fetchData(groupId);
        const { data: { status, data } } = response;
        if (status === 'ok') {
          return data;
        }
        this.$notify({
          type: 'error',
          text: response.data.message,
        });
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }

  provideMark() {
    const { journalMarkRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (contingentId, status, date, arrival_time, leaving_time) {
      try {
        const response = await journalMarkRepository.mark({
          contingentId,
          status,
          date,
          arrival_time,
          leaving_time,
        });
        const { data: { status: responseStatus, data } } = response;
        if (responseStatus === 'ok') {
          if (response.data.message) {
            this.$notify({
              type: 'error',
              text: response.data.message,
            });
          }
          return data;
        }
        this.$notify({
          type: 'error',
          text: response.data.message,
        });
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }

  provideConfirm() {
    const { journalConfirmRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (groupId, date) {
      try {
        const response = await journalConfirmRepository.confirm(groupId, date);
        const { data: { status: responseStatus, data } } = response;
        if (responseStatus === 'ok') {
          if (response.data.message) {
            this.$notify({
              type: 'error',
              text: response.data.message,
            });
          }
          return data;
        }
        this.$notify({
          type: 'error',
          text: response.data.message,
        });
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }
  responseErrorHandler(vm, error) {
    if (error.response.status === 403) {
      if (error.response.data.message) {
        vm.$notify({
          type: 'error',
          text: error.response.data.message,
        });
        if (error.response.data.redirect) {
          window.location.href = error.response.data.redirect;
        }
      }
    } else if (error.response.status === 404 || error.response.status === 422) {
      if (error.response.data.message) {
        vm.$notify({
          type: 'error',
          text: error.response.data.message,
        });
      }
    } else {
      vm.$notify({
        type: 'error',
        text: vm.$trans('notice.error_on_server'),
      });
    }
  }
}
