<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('notice.confirm')"
      size="medium"
    >
      <v-form-inform
        class="mt-10"
        :message="trans('warning_text.freeplace_only_twins_or_single')"
      />
      <v-wrapper-q-select
        v-model="statementSelected"
        :options="statementItems"
        multiple
        option-label="label"
        option-value="value"
        old-search
        :extra-binds="{
          counter: true,
          maxValues: 3,
          label: trans('label.select_child'),
        }"
      />
      <div class="row justify-content-end mt-10">
        <div class="col col-sm-auto">
          <q-btn
            color="green"
            class="s-w-100"
            :label="trans('button.send')"
            :loading="loading"
            :disable="!statementSelected"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VModal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform/VFormInform';

export default {
  name: 'VBulletinItemModal',
  components: {
    QDialog,
    QBtn,
    VModal,
    VFormInform,
  },
  props: {
    modal: Boolean,
    statementItems: {
      type: Array,
      default: () => ([]),
    },
    uriStatementRequest: {
      type: String,
      required: true,
    },
    uriStatementDiseaseRequest: {
      type: String,
      required: true,
    },
    uriStatementSanatoriaRequest: {
      type: String,
      required: true,
    },
    organizationId: {
      type: Number,
      required: true,
    },
    correction: {
      type: Boolean,
      default: () => false,
    },
    sanatoria: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      statementSelected: [],
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'notice', 'warning_text']);
  },
  methods: {
    handleModal() {
      this.statementSelected = [];
      this.$emit('modal-close', false);
    },
    async send() {
      this.loading = true;
      const {
        correction, sanatoria, organizationId, statementSelected,
        uriStatementRequest, uriStatementDiseaseRequest, uriStatementSanatoriaRequest, trans,
      } = this;
      if (statementSelected.length > 1) {
        const bornDates = _.flatMap(statementSelected, item => item.bornDate);

        const isTwins = _.uniq(bornDates).length === 1;
        if (!isTwins) {
          this.loading = false;
          return this.$notify({
            text: trans('notice.freeplace_only_twins_or_single'),
            type: 'warn',
          });
        }
      }
      const statement = statementSelected.map(item => item.value);
      let uri = uriStatementRequest;
      if (correction) {
        uri = uriStatementDiseaseRequest;
      }
      if (sanatoria) {
        uri = uriStatementSanatoriaRequest;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: uri,
        data: {
          free_place: organizationId,
          statement,
        },
      });

      if (res && !res.error && res.redirect) {
        window.location.href = res.redirect;
      } else {
        this.$emit('return-count', res.data.availableCount);
        this.loading = false;
      }
      this.handleModal();
    },
  },
};
</script>
