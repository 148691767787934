<template>
  <div class="s-food-info-modal">
    <div class="mb-40">
      <div class="mb-20 d-flex flex-column">
        <div
          v-for="(module, key) in modules"
          :key="key"
          class="s-str-ico-fa s-str-ico-fa--thm-lock-alt mb-20"
        >
          {{ trans(`notice.food_info_modal.${module}`) }}
        </div>
      </div>
      <div class="s-to-center">
        <div
          class="s-food-info-modal__title"
          v-html="trans('notice.food_info_modal.body_title')"
        />
        <div
          class="s-food-info-modal__body"
          v-html="trans('notice.food_info_modal.body_text')"
        />
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.understandably')"
        @click="$emit('onClose')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.s-food-info-modal {
  .s-str-ico-fa {
    font-size: 1.8rem;
    line-height: 26px;

    &::before {
      color: #3045A7;
      font-weight: 600;
    }
  }

  &__title {
    font-size: 2.2rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    line-height: 31px;
  }

  &__body {
    font-size: 2rem;
    line-height: 28px;
  }

  &__phone {
    cursor: pointer;
    border-bottom: 1px solid currentColor;
    font-weight: 700;
  }
}
</style>

<script>
export default {
  data: () => ({
    modules: [
      'handbook',
      'product_accounting',
      'technological_map',
      'menu_creation',
      'cost_caltulation',
    ],
  }),
};
</script>
