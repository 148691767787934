<template>
  <div>
    <div class="row justify-content-center">
      <div class="col col-sm sn--text-center mt-5 mb-5">
        <q-form
          class="q-gutter-md"
          @submit="handleSubmit"
        >
          <div
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-5 align-self-center">
              <v-wrapper-q-select
                v-model="form.firstSchool"
                :options="refs.optionsOrganization"
                :label="trans('label.choose_organization')"
                option-value="value"
                option-label="label"
                required
                old-search
                @input="getSchoolLangs(1)"
              />
            </div>
            <div class="col-md-3 align-self-center">
              <v-wrapper-q-select
                v-if="form.firstSchool != null"
                v-model="form.schoolLangs.firstSchool"
                :options="refs.schoolLangs.firstSchool"
                :extra-binds="{
                  placeholder: trans('label.choose_language'),
                }"
                required
                old-search
                option-value="value"
                option-label="label"
                @input-full-option="selectSchool($event, 1)"
              />
            </div>
            <div
              v-if="form.firstSchool != null && form.schoolLangs.firstSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(1)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 1"
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-5 align-self-center">
              <v-wrapper-q-select
                v-model="form.secondSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="getSchoolLangs(2)"
              />
            </div>
            <div class="col-md-3 align-self-center">
              <v-wrapper-q-select
                v-if="form.secondSchool != null"
                v-model="form.schoolLangs.secondSchool"
                :options="refs.schoolLangs.secondSchool"
                :extra-binds="{
                  placeholder: trans('label.choose_language'),
                }"
                required
                old-search
                option-value="value"
                option-label="label"
                @input-full-option="selectSchool($event, 2)"
              />
            </div>
            <div
              v-if="form.secondSchool != null && form.schoolLangs.secondSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(2)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 2"
            :class="{'row': true, 'lines-b': $q.screen.width < 768}"
          >
            <div class="col-md-5 align-self-center">
              <v-wrapper-q-select
                v-if="!form.isSpecialStatementType"
                v-model="form.thirdSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="getSchoolLangs(3)"
              />
            </div>
            <div class="col-md-3 align-self-center">
              <v-wrapper-q-select
                v-if="form.thirdSchool != null"
                v-model="form.schoolLangs.thirdSchool"
                :options="refs.schoolLangs.thirdSchool"
                :extra-binds="{
                  placeholder: trans('label.choose_language'),
                }"
                required
                old-search
                option-value="value"
                option-label="label"
                @input-full-option="selectSchool($event, 3)"
              />
            </div>
            <div
              v-if="form.thirdSchool != null && form.schoolLangs.thirdSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(3)"
              />
            </div>
          </div>
          <div
            v-show="schoolMax > 3"
            class="row"
          >
            <div class="col-md-5 align-self-center">
              <v-wrapper-q-select
                v-if="!form.isSpecialStatementType"
                v-model="form.fourthSchool"
                :options="refs.optionsOrganization"
                option-value="value"
                option-label="label"
                old-search
                @input="getSchoolLangs(4)"
              />
            </div>
            <div class="col-md-3 align-self-center">
              <v-wrapper-q-select
                v-if="form.fourthSchool != null"
                v-model="form.schoolLangs.fourthSchool"
                :options="refs.schoolLangs.fourthSchool"
                :extra-binds="{
                  placeholder: trans('label.choose_language'),
                }"
                required
                old-search
                option-value="value"
                option-label="label"
                @input-full-option="selectSchool($event, 4)"
              />
            </div>
            <div
              v-if="form.fourthSchool != null && form.schoolLangs.fourthSchool != null"
              class="col-md-4 align-self-center s-to-right"
            >
              <q-btn
                :class="{'s-w-100': $q.screen.width > 768, 'mb-10': true}"
                color="red"
                outline
                icon="trash"
                no-caps
                :label="trans('button.refuse_from_queue')"
                @click="clearSchool(4)"
              />
            </div>
          </div>
          <q-slide-transition>
            <div
              v-if="Object.keys(lgotTypeDocuments).length"
            >
              <div
                v-for="(item, key) in lgotTypeDocuments"
                :key="key"
                class="mt-10 mb-20"
              >
                <div class="sn-field__label mb-15">
                  {{ lgotTypeDocumentRules[key].label }}:
                  <i v-if="lgotTypeDocumentRules[key].required === true">*</i>
                </div>
                <q-btn
                  v-if="!item"
                  class="s-w-100 form-upload__button"
                  icon="clip"
                  color="blue"
                  type="button"
                  :label="trans('button.overview')"
                  label-wider
                  @click="triggerFile(key)"
                />
                <q-btn
                  v-if="item"
                  icon="close"
                  class="s-w-100 s-btn--border sn-btn--ellipsis"
                  :label="item.name"
                  label-wider
                  type="button"
                  @click="clearFile(key)"
                />
              </div>
            </div>
          </q-slide-transition>
          <input
            ref="fileInput"
            class="d-none"
            type="file"
            accept="application/msword, application/pdf, image/*"
            @change="fileChange()"
          >
          <input
            ref="specialFileInput"
            class="d-none"
            type="file"
            accept="application/msword, application/pdf, image/*"
            @change="fileChange(true)"
          >
          <div v-if="form.needDiseaseDocument" class="mb-15">
            <div
              class="sn-field__label mb-15"
            >
              {{ trans('statement.disease-document') }}:<i>*</i>
            </div>
            <q-btn
              v-if="!form.statementDocumentDisease && form.needDiseaseDocument"
              class="s-w-100 form-upload__button"
              icon="clip"
              color="blue"
              type="button"
              :label="trans('button.overview')"
              label-wider
              @click="triggerFile('statementDocumentDisease', true)"
            />
            <q-btn
              v-if="form.statementDocumentDisease && form.needDiseaseDocument"
              v-model="form.statementDocumentDisease"
              icon="close"
              class="s-w-100 s-btn--border sn-btn--ellipsis"
              :label="form.statementDocumentDisease.name"
              label-wider
              type="button"
              @click="clearFile('statementDocumentDisease', true)"
            />
          </div>
          <div class="row">
            <div
              class="col align-self-center"
            >
              <q-btn
                width="20"
                type="submit"
                :label="trans('button.save')"
                color="green"
              />
            </div>
          </div>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import {ClosePopup} from '@quasar/directives';
import {Helper} from "@common/src/helpers";

export default {
  name: 'VEditStatementSchoolsModal',
  directives: {ClosePopup},
  props: {
    statementId: {
      type: Number,
      required: true,
    },
    getDataUrl: {
      type: String,
      required: true,
    },
    submitUrl: {
      type: String,
      required: true,
    },
    schoolLangUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lgotTypeOptions: [],
      lgotTypeDocuments: {},
      lgotTypeDocumentRules: {},
      withoutDocumentsAndRules: false,
      nedbCategories: [],
      nedbSpecialTypesRequired: false,
      activeFile: null,
      lgotType: {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      },
      showNedbSpecialTypes: false,
      form: {
        firstSchool: null,
        secondSchool: null,
        thirdSchool: null,
        fourthSchool: null,
        isSpecialStatementType: false,
        schoolLangs: {
          firstSchool: null,
          secondSchool: null,
          thirdSchool: null,
          fourthSchool: null,
        },
        needDiseaseDocument: false,
        statementDocumentDisease: null,
      },
      refs: {
        optionsOrganization: [],
        schoolLangs: {
          firstSchool: null,
          secondSchool: null,
          thirdSchool: null,
          fourthSchool: null,
        },
      },
      newCreatedSchools: {},
      schoolMax: 4,
    };
  },
  async mounted() {
    await this.$trans.add([
      'button',
      'label',
      'statement',
    ]);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.$globalLoading.show();

      const data = {
        firstSchool: this.form.firstSchool,
        secondSchool: this.form.secondSchool,
        thirdSchool: this.form.thirdSchool,
        fourthSchool: this.form.fourthSchool,
        schoolLangs: JSON.stringify(this.form.schoolLangs),
        newCreatedSchools: JSON.stringify(this.newCreatedSchools)
      }

      if (this.showNedbSpecialTypes) {
        data['document_disease'] = this.form.statementDocumentDisease;
      }

      _.forEach(this.lgotTypeDocuments, (file, name) => {
        data[name] = file;
      });

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.submitUrl,
        formData: true,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    async selectSchool(e, number) {
      this.$globalLoading.show();
      const schoolIds = {
        1: 'firstSchool',
        2: 'secondSchool',
        3: 'thirdSchool',
        4: 'fourthSchool',
      };
      const scId = schoolIds[number] || null;
      this.handleSelectSchools(scId);
      this.$globalLoading.hide();
    },
    async clearSchool(n) {
      // eslint-disable-next-line default-case
      const schoolIds = {
        1: 'firstSchool',
        2: 'secondSchool',
        3: 'thirdSchool',
        4: 'fourthSchool',
      };
      const scId = schoolIds[n] || null;
      this.newCreatedSchools[scId] = null;
      this.form[scId] = null;
      this.form.schoolLangs[n - 1] = null;
    },
    async getData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.getDataUrl,
      });
      if (!res.error) {
        this.form.isSpecialStatementType = res.data.isSpecialStatementType;
        this.refs.optionsOrganization = res.data.schools;
        this.refs.schoolLangs = res.data.languages;
        this.form.firstSchool = res.data.firstSchool?.value;
        this.form.secondSchool = res.data.secondSchool?.value;
        this.form.thirdSchool = res.data.thirdSchool?.value;
        this.form.fourthSchool = res.data.fourthSchool?.value;
        this.form.schoolLangs = res.data.schoolLangs;

        if (res.data.documents) {
          this.lgotTypeDocuments = res.data.documents;
        }
        this.lgotTypeDocumentRules = res.data.documentRules;
        this.withoutDocumentsAndRules = res.data.withoutDocumentsAndRules ?? false;
        this.nedbCategories = res.data.category;
        this.form.needDiseaseDocument = res.data.needDiseaseDocument;
        this.showNedbSpecialTypes = res.data.showNedbSpecialTypes ?? false;
        this.nedbSpecialTypesRequired = res.data.specialTypeRequired ?? false;
        this.schoolMax = res.data.schoolMax ?? 4;

        this.newCreatedSchools.firstSchool = {
          school_id: res.data.firstSchool?.value,
          language: res.data.schoolLangs?.firstSchool,
        };
        this.newCreatedSchools.secondSchool = {
          school_id: res.data.secondSchool?.value,
          language: res.data.schoolLangs?.secondSchool,
        };
        this.newCreatedSchools.thirdSchool = {
          school_id: res.data.thirdSchool?.value,
          language: res.data.schoolLangs?.thirdSchool,
        };
        this.newCreatedSchools.fourthSchool = {
          school_id: res.data.fourthSchool?.value,
          language: res.data.schoolLangs?.fourthSchool,
        };
      } else {
        this.lgotTypeDocuments = {};
        this.lgotTypeDocumentRules = {};
        this.showNedbSpecialTypes = false;
        this.nedbSpecialTypesRequired = false;
      }
      this.$globalLoading.hide();
    },
    async getSchoolLangs(n) {
      this.$globalLoading.show();
      const schoolIds = {
        1: 'firstSchool',
        2: 'secondSchool',
        3: 'thirdSchool',
        4: 'fourthSchool',
      };
      const scId = schoolIds[n] || null;
      this.form.schoolLangs[scId] = 0;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.schoolLangUrl,
        params: {
          schoolId: this.form[scId],
        },
      });
      if (!res.error) {
        this.refs.schoolLangs[scId] = res.data;
        this.handleSelectSchools(scId);
        this.$forceUpdate();
      }
      this.$globalLoading.hide();
    },
    handleSelectSchools(schoolIndx) {
      this.newCreatedSchools[schoolIndx] = {
        school_id: this.form[schoolIndx],
        language: this.form.schoolLangs[schoolIndx],
      };
    },
    clearFile(filename, special = false) {
      if (special) {
        this.form.statementDocumentDisease = null;
      } else {
        this.lgotTypeDocuments[filename] = null;
      }
    },
    fileChange(special = false) {
      const file = special
        ? this.$refs.specialFileInput.files[0] : this.$refs.fileInput.files[0];
      if (file) {
        if (special) {
          this.form.statementDocumentDisease = file;
        } else {
          this.lgotTypeDocuments[this.activeFile] = file;
        }
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename, special = false) {
      this.activeFile = filename;
      if (special) {
        this.$refs.specialFileInput.click();
      } else {
        this.$refs.fileInput.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sn-form {
  overflow: hidden;
}

.lines-b {
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
</style>
