import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import { eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VFoodTechnologicCard',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        store: null,
        update: null,
        send: null,
        delete: null,
        approve: null,
        reject: null,
        setCooked: null,
      }),
    },
    status: {
      type: [String, Number],
      default: null,
    },
    statusName: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    cookedAt: {
      type: String,
      default: '',
    },
    refuseReason: {
      type: String,
      default: '',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isContingent: {
      type: Boolean,
      default: false,
    },
    isRejected: {
      type: Boolean,
      default: false,
    },
    isCook: {
      type: Boolean,
      default: false,
    },
    dishOut: {
      type: [String, Number],
      default: null,
    },
    cols: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    agesColumnCount: {
      type: Number,
      default: () => 7,
      required: true,
    },
    ingredients: {
      type: Array,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
      default: () => ({
        products: [],
      }),
    },
  },
  data() {
    return {
      dish_out_local: null,
      ingredientsLocal: [],
      nameLocal: null,
      descriptionLocal: null,
      cookedLocal: null,
      isEdit: this.isNew,
    };
  },

  computed: {
    buttons() {
      const {
        trans, statusName, apiConfig, isEdit, handleConfirmModal, handleCancelEdit, handleSave, showStatus, handleRejectModal,
      } = this;
      const genBtn = (color, label, click, customClass) => ({
        color, label, click, customClass,
      });
      const result = [];
      if (apiConfig.update && !isEdit) {
        result.push(genBtn('orange', trans('button.edit'), () => { this.isEdit = true; }));
      }
      if (apiConfig.send && !isEdit) {
        result.push(genBtn('green', trans('button.send'), () => { handleConfirmModal(apiConfig.send, false, 'patch'); }));
      }
      if (apiConfig.reject) {
        result.push(genBtn('red', trans('button.reject'), () => { handleRejectModal(); }));
      }
      if (apiConfig.approve) {
        result.push(genBtn('green', trans('button.approve'), () => { handleConfirmModal(apiConfig.approve, true, 'patch'); }));
      }
      if (apiConfig.update && isEdit) {
        result.push(genBtn('red', trans('button.cancel'), handleCancelEdit));
      }
      if (isEdit) {
        result.push(genBtn('green', trans('button.save'), handleSave));
      }
      if (showStatus && !apiConfig.setCooked) {
        result.push(genBtn('bb-blue', statusName, () => {}, 'sn--cursor-none'));
      }
      if (apiConfig.setCooked && this.isCook) {
        result.push(genBtn('green', trans('button.cooked'), () => { handleConfirmModal(apiConfig.setCooked, false, 'patch'); }));
      }
      return result;
    },
    showStatus() {
      const {
        update, send, approve, reject,
      } = this.apiConfig;
      return !update && !send && !approve && !reject && !this.apiConfig.delete && this.statusName;
    },
    localCard() {
      return {
        dish_out: this.dish_out_local,
        name: this.nameLocal,
        description: this.descriptionLocal,
        isNew: this.isNew,
        ingredients: this.ingredientsLocal,
        apiConfig: this.apiConfig,
      };
    },
  },

  mounted() {
    this.initLocalData();
  },

  methods: {
    initLocalData() {
      this.dish_out_local = _.cloneDeep(this.dishOut);
      this.ingredientsLocal = _.cloneDeep(this.ingredients);
      this.nameLocal = _.cloneDeep(this.name);
      this.descriptionLocal = _.cloneDeep(this.description);
      this.cookedLocal = _.cloneDeep(this.cookedAt);
    },
    handleAddRow() {
      this.ingredientsLocal.push({});
    },
    handleCancelEdit() {
      this.isEdit = false;
      this.initLocalData();
    },
    async handleDelete() {
      if (this.isNew) {
        this.$emit('handleDeleteCard');
      } else {
        this.$sModal.open('v-modal-confirm', {
          title: this.trans('notice.are_you_sure'),
          component: 'v-modal-confirm',
          size: 480,
          binds: { type: 'event' },
          events: {
            onApprove: async () => {
              const res = await this.handlePostRequest(this.apiConfig.delete, undefined, 'delete');
              if (res) {
                this.$emit('handleGetItems');
              }
            },
          },
        });
      }
    },
    async handleRejectModal() {
      this.$sModal.open('v-modal-food-refuse', {
        title: '',
        component: 'v-modal-food-refuse',
        size: 560,
        binds: { uriAction: this.apiConfig.reject },
        events: {
          onSuccess: async () => {
            this.$emit('handleGetItems');
            this.isEdit = false;
          },
        },
      });
    },
    async handleConfirmModal(uri, deleteCard, method) {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        size: 480,
        binds: { type: 'event' },
        events: {
          onApprove: async () => {
            const res = await this.handlePostRequest(uri, undefined, method);
            if (res) {
              if (deleteCard) {
                this.$emit('handleDeleteCard');
              } else {
                this.$emit('handleUpdateCard', res.data);
              }
            }
          },
        },
      });
    },
    async handleSave() {
      if (this.isNew) {
        const res = await this.handlePostRequest(this.apiConfig.store, this.localCard);
        if (res) {
          this.$emit('handleGetItems');
          this.isEdit = false;
        }
      } else if (this.apiConfig.update) {
        this.isEdit = false;
        await this.handlePostRequest(this.apiConfig.update, this.localCard);
        this.$emit('handleUpdateCard', this.localCard);
      }
    },
    handlePCountInput(rowKey, val) {
      const tmpVal = val ?? 0;
      const variables = ['weight_0', 'weight_1', 'weight_2', 'weight_3', 'weight_4', 'weight_5', 'weight_6'];
      const { tmpOption } = this.ingredientsLocal[rowKey];
      const agesCount = this.agesColumnCount;
      const { isContingent } = this;
      if (isContingent === true) {
        _.forEach(variables, (col) => {
          // eslint-disable-next-line no-prototype-builtins
          if (tmpOption.hasOwnProperty(col)) {
            const tmpWeightVal = !tmpVal ? 0 : tmpVal / agesCount;
            this.$set(this.ingredientsLocal[rowKey], col, tmpWeightVal);
          }
        });
      }
    },
    handleProductSelect(rowKey, option) {
      const tmpOption = { ...option, product_name: option.name };
      this.$delete(this.ingredientsLocal[rowKey], 'p_count');
      this.$set(this.ingredientsLocal[rowKey], 'measure_type', tmpOption.measure_type);
      if (tmpOption.hasOwnProperty('p_count')) {
        this.$set(this.ingredientsLocal[rowKey], 'measure_name', tmpOption.measure_name);
        this.$set(this.ingredientsLocal[rowKey], 'tmpOption', option);
        this.$set(this.ingredientsLocal[rowKey], 'p_count', null);
      } else {
        _.forEach(this.cols, (col) => {
          if (tmpOption.hasOwnProperty(col.name)) {
            this.$set(this.ingredientsLocal[rowKey], col.name, tmpOption[col.name]);
          }
        });
      }
    },
    async handlePostRequest(url, data, method = 'post') {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method,
      });

      this.$globalLoading.hide();

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        return res;
      }
      Helper.handlerResponseErrorNew(this, res);
      return false;
    },
  },
};
