<template>
  <confirm
    :active="show"
    :on-hide="cancel"
    size="medium"
  >
    <div class="attendance-child-info">
      {{ `${trans('attendance.time-sheet.full-name')}: ${fullName}` }}
      <br>
      {{ date }}
    </div>
    <q-slide-transition>
      <div
        v-if="attendance === 0"
        class="s-light"
      >
        <q-select
          v-model="typeControl"
          :options="reasons"
          :label="trans('attendance.timesheet-absent-reason')"
          :disable="true"
          option-value="value"
          option-label="label"
        />
        <q-slide-transition>
          <div
            class="mb-10"
          >
            <div class="sn-field__label">
              {{ trans('attendance.timesheet-absent-file') }}:
              <i>*</i>
            </div>
            <q-btn
              v-if="!file && !hasFile"
              class="s-w-100 form-upload__button"
              icon="clip"
              color="blue"
              type="button"
              :label="trans('button.overview')"
              label-wider
              :disable="false"
              :loading="confirming || reloading"
              @click="triggerFile('file')"
            />
            <q-btn
              v-if="file || hasFile"
              v-model="file"
              icon="close"
              class="s-w-100 s-btn--border sn-btn--ellipsis"
              :label="hasFile ? trans('attendance.file-was-loaded') : file.name"
              label-wider
              no-caps
              type="button"
              :disable="fileApproved === 1 || fileApproved === 2"
              :loading="confirming || reloading"
              @click="clearFile('file')"
            />
            <div
              v-if="hasValidateFail('file')"
              class="s-form__error-message mt-5"
            >
              {{ fails.file }}
            </div>
          </div>
          <!--suppress JSUnresolvedFunction -->
        </q-slide-transition>
        <input
          ref="fileInput"
          class="d-none"
          type="file"
          accept="image/*,.pdf"
          @change="fileChange"
        >
      </div>
    </q-slide-transition>
    <div class="s-group s-group--wider justify-content-end">
      <q-btn
        v-if="reason.uriDownload"
        color="orange"
        type="a"
        target="_blank"
        :to="reason.uriDownload"
      >
        {{ trans('button.download_file') }}
      </q-btn>
      <q-btn
        v-if="(file || fileApproved === 3)"
        :disable="approveDisable"
        color="green"
        :loading="confirming || reloading"
        @click="confirm"
      >
        {{ trans('button.save') }}
      </q-btn>
    </div>
  </confirm>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script>
// noinspection NpmUsedModulesInstalled
import {
  QSelect,
  QBtn,
  QSlideTransition,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';
// noinspection NpmUsedModulesInstalled,
import {
  ATTENDANCE_TIME_SHEET_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';
import Localization from '../../Mixins/QuasarLocale';
// noinspection JSUnusedGlobalSymbols
export default {
  name: 'VTimeSheetAddParentReason',
  components: {
    QSelect,
    QBtn,
    QSlideTransition,
    Confirm,
  },
  mixins: [Localization],
  props: {
    reasons: {
      type: Array,
      default: () => ([]),
    },
    reason: {
      type: Object,
      default: () => ({
        fullName: '',
        date: '',
        attendance: null,
        contingentId: null,
        type: null,
        rawType: null,
        file: null,
        hasFile: false,
        fileApproved: false,
        uriDownload: false,
      }),
    },
    show: {
      type: Boolean,
      default: false,
    },
    fails: {
      type: Object,
      default: () => ({}),
    },
    reloading: {
      type: Boolean,
      default: false,
    },
    confirming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const {
      reason,
    } = this;

    return {
      type: reason.type || 0,
      file: reason.file || null,
      hasFile: reason.hasFile || null,
      fileApproved: reason.fileApproved || null,
      rawType: reason.rawType || null,
      other: reason.other || null,
      fullName: reason.fullName || null,
      date: reason.date || null,
      attendance: reason.attendance ?? null,
      contingentId: reason.contingentId || null,
      backupReason: reason,
      activeFile: null,
    };
  },
  computed: {
    attendanceOptionsList() {
      return [
        { value: 1, label: this.trans('attendance.journal-absent-no') },
        { value: 0, label: this.trans('attendance.journal-absent-yes') },
      ];
    },
    reasonsTypeList() {
      const { reasonList } = this;
      return reasonList.map(reason => reason.value);
    },
    approveDisable() {
      return !(this.attendance !== 1 && (this.file !== null || this.hasFile === true));
    },
    typeControl: {
      get() {
        return this.rawType;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          // eslint-disable-next-line prefer-destructuring
          this.rawType = value;
          this.type = value.value;
        }

        this.fails.type = null;
        this.hasValidateFail('type');
      },
    },
    otherControl: {
      get() {
        return this.other;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          this.other = value;
        }

        this.fails.other = null;
        this.hasValidateFail('other');
      },
    },
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  updated() {
    if (this.reason) {
      this.backupReason = this.reason;
    }
  },
  methods: {
    reasonEvent() {
      const {
        file, hasFile,
        type, rawType,
        other, fullName,
        attendance, contingentId,
        date,
      } = this;
      return {
        file,
        hasFile,
        type: parseInt(type, 10),
        rawType,
        other,
        fullName,
        attendance,
        contingentId,
        date,
      };
    },
    hasValidateFail(field) {
      const {
        type, other,
        $trans: { get: trans },
        fails,
      } = this;

      if (this.reloading) {
        return null;
      }

      if (
        typeof fails[field] !== 'undefined'
        && fails[field] !== null
      ) {
        return true;
      }
      if (field === 'type') {
        this.fails.type = null;
        if (type !== 1) {
          if (this.reasonsTypeList.indexOf(type) === -1) {
            this.fails.type = 'invalid type';
          }
        } else if (this.reasonsTypeList.indexOf(type) === -1) {
          this.fails.type = 'invalid type';
          return true;
        } else if (other && other.length === 0) {
          this.fails.type = 'invalid type';
          return true;
        }
        return null;
      }
      return null;
    },
    confirm() {
      this.$emit('confirm', this.reasonEvent());
    },
    cancel() {
      const { backupReason } = this;
      this.file = backupReason.file;
      this.type = backupReason.type;
      this.rawType = backupReason.rawType;
      this.other = backupReason.other;
      this.hasFile = backupReason.hasFile;
      this.fullName = backupReason.fullName;
      this.$emit('change', this.reasonEvent());
      this.$emit('close');
    },

    clearFile(filename) {
      this[filename] = null;
      this.hasFile = false;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
  },
};
</script>


<style scoped lang="scss">
.attendance-child-info {
  color: #3248A5;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.confirm-control {
  padding: 1.5em 0 0;
  display: flex;
  justify-content: flex-end;
  flex-basis: 1em;

  .sn-btn {
    margin: 0 0.5em;
  }
}
</style>
