// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

import _ from 'lodash';

// noinspection NpmUsedModulesInstalled
import {
  QSeparator,
  QSelect,
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QDialog,
  QSpinner,
  QTooltip,
  QIcon,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@vjs/balabaqsha/views/VContingent/Dialog';
import VNoResults from '@vjs/components/VNoResults';
import axios from 'axios';
import Localization from '../../Mixins/QuasarLocale';
import DateYearMonthPicker from '../../Mixins/DateYearMonthPicker';
import PriceFormat from '../../Mixins/PriceFormat';
import FinalVoucherFundingListProviders from '../Mixins/FinalVoucherFundingListProviders';

export default {
  name: 'VVoucherFundingKindergartenList',
  components: {
    QSeparator,
    QSelect,
    QTable,
    QTr,
    QTd,
    QTh,
    QBtn,
    QDialog,
    QSpinner,
    Confirm,
    QTooltip,
    VNoResults,
    QIcon,
  },
  mixins: [
    Localization, PriceFormat,
    DateYearMonthPicker, FinalVoucherFundingListProviders,
  ],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
    type: {
      type: String,
      default: () => 'funding',
    },
    detailUrl: {
      type: String,
      default: () => 'string',
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const {
      dataConfig: {
        localities,
        date,
        month,
        year,
        dates,
        available,
        exportable,
        type,
      },
    } = this;

    return {
      locale,
      calcType: type,
      currentDate: date,
      availableLocalities: localities,
      currentMonth: month,
      currentYear: year,
      availableDates: dates,
      selectedLocality: _.first(localities),
      kindergartens: [],
      kindergartensLoad: false,
      total: 0,
      calculated: false,
      calculating: false,
      confirmed: false,
      confirmPopup: false,
      confirmStatus: false,
      denyStatus: false,
      denied: available === false,
      exportable,
      loading: false,
    };
  },
  computed: {
    kindergartenTableColumns() {
      const { currentDate } = this;
      const trans = this.$trans;
      return [
        {
          name: 'name',
          field: 'name',
          label: trans.get('funding.kindergarten.table.name'),
        },
        {
          name: 'group_children_count',
          field: 'group_children_count',
          label: trans.get('funding.kindergarten.table.funding-count'),
        },
        {
          name: 'plan',
          field: 'plan',
          label: trans.get('funding.kindergarten.table.funding-plan-date', {
            date: currentDate.split('-').slice(0, 2).join('-'),
          }),
        },
        {
          name: 'fact',
          field: 'fact',
          label: trans.get('funding.kindergarten.table.funding-fact-date', {
            date: currentDate.split('-').slice(0, 2).join('-'),
          }),
        },
        {
          name: 'id',
          field: 'id',
          label: '',
        },
      ];
    },
  },
  watch: {
    selectedLocality(value, old) {
      if (
        old === null
        || value.id !== old.id
      ) {
        const {
          loadKindergartens,
        } = this;
        loadKindergartens(value.id);
      }
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_TRANSLATIONS);
  },
  beforeMount() {
    const {
      apiConfig,
    } = this;
    this.initLocale();
    this.initProvider(apiConfig);
    this.loadKindergartens(this.selectedLocality.id);
  },
  methods: {
    getLocalityById(id) {
      const { availableLocalities } = this;
      const locality = availableLocalities
        .reduce((acc, item) => {
          if (acc) {
            return acc;
          }
          return parseInt(id, 10) === parseInt(item.id, 10) ? item : null;
        }, null);

      return locality || availableLocalities.slice(0, 1)
        .shift();
    },
    async loadKindergartens(localityId) {
      const { apiProvider, selectedDate, calcType } = this;
      this.kindergartens = [];
      this.kindergartensLoad = true;

      this.total = 0;
      const response = await apiProvider
        .provideFetch()
        .apply(this, [localityId, selectedDate, calcType]);
      const {
        kindergartens,
        total,
        date,
        month,
        year,
        availableDates,
        available,
        calculated,
        exportable,
      } = response;
      this.kindergartensLoad = false;
      if (
        kindergartens
        && Array.isArray(kindergartens)
        && kindergartens.length
      ) {
        this.kindergartens = kindergartens;
      }
      // noinspection JSUnresolvedVariable
      this.total = total;
      this.currentMonth = month;
      this.currentYear = year;
      this.availableDates = availableDates;
      this.currentDate = date;
      this.denied = available === false;
      this.calculated = calculated;
      this.exportable = exportable;
    },
    async calculate() {
      const {
        apiProvider, selectedLocality, selectedDate, calcType,
      } = this;
      this.calculating = true;
      const response = await apiProvider
        .provideCalculate()
        .apply(this, [selectedLocality.id, selectedDate, calcType]);
      this.calculating = false;
      if (response.status === 'ok') {
        const { calculated } = response;
        await this.loadKindergartens(selectedLocality.id);
        if (calculated) {
          this.calculated = calculated;
        }
      }
    },
    confirmPopupShow() {
      this.confirmPopup = true;
    },
    cancelDeactivate() {
      this.confirmPopup = false;
    },
    async confirm() {
      const {
        apiProvider, selectedLocality, calculated,
      } = this;
      this.confirmStatus = true;
      const response = await apiProvider
        .provideConfirm()
        .apply(this, [calculated]);
      this.confirmStatus = false;

      await this.loadKindergartens(selectedLocality.id);

      const { status } = response;
      if (status === 'ok') {
        this.confirmed = true;
      }
      this.confirmPopup = false;
    },
    async cancel() {
      const {
        apiProvider, selectedLocality, calculated,
      } = this;
      this.confirmStatus = true;
      this.denyStatus = true;
      const response = await apiProvider
        .provideCancel()
        .apply(this, [calculated]);
      this.confirmStatus = false;
      this.denyStatus = false;
      await this.loadKindergartens(selectedLocality.id);

      const { data: { status } } = response;
      if (status === 'ok') {
        this.calculated = false;
      }
    },
    onChangeYear(val) {
      this.currentYear = val;
      this.currentMonth = _.first(this.availableDates[val]);
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedLocality.id);
    },
    onChangeMonth(val) {
      this.currentMonth = val;
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedLocality.id);
    },
    async exportu(e) {
      this.loading = true;

      axios({
        url: `${this.apiConfig.export.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          locality_id: this.selectedLocality.id,
          export: true,
          date: this.currentDate,
          type: this.calcType,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } - ${this.$trans.get('funding.kindergarten.table.funding-fact-date', {
            date: this.currentDate.split('-').slice(0, 2).join('-'),
          })}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
