import VFaqSpoiler from '@vjs/views/VFaqSpoiler/VFaqSpoiler.vue';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  components: {
    VFaqSpoiler,
  },
  data() {
    return {
      cdnStatic: window.core_project.cdnStatic,
    };
  },
  props: {
    uriAccount: {
      type: String,
      required: false,
    },
    uriDecline: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    signConfig: {
      type: Object,
      default: () => ({
        ncaLayerAction: null,
        getData: null,
        updateQr: null,
        getBody: null,
      }),
    },
  },
  computed: {
    items() {
      const trans = (type, id) => this.trans(`voucher.faq.${type}.${id}`, { declineUrl: this.uriDecline });
      const result = [];
      const makeItm = () => {
        const id = result.length + 1;
        const itm = {
          id,
          title: trans('title', id),
          text: trans('text', id),
        };
        result.push(itm);
      };
      for (let i = 0; i < 7; i++) {
        makeItm();
      }
      return result;
    },
  },
  async mounted() {
    await this.$trans.add(['voucher', 'label']);
  },
  methods: {
    async handleCreateRequest() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAccount,
      });

      if (res.error) {
        this.$globalLoading.hide();
        Helper.handlerResponseErrorNew(this, res);
      }
    },
    async handleSignModal() {
      const res = await requestGetDataHelper(this, {
        url: this.signConfig.getBody,
        returnData: true,
        fullRes: true,
      });
      this.$sModal.open('v-modal-voucher-account-sign', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-voucher-account-sign',
        size: '800',
        binds: { signConfig: this.signConfig, ...res },
      });
    },
  },
};
