<template>
  <q-form
    class="v-modal-food-refuse"
    @submit="requestAction"
  >
    <q-input
      v-model="refuse_reason"
      maxlength="500"
      type="textarea"
      :label="trans('food.technological_map.refuse_reason')"
      class="mb-30"
      :error="hasValidationFail('refuse_reason')"
      :error-message="getValidationMessage('refuse_reason')"
      @input="flashValidationFail('refuse_reason')"
    />

    <div class="row-sort-between-btn">
      <q-btn
        width="20"
        no-caps
        color="grey"
        :label="trans('button.close')"
        @click="$emit('onClose')"
      />
      <q-btn
        type="submit"
        width="20"
        no-caps
        color="orange"
        :label="trans('button.save')"
      />
    </div>
  </q-form>
</template>

<script>
import {
  QBtn,
  QForm,
  QInput,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  name: 'FoodRefuseModal',
  components: {
    QBtn,
    QForm,
    QInput,
  },
  mixins: [Validation],
  props: {
    uriAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refuse_reason: '',
    };
  },
  methods: {
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: 'patch',
        data: {
          refuse_reason: this.refuse_reason,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onSuccess');
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
