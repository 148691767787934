import {
  QTd, QTh, QTr, QTable,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VParentPaymentForm',
  components: {
    QTd,
    QTh,
    QTr,
    QTable,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
        submitTable: '',
      }),
    },
  },
  data() {
    return {
      isEdit: false,
      filters: {
        department: null,
        year: null,
        month: null,
      },
      refs: {
        departments: [],
        years: [],
        months: [],
      },
      table: {
        rows: [],
        cols: [],
      },
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.department
      || !this.filters.year
      || !this.filters.month;
    },
    canSubmit() {
      const result = [];
      _.forEach(this.table.rows, (row) => {
        _.forEach(row, (cell, key) => {
          if (key !== '__index' && cell.editable && [null, undefined, ''].includes(cell.label)) {
            result.push(false);
          }
        });
      });
      return !result.includes(false);
    },
    canEditMonth() {
      return this.filters.month >= currentDayHelper.month
        && this.filters.year >= currentDayHelper.year;
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button']);
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
  },
  methods: {
    async handleSearch() {
      this.rows = [];
      this.isEdit = false;
      await requestGetDataHelper(this, {
        url: this.config.getTable,
        variableName: 'table',
        params: this.filters,
      });
    },
    async submit() {
      this.$globalLoading.show();

      const fields = {};
      _.forEach(this.table.rows, (row) => {
        _.forEach(row, (field, key) => {
          const subType = row.sub_type.value;
          if (field.editable && field.type && subType) {
            if (!fields[field.type]) {
              fields[field.type] = {};
            }
            if (!fields[field.type][subType]) {
              fields[field.type][subType] = {};
            }
            fields[field.type][subType][key] = field.label;
          }
        });
      });

      const data = {
        filters: this.filters,
        data: fields,
      };

      const res = await requestWrapper.call(this, {
        url: this.config.submitTable,
        method: 'post',
        data,
      });

      if (!res.error) {
        this.isEdit = false;
        this.$globalLoading.hide();
      } else {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
