import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VParentPaymentReceiptView',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
        submit: '',
        sendFile: '',
        deleteFile: '',
        reset: '',
      }),
    },
  },
  data() {
    return {
      filters: {
        year: currentDayHelper.year,
        month: currentDayHelper.month,
      },
      refs: {
        years: [],
        months: [],
      },
      table: {
        cols: [],
        rows: [],
        calculateId: null,
        receiptId: null,
        date: null,
        pagination: {
          rowsPerPage: 20,
        },
        canSendToParent: false,
      },
      signXml: '',
      showActions: true,
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'parent_payment']);
  },

  async mounted() {
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
    });
  },

  methods: {
    async getData() {
      this.signXml = '';
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
      });
      this.showActions = true;
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        await requestGetDataHelper(this, {
          url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
        });
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async submit() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          await this.handlePostRequest(
            this.config.submit, {
              id: this.table.receiptId,
              signXml: this.signXml,
            },
          );
          this.$globalLoading.hide();
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    async sendFile(file, { childId, groupId, parentId }) {
      const formData = new FormData();
      formData.append('uploadFile', file[0]);
      formData.append('type', 1);
      formData.append('childId', childId);
      formData.append('parentId', parentId);
      formData.append('date', this.table.date);
      formData.append('calculateId', this.table.calculateId);
      formData.append('groupId', groupId);
      await this.handlePostRequest(
        this.config.sendFile, formData,
      );
    },
    async deleteFile(id) {
      await this.handlePostRequest(
        this.config.deleteFile, { id },
      );
    },
  },
};
