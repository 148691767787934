import Validation from '@vjs/mixins/Validation';

export default {
  name: 'VProductCreateForm',
  mixins: [Validation],
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        backButtonUrl: '',
        storeApiUrl: '',
        createDataApiUrl: '',
      }),
    },
  },

  data() {
    return {
      form: {
        name_ru: null,
        amount: null,
        amount_left: null,
        organoleptic: null,
        receipt_at: null,
        deadline_at: null,
        product_cat_id: null,
        measure_type: null,
        price: 0,
        personnel_id: null,
      },
      options: {
        productCategories: [],
        measureTypelist: [],
        personnelList: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'warning_text',
    ]);
    await this.requestGetData();
  },
  computed: {
    validate() {
      return this.form.name_ru !== null
        && this.form.amount !== null
        && this.form.amount_left !== null
        && this.form.receipt_at !== null
        && this.form.deadline_at !== null
        && this.form.product_cat_id !== null
        && this.form.measure_type !== null
        && (this.form.price !== null && this.form.price !== 0 && this.form.price !== '')
        && this.form.personnel_id !== null;
    },
  },
  methods: {
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.createDataApiUrl,
      });

      if (!res.error) {
        const { data } = res;
        this.form = { ...this.form, ...data.data };
        this.options = data.options;
        if (data.disabled && !_.isEmpty(data.disabled)) {
          _.forEach(this.disable, (value, name) => {
            this.disable[name] = data.disabled[name] ?? false;
          });
        }
      }
      this.$globalLoading.hide();
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.storeApiUrl,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
  },
};
