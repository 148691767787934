<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.doc_extend_direction')"
      size="medium"
    >
      <div class="s-plain-text">
        <p class="s-medium">
          {{ trans('label.extend_direction_description') }}
        </p>
        <p class="s-medium">
          {{ trans('label.docs_criteries') }}:
        </p>
        <ul class="mb-30">
          <li>{{ trans('label.file_formats') }};</li>
          <li>{{ trans('label.file_size') }};</li>
          <li>{{ trans('label.file_dimensions') }};</li>
        </ul>
      </div>
      <v-wrapper-file-field
        v-model="file"
        required
        equal
        :config="{
          max: 1,
        }"
      />
      <div class="s-group s-group--wider justify-content-between">
        <q-btn
          v-close-popup
          width="20"
          :label="trans('button.cancel')"
          label-wider
          color="grey"
        />
        <q-btn
          width="20"
          :label="trans('button.send')"
          color="green"
          :loading="loading"
          :disable="!file"
          @click="send"
        />
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QInput,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';

export default {
  name: 'VApplicationItemModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  directives: { ClosePopup },
  props: {
    modal: Boolean,
    uriStatementContinue: {
      type: String,
      required: true,
    },
    identifier: {
      type: Number,
      required: true,
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'button']);
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    async send() {
      this.loading = true;
      const formData = new FormData();
      formData.append('upload_file', this.file[0]);
      formData.append('type', 7);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriStatementContinue.replace('{id}', this.identifier),
        data: formData,
      });

      if (!res.error) {
        this.success();
        this.handleModal();
      }

      this.loading = false;
    },
  },
};
</script>
