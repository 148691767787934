import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VFundingDocumentsChildrenView',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getTable: '',
        fundingDocumentId: null,
        childId: null,
      }),
    },
  },
  data() {
    return {
      table: {
        cols: [],
        rows: [],
        childFullName: '',
        pagination: {
          rowsPerPage: 0,
        },
      },
      showActions: true,
    };
  },

  computed: {
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'funding_documents']);
  },

  async mounted() {
    await requestGetDataHelper(this, {
      url: this.config.getTable,
      variableName: 'table',
      params: {
        fundingDocumentId: this.config.fundingDocumentId,
        childId: this.config.childId,
      },
      hardSet: true,
    });
  },

  methods: {
    async getData() {
      this.signXml = '';
      await requestGetDataHelper(this, {
        url: this.config.getTable,
        variableName: 'table',
        params: {
          fundingDocumentId: this.config.fundingDocumentId,
          childId: this.config.childId,
        },
        hardSet: true,
      });
      this.showActions = true;
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        await requestGetDataHelper(this, {
          url: this.config.getTable,
          variableName: 'table',
          params: {
            fundingDocumentId: this.config.fundingDocumentId,
            childId: this.config.childId,
          },
          hardSet: true,
        });
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
