<template>
  <q-form
    class="v-modal-food-refuse"
    @submit="requestAction"
  >
    <div class="row mb-20">
      <div
        v-for="(itm, key) in info"
        :key="key"
        class="col-lg-6 col-md-6 col-sm-12"
      >
        <div class="s-desc-item">
          <div class="s-desc-item__label mb-5">
            {{ itm.label }}
          </div>
          <div class="s-desc-item__value">
            {{ itm.value }}
          </div>
        </div>
      </div>
    </div>


    <v-wrapper-q-select
      v-model="lang"
      :options="refs"
      :extra-binds="{
        label: trans('label.edu_lang'),
        hideBottomSpace: true,
        require: true,
      }"
    />

    <div class="row-sort-between-btn">
      <q-btn
        width="20"
        no-caps
        color="grey"
        :label="trans('button.close')"
        @click="$emit('onClose')"
      />
      <q-btn
        type="submit"
        width="20"
        no-caps
        color="orange"
        :label="trans('button.save')"
        :disable="!lang"
      />
    </div>
  </q-form>
</template>

<script>

export default {
  name: 'SelectMapOrg',
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    selectedLang: Number,
  },
  data() {
    return {
      info: [],
      refs: [],
      lang: this.selectedLang ?? null,
    };
  },
  async mounted() {
    const res = await requestGetDataHelper(this, {
      url: this.uriGetData,
      returnData: true
    });
    this.info = res.info;
    this.refs = res.refs;
  },
  methods: {
    async requestAction() {
        this.$emit('onClose');
        this.$emit('onApprove', this.lang);
    },
  },
};
</script>
