import Vue from 'vue';
import Vuex from 'vuex';
import VQuestionnaire from './VQuestionnaire';
import VAttendance from './VAttendance';
import qualificationForm from './qualificationForm';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    VQuestionnaire,
    VAttendance,
    qualificationForm,
  },
});
