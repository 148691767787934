import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class TimeSheetRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async fetchData(groupId, date, isAlt) {
    const { url, locale } = this;

    const tempUrl = new URL(url);
    const debatable = tempUrl.searchParams.get('debatable');

    // eslint-disable-next-line no-return-await
    return await axios.get(
      `${tempUrl.origin + tempUrl.pathname}/${groupId}`,
      {
        params: {
          date,
          isAlt,
          debatable,
        },
        headers: { 'X-localization': locale },
      },
    );
  }
}
