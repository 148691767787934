<template>
  <q-form
    @submit="requestAction"
  >
    <div
      class="mb-30"
    >
      <v-wrapper-file-field
        v-model="file"
        required
        equal
        :label="trans('menu.food.product_quality_certificate')"
        :config="{
          max: 1,
        }"
      />
    </div>
    <div class="s-group s-group--wider justify-content-between">
      <q-btn
        width="20"
        no-caps
        color="grey"
        :label="trans('button.close')"
        @click="$emit('onClose')"
      />
      <q-btn
        type="submit"
        width="20"
        no-caps
        color="orange"
        :label="trans('button.save')"
      />
    </div>
  </q-form>
</template>


<script>

export default {
  name: 'VAddProductQualityCertificateDialog',
  props: {
    uriAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement',
      'queue',
      'button',
      'warning_text',
      'main',
      'label',
      'menu',
    ]);
  },
  methods: {
    async requestAction() {
      this.$globalLoading.show();

      const { file } = this;
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: 'post',
        formData: true,
        data: { file },
      });
      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onSuccess');
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
