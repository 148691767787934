import _ from 'lodash';
import {
  QBtn,
  QDate,
  QField,
  QIcon,
  QItem,
  QOptionGroup,
  QPopupProxy,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VRegionSelect
from '@indv/balabaqsha/javascript/components/VRegionSelect';
import MXFilters from '@vjs/mixins/MXFilters';
import { ERR_404 } from '@vjs/helpers/requestWrapper';
import VTextModal from '../../../../modals/VTextModal.vue';

export default {
  name: 'VVoucherQueueFilters',
  mixins: [MXFilters],
  components: {
    QSelect,
    QIcon,
    QPopupProxy,
    QDate,
    QBtn,
    QItem,
    VRegionSelect,
    VTextModal,
    QSlideTransition,
    QOptionGroup,
    QField,
  },
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
    diseases: {
      type: Array,
      required: true,
    },
    sanatorium: {
      type: Array,
      required: true,
    },
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    stoplist: Boolean,
  },
  data() {
    return {
      orgOptions: [],
      orgSelected: null,
      diseasesSpoiler: null,
      sanatoriaSpoiler: null,
      typeSelected: this.typeOptions[0],
      queueItems: [],
      statisticItems: [],
      disease: null,
      sanatoria: null,
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);
    // Запрос населённых пунктов
    const localityRes = await requestWrapper.call(this, {
      url: this.uriLocality,
    }, true);

    if (localityRes.error) {
      this.loading = false;
      this.$emit('filter-loading', false);

      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }

      return;
    }

    this.localityOptions = localityRes.data;
    // this.loadFiltersFromLocalStorage({}, 'queueVoucherFilter');
    // Проверка на соответствие нас. пунтку при смене региона
    const checkLocality = _.find(
      this.localityOptions,
      loc => loc.value === this.localitySelected.value,
    );

    if (!checkLocality) {
      this.localitySelected = this.localityOptions[0];
    }

    if (
      this.localitySelected.nedbIntegration === true
    ) {
      this.requestOrgQueue(this.localitySelected.value);
    }
    await this.requestQueue();
    this.loading = false;
    this.$emit('filter-loading', false);
  },

  watch: {
    localitySelected(val) {
      if (
        val && (
          val.hasOrgQueue === 1 || val.nedbIntegration === true
          || this.typeSelected.label === 'queue.type_correct'
        )
      ) {
        this.requestOrgQueue(val.value);
      } else {
        this.orgSelected = null;
        this.orgOptions = [];
      }
    },
    typeSelected() {
      this.disease = null;
      this.sanatoria = null;

      if (
        this.isCorrection || this.isSanatoria || this.localitySelected.nedbIntegration === true
      ) {
        this.requestOrgQueue(this.localitySelected.value);
      } else {
        this.orgSelected = null;
        this.orgOptions = [];
      }
      if (this.isSanatoria) {
        this.sanatoriaSpoiler = true;
        if (this.sanatorium.length) {
          this.sanatoria = this.sanatorium[0].value;
        }
      }
      if (this.isCorrection) {
        this.diseasesSpoiler = true;
      }
    },
    disease() {
      if (this.isCorrection) {
        this.requestOrgQueue(this.localitySelected.value);
      } else {
        this.orgSelected = null;
        this.orgOptions = [];
      }
    },
    sanatoria() {
      if (this.isSanatoria) {
        this.requestOrgQueue(this.localitySelected.value);
      } else {
        this.orgSelected = null;
        this.orgOptions = [];
      }
    },
  },

  computed: {
    btnEnable() {
      return (this.localitySelected.hasOrgQueue === 1 && this.orgSelected)
        || this.localitySelected.hasOrgQueue === 0;
    },
    isCorrection() {
      return this.typeSelected.label === 'queue.type_correct';
    },
    isSanatoria() {
      return this.typeSelected.label === 'queue.type_sanatorium';
    },
  },

  methods: {
    async requestOrgQueue(localityId) {
      let correction = !!this.isCorrection;
      let sanatorium = !!this.isSanatoria;

      if (correction && this.disease) {
        correction = this.disease;
      }
      if (sanatorium && this.sanatoria) {
        sanatorium = this.sanatoria;
      }
      console.log(sanatorium, correction);

      const res = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: {
          locality_id: localityId,
          correction,
          sanatorium,
        },
      });

      if (!res.error) {
        this.orgOptions = res.data;
      }
    },
    async handleUpdateLocality(val) {
      this.localityOptions = val.data;
      this.localitySelected = val.selected;
      // this.loadFiltersFromLocalStorage({
      //   localitySelected: val.selected,
      // }, 'queueVoucherFilter');
      await this.requestQueue();
    },
    async requestQueue() {
      this.loading = true;
      this.$emit('filter-loading', true);
      const obj = {
        localitySelected: this.localitySelected,
        typeSelected: this.typeSelected,
      };

      if (
        this.localitySelected.hasOrgQueue === 1
        && !this.isCorrection && !this.isSanatoria
      ) {
        obj.orgSelected = this.orgSelected;
      }
      if (this.hasCorrOrgQueue && (this.isCorrection || this.isSanatoria)) {
        obj.orgSelected = this.orgSelected;
      }

      // this.saveFiltersToLocalStorage(obj, 'queueVoucherFilter');
      const filters = {
        [this.orgSelected ? 'organization' : 'locality']: this.orgSelected ? this.orgSelected.value : this.localitySelected.value,
        diseases: this.disease ? this.disease : [],
        sanatorium: this.sanatoria ? this.sanatoria : [],
        type: this.typeSelected.type,
      };
      const queueItemsRes = await requestWrapper.call(this, {
        url: this.typeSelected.value,
        params: filters,
      });
      const result = {
        items: [],
        statistics: [],
        stoplist: this.typeSelected.label === 'queue.type_stoplist',
        filters,
      };

      if (!queueItemsRes.error) {
        result.items = (_.isArray(queueItemsRes.data) && queueItemsRes.data) || [];
        result.statistics = (_.isArray(queueItemsRes.statistics) && queueItemsRes.statistics) || [];
      }

      this.filterShow = false;
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', result);
    },
  },
};
