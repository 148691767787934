export default class KindergartenListApiProvider {
  constructor({ KindergartensGroupsRepository }, trans) {
    this.kindergartensGroupsRepository = KindergartensGroupsRepository;
    this.trans = trans;
  }

  provideListGroupsByKindergarten() {
    const { kindergartensGroupsRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (kindergartenId, selectedDate) {
      try {
        const response = await kindergartensGroupsRepository
          .listGroupsByKindergartenId(kindergartenId, selectedDate);
        const {
          status,
          groups,
          total,
          confirm,
          dates,
          date,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.kindergarten'),
          });
        } else {
          return {
            groups,
            total,
            confirm,
            dates,
            date,
          };
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$notify({
            type: 'warn',
            text: trans('notice.not_found_entity.kindergarten'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'server error',
            text: error,
          });
        }
      }

      return {};
    };
  }
}
