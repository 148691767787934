import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VFundingDocumentsSchoolsView',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
      }),
    },
  },
  data() {
    return {
      filters: {
        locality: null,
      },
      refs: {
        localities: [],
      },
      table: {
        cols: [],
        rows: [],
      },
    };
  },

  computed: {
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'funding_documents']);
  },

  async mounted() {
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    if (this.refs.localities.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.filters.locality = this.refs.localities[0].id;
    }
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
    });
  },

  methods: {
    async getData() {
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
      });
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        await requestGetDataHelper(this, {
          url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
        });
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
