import axios from 'axios';
import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QSelect,
  QTable,
  QIcon,
  QBtn,
  QTooltip,
  QInput,
  QCheckbox,
} from '@quasar/components';
import { ERR_404 } from '../../../helpers/requestWrapper';
import VNoResults from '../../../components/VNoResults/VNoResults.vue';
import { capitalizeByWords } from '../../../helpers/text';


export default {
  name: 'VWorkerQueueListView',
  components: {
    QSelect,
    QTable,
    QIcon,
    QBtn,
    QCheckbox,
    VNoResults,
    QTooltip,
    QInput,
  },
  props: {
    apiUrl: {
      type: String,
    },
    uriLocality: {
      type: String,
    },
    uriExport: {
      type: String,
    },
    uriStatuses: {
      type: String,
    },
    years: {
      type: Array,
    },

  },
  data: () => ({
    form: {
      status: null,
      year: null,
      locality: null,
    },
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
    },
    statements: [],
    statistic: {},
    bystatus: {},
    localityOptions: [],
    statusOptions: [],
    localityOptionsRaw: [],
    yearsOptions: [],
    loading: false,
    noResult: false,
    filter: '',
  }),
  async mounted() {
    await this.$trans.add(['label', 'menu', 'main', 'button', 'queue', 'warning_text', 'placeholder']);

    const localityRes = await requestWrapper({
      url: this.uriLocality,
    });
    if (localityRes.error) {
      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }
      return;
    }
    this.localityOptions = localityRes.data;
    this.yearsOptions = this.years;


    this.localityOptionsRaw = localityRes.data;
    const statusRes = await requestWrapper({
      url: this.uriStatuses,
    });


    this.statusOptions = statusRes.data;

    this.table.columns = [
      {
        name: 'index',
        label: '#',
        field: 'index',
      }, {
        name: 'child',
        required: true,
        label: this.trans('label.workerqueue.child'),
        align: 'left',
        field: 'child',
        sortable: true,
      }, {
        name: 'iin',
        required: true,
        label: this.trans('label.workerqueue.iin'),
        align: 'left',
        field: 'iin',
        sortable: true,
      }, {
        name: 'issuer',
        required: true,
        label: this.trans('label.workerqueue.issuer'),
        align: 'left',
        field: 'issuer',
        sortable: true,
      }, {
        name: 'loc',
        required: true,
        label: this.trans('label.workerqueue.loc'),
        align: 'left',
        field: 'loc',
        sortable: true,
      }, {
        name: 'bornDate',
        required: true,
        label: this.trans('label.workerqueue.bornDate'),
        align: 'left',
        field: 'bornDate',
        sortable: true,
      }, {
        name: 'status',
        required: true,
        label: this.trans('label.workerqueue.status'),
        align: 'left',
        field: 'status',
        sortable: true,
      }, {
        name: 'benefits',
        required: true,
        label: this.trans('label.workerqueue.benefits'),
        align: 'left',
        field: 'benefits',
        sortable: true,
      }, {
        name: 'queueDate',
        required: true,
        label: this.trans('label.workerqueue.queueDate'),
        align: 'left',
        field: 'queueDate',
        sortable: true,
      }, {
        name: 'source',
        required: true,
        label: this.trans('label.workerqueue.source'),
        align: 'left',
        field: 'source',
        sortable: true,
      },
    ];

    this.addAllFilterField();
    this.submit();
  },
  methods: {
    addAllFilterField() {
      this.localityOptionsRaw.unshift({
        label: this.trans('main.all'),
        value: '',
      });
      this.yearsOptions.unshift({
        label: this.trans('main.all'),
        value: '',
      });
      this.statusOptions.unshift({
        label: this.trans('main.all'),
        value: '',
      });
      this.clearFilters();
    },
    clearFilters() {
      this.form.status = this.statusOptions[0].value;
      this.form.year = this.yearsOptions[0].value;
      this.form.locality = this.localityOptionsRaw[0].value;
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    async submit() {
      this.loading = true;
      this.noResult = false;
      try {
        const res = await axios({
          method: 'POST',
          url: this.apiUrl,
          data: {
            status: this.form.status,
            year: this.form.year,
            locality: this.form.locality,
          },
        });
        const { data } = res.data;
        data.forEach((row, index) => {
          row.index = index + 1;
        });
        this.statistic = res.data.stats;

        this.bystatus = _.groupBy(data, 'status');

        this.statements = data.map(item => ({
          ...item,
          child: capitalizeByWords(item.child),
          issuer: capitalizeByWords(item.issuer),
        }));
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {

        } else {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data.message,
          });
        }
      }
      if (this.statements.length === 0) {
        this.noResult = true;
      }
      this.loading = false;
    },
    exportu(e) {
      this.loading = true;
      axios({
        url: this.uriExport,
        method: 'POST',
        data: this.form,
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download', `${date.getFullYear()}.${date.getMonth() * 1 + 1}.${date.getDate()} Отчет по очереди.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
    serialize(form) {
      const serialized = [];
      Object.keys(form).forEach((key) => {
        serialized.push(`${encodeURIComponent(key)}=${encodeURIComponent(form[key])}`);
      });
      return serialized.join('&');
    },
  },
};
