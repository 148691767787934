import FundingApiProvider
from '../../../providers/Kindergarten/FundingApiProvider';
import FundingCalcRepository
from '../../../repositories/FundingCalcRepository';
import FundingStoreRepository
from '../../../repositories/FundingStoreRepository';
import FundingFetchRepository
from '../../../repositories/FundingFetchRepository';

const FundingProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ calculateApi, storeApi, fetchApi }) {
      const trans = this.$trans.get;
      this.apiProvider = new FundingApiProvider({
        FundingFetchRepository: new FundingFetchRepository(fetchApi),
        FundingCalcRepository: new FundingCalcRepository(calculateApi),
        FundingStoreRepository: new FundingStoreRepository(storeApi),
      }, trans);
    },
  },
};

export default FundingProvider;
