<template>
  <div
    class="s-table-with-filters mt-20"
  >
    <h5
      class="s-combine-title mb-20"
      @click="spoiler = !spoiler"
    >
      <span class="s-combine-title__label">
        {{ trans('voucher.application_form.group_details') }}
      </span>
      <span class="s-combine-title__line">
        <button
          class="s-combine-title__button"
          type="button"
        >
          <span
            class="s-str-ico s-str-ico--wider s-c-grey"
            :class="`s-str-ico--thm-angle-${spoiler ? 'up' : 'down'}`"
          />
        </button>
      </span>
    </h5>
    <q-slide-transition>
      <q-table
        v-if="spoiler"
        :data="rows"
        :columns="cols"
        separator="cell"
        flat
        wrap-cells
        :no-data-label="trans('label.no_data')"
        :no-results-label="trans('label.no_results')"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td>
              {{ props.row.agesImploded }}
            </q-td>
            <q-td align="center">
              {{ props.row.childCount }}
            </q-td>
            <q-td align="center">
              {{ props.row.freePlaces }}
            </q-td>
            <q-td align="center">
              {{ props.row.lang.name }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-slide-transition>
  </div>
</template>

<script>
import { QTable, QTr, QTd } from '@quasar/components';

export default {
  name: 'VVoucherApplicationFormGroupsTable',
  components: { QTable, QTr, QTd },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rows: this.data,
      spoiler: false,
    };
  },

  computed: {
    cols() {
      return [
        {
          label: this.trans('voucher.application_form.group_ages'),
          name: 'ages',
          field: 'ages',
          align: 'left',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_child_count'),
          name: 'childCount',
          field: 'childCount',
          align: 'center',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_free_places'),
          name: 'freePlaces',
          field: 'freePlaces',
          align: 'center',
          sortable: false,
        },
        {
          label: this.trans('voucher.application_form.group_lang'),
          name: 'lang',
          field: 'lang',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        this.rows = val;
      },
    },
  },
};

</script>
