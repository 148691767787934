import { textUtils } from '@vjs/helpers';

export default {
  name: 'VVoucherQueueItem',
  props: {
    stoplist: Boolean,
    queueNumber: Number,
    childFullName: String,
    userFullName: String,
    dateSendStatement: Number,
    expiredDate: Number,
    privilegeClient: {
      type: Object,
      default: () => ({
        key: 0,
        value: '',
      }),
    },
    queueFlagStyle: '',
    loading: Boolean,
  },
  methods: {
    getDate(timestamp) {
      const f = textUtils.addZeroBefore;
      const d = new Date(timestamp * 1000);
      return `${f(d.getDate())}.${f(d.getMonth() + 1)}.${d.getFullYear()}
              ${d.getHours()}:${f(d.getMinutes())}:${f(d.getSeconds())}`;
    },
    capitalizeByWords: textUtils.capitalizeByWords,
    getFlagStyle() {
      return `s-flag ${this.queueFlagStyle} s-c-white`;
    },
  },
};
