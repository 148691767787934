import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VParentPaymentReceiptHistoryView',
  components: {
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
      }),
    },
  },
  data() {
    return {
      filters: {
        year: currentDayHelper.year,
        month: currentDayHelper.month,
      },
      refs: {
        years: [],
        months: [],
      },
      table: {
        cols: [],
        rows: [],
        calculateId: null,
        receiptId: null,
        date: null,
        pagination: {
          rowsPerPage: 20,
        },
        canSendToParent: false,
      },
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'parent_payment']);
  },

  async mounted() {
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await this.getData();
  },

  methods: {
    async getData() {
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: { ...this.filters, history: true }, hardSet: true,
      });
    },
  },
};
