import VModal from '@vjs/components/VModal';
import { QDialog } from '@quasar/components';

export default {
  name: 'ConfirmDialog',
  components: { VModal, QDialog },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    onHide: {
      type: Function,
      default: () => {},
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: '',
    },
    lightText: {
      type: Boolean,
    },
  },
  methods: {
    hideHandler() {
      this.onHide();
    },
  },
};
