<template>
  <div class="s-passport-page__panel mb-30">
    <div class="s-passport-page__panel-title mb-30">
      <div class="s-str-ico-fa s-ico-fa--thm-users">
        {{ trans('organization.queue_statements') }}
      </div>
    </div>
    <div class="mb-30">
      <v-two-field-wrapper>
        <v-wrapper-q-select
          v-model="filters.year"
          :options="refs.years"
          :extra-binds="{
            required: true,
            label: trans('organization.queue_statements_filter_year'),
          }"
        />
        <v-wrapper-q-select
          v-model="filters.lang"
          :options="refs.langs"
          :extra-binds="{
            required: true,
            label: trans('organization.queue_statements_filter_lang'),
          }"
        />
      </v-two-field-wrapper>
      <div class="row justify-content-end">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <q-btn
            :label="trans('label.submit')"
            color="green"
            no-caps
            class="s-w-100"
            type="submit"
            :disable="!filters.lang || !filters.year"
            @click="handleSubmitFilter"
          />
        </div>
      </div>
    </div>

    <div
      v-if="noData || !filtersSubmited"
      class="d-flex justify-content-center"
    >
      <v-no-results
        class="s-w-100"
        :image="!filtersSubmited ? 'search' : 'queue'"
        style="max-width: 90rem"
        :title="trans(`organization.queue_list_empty_title${!filtersSubmited ? '_filter' : ''}`)"
        :text="trans(`organization.queue_list_empty_text${!filtersSubmited ? '_filter' : ''}`)"
      />
    </div>
    <table
      v-else
      class="s-table s-table--striped s-table--thm-head-grey"
    >
      <thead class="s-table__head">
        <tr>
          <th>№</th>
          <th class="s-to-center">
            {{ trans('organization.statement_number') }}
          </th>
          <th class="s-to-center">
            {{ trans('organization.statement_date') }}
          </th>
          <th class="s-to-center">
            {{ trans('organization.status') }}
          </th>
          <th class="s-to-center">
            {{ trans('organization.statement_age') }}
          </th>
          <th class="s-to-center">
            {{ trans('organization.statement_parent') }}
          </th>
          <th class="s-to-center">
            {{ trans('organization.statement_child') }}
          </th>
        </tr>
      </thead>
      <tbody class="s-table__body">
        <tr
          v-for="(statement, statementIdx) in rows"
          :key="statementIdx"
        >
          <td
            class="s-to-center"
            data-table-head="№"
          >
            <div class="s-medium">
              {{ statementIdx + 1 }}
            </div>
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.statement_number')"
          >
            {{ statement.statement_number }}
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.statement_date')"
          >
            {{ statement.statement_date }}
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.status')"
          >
            {{ statement.status }}
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.statement_age')"
          >
            {{ statement.statement_age }}
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.statement_parent')"
          >
            {{ statement.statement_parent }}
          </td>
          <td
            class="s-to-center"
            :data-table-head="trans('organization.statement_child')"
          >
            {{ statement.statement_child }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VQueueTable',

  components: {
    VNoResults,
  },

  props: {
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriGetData: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filtersSubmited: false,
      noData: true,
      rows: [],
      filters: {
        year: null,
        lang: null,
      },
      refs: {
        years: [],
        langs: [],
      },
    };
  },

  async mounted() {
    await requestGetDataHelper(this, {
      url: this.uriGetFilters,
      variableName: 'refs',
    });
  },

  methods: {
    async handleSubmitFilter() {
      await requestGetDataHelper(this, {
        url: this.uriGetData,
        variableName: 'rows',
        params: this.filters,
        hardSet: true,
      });
      this.noData = !this.rows.length;
      this.filtersSubmited = true;
    },
  },
};

</script>
