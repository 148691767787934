import AttendanceExtendedJournalApiProvider
from '../../../providers/Attendance/AttendanceExtendedJournalApiProvider';
import ExtendedJournalRepository
from '../../../repositories/attendance/ExtendedJournalRepository';
import JournalMarkRepository
from '../../../repositories/attendance/JournalMarkRepository';
import JournalConfirmRepository
from '../../../repositories/attendance/JournalConfirmRepository';

const ExtendedJournalApiProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ journalListApi, journalMarkApi, journalConfirmApi }) {
      const trans = this.$trans.get;
      this.apiProvider = new AttendanceExtendedJournalApiProvider({
        JournalRepository: new ExtendedJournalRepository(journalListApi),
        JournalMarkRepository: new JournalMarkRepository(journalMarkApi),
        JournalConfirmRepository: new JournalConfirmRepository(journalConfirmApi),
      }, trans);
    },
  },
};

export default ExtendedJournalApiProvider;
