import { QTable } from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VFoodCostCalcView',
  components: {
    QTable,
    VNoResults,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getRefs: '',
        getTable: '',
        calculate: '',
        calculateConfirm: '',
        calculateCancel: '',
        export: '',
      }),
    },
    isContingent: {
      type: Boolean,
      default: false,
    },
    isCook: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vm: this,
      requestGetDataHelper,
      filters: {
        year: currentDayHelper.year,
        month: currentDayHelper.month,
        day: currentDayHelper.day,
      },
      refs: {
        years: [],
        months: [],
        days: [],
      },
      table: {
        calcId: null,
        isConfirmed: false,
        cols: [],
        rows: [],
      },
      showActions: true,
    };
  },

  computed: {
    isSearchDisabled() {
      return !this.filters.year || !this.filters.month;
    },
  },

  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button', 'food', 'notice']);
    await requestGetDataHelper(this, { url: this.config.getRefs, variableName: 'refs' });
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
    });
  },
  methods: {
    async getData() {
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: this.filters, hardSet: true,
      });
      this.showActions = true;
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        eventBus.$emit('onCloseAll');
        await this.getData();
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
