import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class ContingentRepository {
  constructor({
    deactivateUrl,
    deactivateAllUrl,
    moveUrl,
    getAllowedGroupsUrl,
    locale,
  }) {
    this.deactivateUrl = deactivateUrl || '';
    this.deactivateAllUrl = deactivateAllUrl || '';
    this.moveUrl = moveUrl || '';
    this.getAllowedGroupsUrl = getAllowedGroupsUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async deactivate(modelId, deactivateContractNumber, reasonId, deactivateOtherReason = null, byGroup = false) {
    const { deactivateUrl, deactivateAllUrl, locale } = this;
    const url = byGroup ? deactivateAllUrl : deactivateUrl;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${url}/${modelId}`,
      {
        deactivate_contract_number: deactivateContractNumber,
        reason: reasonId,
        deactivate_other_reason: deactivateOtherReason,
      },
      {
        headers: { 'X-localization': locale },
      },
    );
  }

  async getAllowedGroups(contingentId) {
    const { getAllowedGroupsUrl, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${getAllowedGroupsUrl}/${contingentId}`,
      {},
      {
        headers: { 'X-localization': locale },
      },
    );
  }

  async moveChildToGroup(contingentId, groupId, file, replacedContingent) {
    const { moveUrl, locale } = this;
    const formData = new FormData();
    formData.append('group_id', groupId);
    formData.append('replaced_contingent', replacedContingent);
    formData.append('document_disease', file);
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${moveUrl}/${contingentId}`,
      formData,
      {
        headers: { 'X-localization': locale },
      },
    );
  }
}
