import { Helper } from '@common/src/helpers';
import Validation from '@vjs/mixins/Validation';
import { QOptionGroup } from '@quasar/components';

export default {
  components: { QOptionGroup },
  mixins: [Validation],
  props: {
    action: {
      type: String,
      default: '',
    },
    uriSearchIIN: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        iin: null,
        surname: null,
        firstname: null,
        lastname: null,
        sex: null,
      },
      iinFilled: false,
      debounceTimeout: null,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'text',
      'button',
      'placeholder',
      'validation',
    ]);
  },

  computed: {
    genders() {
      return [
        {
          value: 'm',
          label: this.trans('label.male'),
        },
        {
          value: 'w',
          label: this.trans('label.female'),
        },
      ];
    },
  },

  methods: {
    clearForm() {
      this.form.iin = null;
      this.form.surname = null;
      this.form.firstname = null;
      this.form.lastname = null;
      this.form.sex = null;
      this.iinFilled = false;
    },
    handleCancel() {
      if (this.iinFilled === true) {
        this.clearForm();
      } else {
        this.$emit('onClose');
      }
    },
    async checkIin() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriSearchIIN,
        method: 'post',
        data: { iin: this.form.iin },
      });

      if (!res.error) {
        this.iinFilled = true;
        this.form.surname = res.user.surname;
        this.form.firstname = res.user.firstname;
        this.form.lastname = res.user.lastname;
        this.form.sex = res.user.gender;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
  watch: {
    'form.iin': function (val) {
      if (this.validateIin(val)) {
        this.debounceTimeout = setTimeout(async () => {
          await this.checkIin();
        }, 1000);
      }
    },
  },
};
